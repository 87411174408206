import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../../AxiosClient";

export const getAllMenusAsync = createAsyncThunk(
  "permission/getAllMenusAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/setting/list-menu`, [], toolkit);
  }
);
export const getAllRoutesAsync = createAsyncThunk(
  "permission/getAllRouteAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-menu`, [], toolkit);
  }
);
