import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllVendorAsync,
  deleteVendorAsync,
  createVendorAsync,
  getVendorByIdAsync,
  updateVendorAsync,
  getStateBycountryIdAsync,
  getCityByStateIdAsync,
  getVendorWithoutPaginationAsync
} from "./vendor.async";

const initialState = {
  vendorListLoader: false,
  vendorList: [],
  vendorListInfo: {},

  createVendorLoader: false,
  createVendorInfo: {},

  deleteVendorLoading: false,
  deleteVendorInfo: {},

  getVendorByidLoading: false,
  getVendorByid: {},
  getVendorByidInfo: {},

  updateVendorLoader: false,
  updateVendorInfo: {},

  getStateBycountryIdLoading: false,
  getStateBycountryId: {},
  getStateBycountryIdInfo: {},

  cityByStateIdLoading: false,
  cityByStateId: {},
  cityByStateIdInfo: {},

  vendorWithoutPaginationLoader: false,
  vendorListWithoutPagination: [],
  vendorListWithoutPaginationInfo: {}
};

export const VendorSlice = createSlice({
  name: "vendor",
  initialState,
  extraReducers: (builder) => {
    // vendor list

    builder.addMatcher(isAnyOf(getAllVendorAsync.pending), (state) => {
      state.vendorListLoader = true;
      state.vendorListInfo = {};
      state.vendorList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllVendorAsync.fulfilled),
      (state, action) => {
        state.vendorListLoader = false;
        state.vendorListInfo = {};
        state.vendorList = action.payload?.data || [];
      }
    );
    builder.addMatcher(isAnyOf(getAllVendorAsync.rejected), (state, action) => {
      state.vendorListLoader = false;
      state.vendorListInfo = {};
      state.vendorList = [];
    });

    //Vendor Delete
    builder.addMatcher(isAnyOf(deleteVendorAsync.pending), (state) => {
      state.deleteVendorLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteVendorAsync.fulfilled),
      (state, action) => {
        state.deleteVendorLoading = false;
        state.deleteVendorInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(deleteVendorAsync.rejected), (state, action) => {
      state.deleteVendorLoading = false;
    });

    // Create Vendor
    builder.addMatcher(isAnyOf(createVendorAsync.pending), (state) => {
      state.createVendorLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createVendorAsync.fulfilled),
      (state, action) => {
        state.createVendorLoader = false;
        state.createVendorInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(createVendorAsync.rejected), (state, action) => {
      state.createVendorLoader = false;
    });

    //Vendor By Id
    builder.addMatcher(isAnyOf(getVendorByIdAsync.pending), (state) => {
      state.getVendorByidLoading = true;
      state.getVendorByidInfo = {};
      state.getVendorByid = {};
    });
    builder.addMatcher(
      isAnyOf(getVendorByIdAsync.fulfilled),
      (state, action) => {
        state.getVendorByidLoading = false;
        state.getVendorByidInfo = {};
        state.getVendorByid = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getVendorByIdAsync.rejected),
      (state, action) => {
        state.getVendorByidLoading = false;
        state.getVendorByidInfo = {};
        state.getVendorByid = {};
      }
    );

    //Vendor Update
    builder.addMatcher(isAnyOf(updateVendorAsync.pending), (state) => {
      state.updateVendorLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateVendorAsync.fulfilled),
      (state, action) => {
        state.updateVendorLoader = false;
        state.updateVendorInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(updateVendorAsync.rejected), (state, action) => {
      state.updateVendorLoader = false;
    });

    //getStateBycountryId
    builder.addMatcher(isAnyOf(getStateBycountryIdAsync.pending), (state) => {
      state.getStateBycountryIdLoading = true;
      state.getStateBycountryIdInfo = {};
      state.getStateBycountryId = {};
    });
    builder.addMatcher(
      isAnyOf(getStateBycountryIdAsync.fulfilled),
      (state, action) => {
        state.getStateBycountryIdLoading = false;
        state.getStateBycountryIdInfo = {};
        state.getStateBycountryId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getStateBycountryIdAsync.rejected),
      (state, action) => {
        state.getStateBycountryIdLoading = false;
        state.getStateBycountryIdInfo = {};
        state.getStateBycountryId = {};
      }
    );

    //getCityByStateId
    builder.addMatcher(isAnyOf(getCityByStateIdAsync.pending), (state) => {
      state.cityByStateIdLoading = true;
      state.cityByStateIdInfo = {};
      state.cityByStateId = {};
    });
    builder.addMatcher(
      isAnyOf(getCityByStateIdAsync.fulfilled),
      (state, action) => {
        state.cityByStateIdLoading = false;
        state.cityByStateIdInfo = {};
        state.cityByStateId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getCityByStateIdAsync.rejected),
      (state, action) => {
        state.cityByStateIdLoading = false;
        state.cityByStateIdInfo = {};
        state.cityByStateId = {};
      }
    );

    // get allVendor without pagination
    builder.addMatcher(
      isAnyOf(getVendorWithoutPaginationAsync.pending),
      (state) => {
        state.vendorWithoutPaginationLoader = true;
        state.vendorListWithoutPaginationInfo = {};
        state.vendorListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getVendorWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.vendorWithoutPaginationLoader = false;
        state.vendorListWithoutPaginationInfo = {};
        state.vendorListWithoutPagination = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getVendorWithoutPaginationAsync.rejected),
      (state, action) => {
        state.vendorWithoutPaginationLoader = false;
        state.vendorListWithoutPaginationInfo = {};
        state.vendorListWithoutPagination = [];
      }
    );
  },
  reducers: {
    emptyvendor: () => initialState
  }
});
export const { emptyvendor } = VendorSlice.actions;

export default VendorSlice.reducer;
