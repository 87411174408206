import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getCollectionPercentageChartDataAsync } from "./collectionPercentage.async";

const initialState = {
    collectionPercentageChartDataLoader: false,
    collectionPercentageChartDataInfo: {}
};

export const collectionPercentageSlice = createSlice({
  name: "collectionPercentage",
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getCollectionPercentageChartDataAsync.pending),
      (state) => {
        state.revenueNumberChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getCollectionPercentageChartDataAsync.fulfilled),
      (state, action) => {
        state.collectionPercentageChartDataLoader = false;
        state.collectionPercentageChartDataInfo = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getCollectionPercentageChartDataAsync.rejected),
      (state, action) => {
        state.collectionPercentageChartDataLoader = false;
      }
    );
  },
  reducers: {
    emptycollectionPercentage: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptycollectionPercentage } = collectionPercentageSlice.actions;
export default collectionPercentageSlice.reducer;
