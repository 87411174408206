import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createPincodeAsync = createAsyncThunk(
  "master/pincode",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-pincode`,
      payload,
      toolkit
    );
  }
);



export const getAllPincodewithoutpaginationAsync = createAsyncThunk(
  "master/getAllPincodewithoutpagination",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-pincode`, [], toolkit);
  }
);

export const getAllPincodeAsync = createAsyncThunk(
  "master/getAllPincodeAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-pincode?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const deletePincodeAsync = createAsyncThunk(
  "master/deletepincode",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-pincode/${payload}`,
      [],
      toolkit
    );
  }
);

export const getPincodeByIdAsync = createAsyncThunk(
  "master/getpincodeById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-pincode/${payload}`,
      [],
      toolkit
    );
  }
);
export const getPincodeByCity = createAsyncThunk(
  "master/getPincodeByCity",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/lookup-pincode?city=${payload?.cityId}`,
      [],
      toolkit
    );
  }
);

export const updatePincodeAsync = createAsyncThunk(
  "admin/updatepincode",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-pincode/${payload.id}`,
      payload,
      toolkit
    );
  }
);
