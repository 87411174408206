import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";


// get allVendorSku without pagination 

export const getvendorskuWithoutPaginationAsync = createAsyncThunk( 
  "master/getAllvendorSkuWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-vendorsku`,
      [],
      toolkit
    );
    
  }
);

export const createvendorskuAsync = createAsyncThunk(
  "master/vendorsku",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-vendorsku`,
      payload,
      toolkit
    );
  }
);

export const getAllvendorskuAsync = createAsyncThunk(
  "master/getAllvendorsku",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-vendorsku?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const deletevendorskuAsync = createAsyncThunk(
  "master/deletevendorsku",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-vendorsku/${payload}`,
      [],
      toolkit
    );
  }
);

export const getvendorskuByIdAsync = createAsyncThunk(
  "master/getvendorskuById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-vendorsku/${payload}`,
      [],
      toolkit
    );
  }
);



export const updatevendorskuAsync = createAsyncThunk(
  "admin/updatevendorsku",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-vendorsku/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const getPcmwSkuWithoutPaginationAsync = createAsyncThunk(
  "/master/list-pcmw-sku",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-pcmw-sku`, [], toolkit);
  }
);
