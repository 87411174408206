import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllPurchaseOrderAsync = createAsyncThunk(
  "/getAllPurchaseOrder",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/purchaseOrder/list-purchase-order?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const getAllPurchaseOrderWithoutPaginationAsync = createAsyncThunk(
  "/getAllPurchaseOrderWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/purchaseOrder/list-purchase-order`,
      [],
      toolkit
    );
  }
);

export const deletePurchaseOrderAsync = createAsyncThunk(
  "purchaseOrder/deletePurchaseOrder",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/purchaseOrder/delete-purchase-order/${payload}`,
      [],
      toolkit
    );
  }
);

export const createPurchaseOrderAsync = createAsyncThunk(
  "/purchaseOrder/createPurchaseOrder",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/purchaseOrder/create-purchase-order`,
      payload,
      toolkit
    );
  }
);


export const getPurchaseOrderByIdAsync = createAsyncThunk(
  "/getPurchaseOrderById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/purchaseOrder/get-purchase-order/${payload}`, [], toolkit);
  });



  export const updatePurchaseOrderAsync = createAsyncThunk(
    "updatePurchaseOrder",
    async (payload, toolkit) => {
      return await AxiosClient(
        "PUT",
        `/purchaseOrder/update-purchase-order/${payload.id}`,
        payload,
        toolkit
      );
    }
  );

// get list-itemSku-dropDown

export const getItemSkuDropdownAsync = createAsyncThunk(
  "purchaseOrder/getItemSkuDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/purchaseOrder/list-itemSku-dropDown`,
      [],
      toolkit
    );
    
  }
);

export const getVariantByIdAsync = createAsyncThunk(
  "purchaseOrder/get-variants",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/purchaseOrder/get-variants/${payload}`,
      [],
      toolkit
    );
    
  }
);
export const getVendorSkuByIdAsync = createAsyncThunk(
  "purchaseOrder/get-vendorSku",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/purchaseOrder/get-vendorSku/${payload?.id}/${payload?.variantId}/${payload?.supplierId}`,
      [],
      toolkit
    );
    
  }
);

export const getVendorlocationByIdAsync = createAsyncThunk(
  "purchaseOrder/getVendorlocationByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/purchaseOrder/get-vendor-address/${payload?.id}`,
      [],
      toolkit
    );
    
  }
);
