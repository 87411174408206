import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllServicesAsync,
  getAllJobsSchedule,
  getServiceSlipListDocumentDropdowndAsync,
  getServiceSlipDocumentHtmlByServiceIdAsync,
  getDocumentsOfServiceIdAsync,
  cancelCertificateAsync,
  cancelJobAsync,
} from "./services.async";

const initialState = {
  servicesLoader: false,

  servicesList: [],

  jobsScheduleLoader: false,

  jobsScheduleList: [],

  serviceSlipDocumentDropDownLoader: false,
  serviceSlipDocumentDropDown: [],

  serviceSlipDocumentHtmlByServiceIdLoading: false,
  serviceSlipDocumentHtmlByServiceId: {},
  serviceSlipDocumentHtmlByServiceIdInfo: {},

  getDocumentsOfServiceIdLoading: false,
  getDocumentsOfServiceId: {},
  getDocumentsOfServiceIdInfo: {},
  cancelCertificate: {},
  loading:false,
  cancelJob: {},
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllServicesAsync.pending), (state) => {
      state.servicesLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllServicesAsync.fulfilled),
      (state, action) => {
        state.servicesLoader = false;
        state.servicesList = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllServicesAsync.rejected),
      (state, action) => {
        state.servicesLoader = false;
      }
    );

    // JobsSchedule

    builder.addMatcher(isAnyOf(getAllJobsSchedule.pending), (state) => {
      state.jobsScheduleLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllJobsSchedule.fulfilled),
      (state, action) => {
        state.jobsScheduleLoader = false;
        state.jobsScheduleList = action.payload.data || [];
      }
    );

    builder.addMatcher(
      isAnyOf(getAllJobsSchedule.rejected),
      (state, action) => {
        state.jobsScheduleLoader = false;
        state.jobsScheduleList = [];
      }
    );

    //  Slip document Drop Down List

    builder.addMatcher(
      isAnyOf(getServiceSlipListDocumentDropdowndAsync.pending),
      (state) => {
        state.sendSlipDocumentDropDownLoader = true;
        state.sendSlipDocumentDropDown = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getServiceSlipListDocumentDropdowndAsync.fulfilled),
      (state, action) => {
        state.serviceSlipDocumentDropDownLoader = false;
        state.serviceSlipDocumentDropDown = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getServiceSlipListDocumentDropdowndAsync.rejected),
      (state, action) => {
        state.serviceSlipDocumentDropDownLoader = false;
        state.serviceSlipDocumentDropDown = [];
      }
    );

    //Document Html By Service Id
    builder.addMatcher(
      isAnyOf(getServiceSlipDocumentHtmlByServiceIdAsync.pending),
      (state) => {
        state.serviceSlipDocumentHtmlByServiceIdLoading = true;
        state.serviceSlipDocumentHtmlByServiceIdInfo = {};
        state.serviceSlipDocumentHtmlByServiceId = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getServiceSlipDocumentHtmlByServiceIdAsync.fulfilled),
      (state, action) => {
        state.serviceSlipDocumentHtmlByServiceIdLoading = false;
        state.serviceSlipDocumentHtmlByServiceIdInfo = {};
        state.serviceSlipDocumentHtmlByServiceId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getServiceSlipDocumentHtmlByServiceIdAsync.rejected),
      (state, action) => {
        state.serviceSlipDocumentHtmlByServiceIdLoading = false;
        state.serviceSlipDocumentHtmlByServiceIdInfo = {};
        state.serviceSlipDocumentHtmlByServiceId = {};
      }
    );

    // Get Document By Service id
    builder.addMatcher(isAnyOf(cancelJobAsync.pending), (state) => {
      state.cancelJob = {};
    });
    builder.addMatcher(isAnyOf(cancelJobAsync.fulfilled), (state, action) => {
      state.cancelJob = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(cancelJobAsync.rejected), (state, action) => {
      state.cancelJob = {};
    });

    builder.addMatcher(
      isAnyOf(getDocumentsOfServiceIdAsync.pending),
      (state) => {
        state.getDocumentsOfServiceIdLoading = true;
        state.getDocumentsOfServiceIdInfo = {};
        state.getDocumentsOfServiceId = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getDocumentsOfServiceIdAsync.fulfilled),
      (state, action) => {
        state.getDocumentsOfServiceIdLoading = false;
        state.getDocumentsOfServiceIdInfo = {};
        state.getDocumentsOfServiceId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getDocumentsOfServiceIdAsync.rejected),
      (state, action) => {
        state.getDocumentsOfServiceIdLoading = false;
        state.getDocumentsOfServiceIdInfo = {};
        state.getDocumentsOfServiceId = {};
      }
    );

    // Get Document By Service id
    builder.addMatcher(isAnyOf(cancelCertificateAsync.pending), (state) => {
      state.cancelCertificate = {};
      state.loading=true;
    });
    builder.addMatcher(
      isAnyOf(cancelCertificateAsync.fulfilled),
      (state, action) => {
        state.cancelCertificate = action.payload?.data;
        state.loading=false;
      }
    );
    builder.addMatcher(
      isAnyOf(cancelCertificateAsync.rejected),
      (state, action) => {
        state.cancelCertificate = {};
        state.loading=false;
      }
    );
  },
  reducers: {
    emptyServices: (state) => {
      return {
        ...initialState,
      };
    },
  },
});
export const { emptyServices } = servicesSlice.actions;
export default servicesSlice.reducer;
