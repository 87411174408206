import { Dialog, Button, DialogTitle, DialogActions, DialogContent, IconButton, Box } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

export default function ConfirmDialog({ title, content, action, open, onClose, crossButton = false, ...other }) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <Box sx={{ display: "flex !important", justifyContent: "space-between !important" }}>
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
        {crossButton &&
          <IconButton color="primary" size="large" sx={{ display: "flex !important", justifyContent: "flex-end !important", marginRight: "10px !important" }} onClick={onClose} className="cross">
            <CloseIcon className="cross" />
          </IconButton>
        }
      </Box>
      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}
      <DialogActions>
        {action}
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
