import CustomTable from 'components/CustomTable/CustomTable';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  TextField,
  Stack,
  Typography,
  Card,
  Grid,
  Paper,
} from '@mui/material';

import CustomBreadcrumbs from 'components/custom-breadcrumbs';
import { useSettingsContext } from 'components/settings';
import { useDispatch, useSelector } from 'react-redux';

import { ContainerReportsColumnHandler } from './util';
import { Container } from '@mui/material';
import { getContainerReportAsync } from 'redux/slices/mis/revenueNumbers/revenueNumber.async';
import { gstReportExcelDownloadAsync } from 'redux/slices/gst/gst.async';
import Iconify from 'components/iconify/Iconify';
import AutoCompleteCustom from 'components/AutoCompleteCustom/AutoCompleteCustom';
import { getAllCustomerWithoutPaginationAsync } from 'redux/slices/customer/customer.async';
import { getAllSitesWithoutPaginationAsync } from 'redux/slices/sites/sites.async';
import CardContent from '@mui/material/CardContent';
import CustomizedDialogs from 'pages/Services/Certificates/ContainerModal';
import ContainerModal from './ContainerModal';
import { emptyContainerReport } from 'redux/slices/mis/revenueNumbers/revenueNumber.slice';

const ContainerReport = () => {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(10);
  const [customer, setCustomer] = useState('');
  const [site, setSite] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [entity, setEntity] = useState([]);

  const { containerReportLoader, containerReports } = useSelector(
    (state) => state?.revenueNumbers
  );

  const {
    customerListWithoutPaginationLoader,
    customerListWithoutPagination = [],
  } = useSelector((state) => state.customer);

  const { siteListWithoutPaginationLoader, siteListWithoutPagination } =
    useSelector((state) => state.sites);

  const handlePerRowsChange = (perPageNo, page) => {
    setPageNumber(page);
    setPerPageNumber(perPageNo);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const { themeStretch } = useSettingsContext();

  useEffect(() => {
    dispatch(getAllCustomerWithoutPaginationAsync());
    dispatch(getAllSitesWithoutPaginationAsync());

    return () => {
      dispatch(emptyContainerReport());
    };
  }, []);

  const getAllReceiptHandler = () => {
    const payload = {
      page: pageNumber,
      limit: perPageNumber,
      customer: customer?.value || '',
      fromDate: startDate,
      toDate: endDate,
      site: site?.value || '',
    };
    dispatch(getContainerReportAsync(payload));
  };

  const resetHandler = () => {
    setCustomer('');
    setStartDate('');
    setEndDate('');
    setSite('');
    setPageNumber(1);
    setPerPageNumber(10);
    dispatch(emptyContainerReport());
    // const payload = { page: 1, limit: 10, search: '' };
    // dispatch(getOutStandingReportAsync(payload));
  };

  // my scroll Code
  const fetchGstReports = () => {
    const payload = {
      page,
      limit: 10,
    };
    dispatch(getContainerReportAsync(payload));
    setPage(page + 1); // Increment page for the next API call
  };

  useEffect(() => {
    if (pageNumber !== 1 && perPageNumber !== 10) {
      getAllReceiptHandler();
    }
  }, [pageNumber, perPageNumber]);

  return (
    <>
      <Helmet>
        <title>Container Reports | PCMW</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Container Reports'
          links={[{ name: 'MIS' }, { name: 'Container Reports' }]}
          //   action={
          //     <Button
          //       variant='contained'
          //       startIcon={<Iconify icon='ic:baseline-download' />}
          //       onClick={() =>
          //         gstReportExcelDownloadAsync({
          //           page: pageNumber,
          //           limit: perPageNumber,
          //           customer: customer.value || '',
          //           fromDate: startDate,
          //           toDate: endDate,
          //           site: site?.value || '',
          //         })
          //       }
          //     >
          //       Export
          //     </Button>
          //   }
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 2,
            justifyContent: {
              xs: 'center !important',
              sm: 'flex-start !important',
            },
            alignItems: 'start',
            flexWrap: 'wrap',
          }}
          rowGap={2}
          columnGap={0}
          display='grid'
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            lg: 'repeat(2, 1fr)',
          }}
        >
          <AutoCompleteCustom
            sx={{
              ml: 2,
              mb: { xs: 2, md: 0 },
              width: { xs: '123px !important', sm: '200px !important' },
            }}
            size='small'
            name='customer'
            InputLabelProps={{ shrink: true }}
            loading={customerListWithoutPaginationLoader}
            options={_.map(customerListWithoutPagination?.data, (ev) => {
              return {
                label: `${ev.businessName} (${ev?.contactDetails?.[0]?.contactPerson},${ev?.contactDetails?.[0]?.mobile})`,
                value: ev._id,
              };
            })}
            value={customer}
            onChange={(event, value) => {
              if (value == '' || value == null) {
                return;
              }
              setCustomer(value);
              const payload = {
                page: pageNumber,
                limit: perPageNumber,
                customer: value?.value || '',
                fromDate: startDate,
                toDate: endDate,
                site: site?.value || '',
              };
              dispatch(getContainerReportAsync(payload));
            }}
            label='Customer'
          />
          {customer !== '' && (
            <AutoCompleteCustom
              sx={{
                ml: 2,
                mb: { xs: 2, md: 0 },
                width: { xs: '130px !important', sm: '150px !important' },
              }}
              size='small'
              name='site'
              loading={siteListWithoutPaginationLoader}
              options={_.map(siteListWithoutPagination?.data, (ev) => {
                return {
                  label: ev.locationName,
                  value: ev._id,
                };
              })}
              value={site}
              onChange={(event, value) => {
                setSite(value);
              }}
              label='Select Sites'
            />
          )}

          {customer !== '' && (
            <TextField
              size='small'
              sx={{
                ml: 2,
                mb: { xs: 2, md: 0 },
                width: { xs: '115px !important', sm: '150px !important' },
              }}
              name='Start Date'
              label='Start Date'
              type='date'
              InputLabelProps={{ shrink: 'false' }}
              value={startDate}
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
          )}
          {customer !== '' && (
            <TextField
              size='small'
              sx={{
                ml: 2,
                mb: { xs: 2, md: 0 },
                width: { xs: '150px !important', sm: '150px !important' },
              }}
              name='End Date'
              label='End Date'
              type='date'
              value={endDate}
              InputLabelProps={{ shrink: 'false' }}
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
            />
          )}
          <Button
            sx={{
              ml: 2,
              mt: 0.25,
              mb: { xs: 1, sm: 0 },
              borderRadius: '7px',
              maxWidth: '100px',
            }}
            disabled={customer === ''}
            variant='contained'
            onClick={getAllReceiptHandler}
          >
            Search
          </Button>
          <Button
            variant='contained'
            sx={{
              ml: 2,
              mt: 0.25,
              mb: { xs: 1, sm: 0 },
              borderRadius: '7px',
              maxWidth: '100px',
            }}
            onClick={resetHandler}
          >
            Reset
          </Button>
        </Box>

        <CustomTable
          columnheight='50px'
          loader={containerReportLoader}
          data={containerReports?.data?.response}
          columns={ContainerReportsColumnHandler({
            pageNumber,
            setEntity,
            setOpen,
          })}
          totalcount={containerReports?.data?.totalItems}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Container>
      <Box sx={{ display: 'none !important' }}>
        <ContainerModal
          open={open}
          setOpen={setOpen}
          entity={entity?.containerNo}
        />
      </Box>
    </>
  );
};

export default ContainerReport;
