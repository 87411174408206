import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllCredentialAsync = createAsyncThunk(
  "master/getAllCredential",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-gst-credentials?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createCredentialAsync = createAsyncThunk(
  "master/createCredential",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-gst-credential`,
      payload,
      toolkit
    );
  }
);

export const deleteCredentialAsync = createAsyncThunk(
  "master/deleteCredential",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-gst-credential/${payload}`,
      [],
      toolkit
    );
  }
);

export const getCredentialByIdAsync = createAsyncThunk(
  "master/getAccreditationById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-gst-credential/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateCredentialAsync = createAsyncThunk(
  "admin/updateAccreditation",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-gst-credential/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// for site
export const getAllSitesAsync = createAsyncThunk(
  "master/getAllSites",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-site`, [], toolkit);
  }
);





