import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createPestAsync = createAsyncThunk(
  "master/Pest",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-pest`, payload, toolkit);
  }
);

export const getAllPestAsync = createAsyncThunk(
  "master/getAllPest",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-pest?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const deletePestAsync = createAsyncThunk(
  "master/deletePest",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-pest/${payload}`,
      [],
      toolkit
    );
  }
);

export const getPestByIdAsync = createAsyncThunk(
  "master/getPestById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-pest/${payload}`, [], toolkit);
  }
);

export const updatePestAsync = createAsyncThunk(
  "admin/updatePest",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-pest/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// get Pest without Pagination
export const getAllPestWithoutPaginationAsync = createAsyncThunk(
  "master/getAllPestWithoutPaginationAsync", 
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-pest`, [], toolkit);
  }
);


