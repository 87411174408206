import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAllExpenseAsync,expenseStatusChangeAsync } from "./expense.async";

const initialState = {
  expenseListLoader: false,
  expenseList: [],
  expenseListInfo: {},

  expenseStatusChangeLoading: false,
  expenseStatusChangeById: {},
  expenseStatusChangeByIdInfo: {},
};

export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  extraReducers: (builder) => {
    //State List
    builder.addMatcher(isAnyOf(getAllExpenseAsync.pending), (state) => {
      state.expenseListLoader = true;
      state.expenseListInfo = {};
      state.expenseList = [];
      state.expenseListLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllExpenseAsync.fulfilled),
      (state, action) => {
        state.expenseListLoader = false;
        state.expenseListInfo = {};
        state.expenseList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllExpenseAsync.rejected),
      (state, action) => {
        state.expenseListLoader = false;
        state.expenseListInfo = {};
        state.expenseList = [];
      }
    );

        //Expense Status Change
        builder.addMatcher(isAnyOf(expenseStatusChangeAsync.pending), (state) => {
            state.expenseStatusChangeLoading = true;
            state.expenseStatusChangeByIdInfo = {};
            state.expenseStatusChangeById = {};
          });
          builder.addMatcher(
            isAnyOf(expenseStatusChangeAsync.fulfilled),
            (state, action) => {
              state.expenseStatusChangeLoading = false;
              state.expenseStatusChangeByIdInfo = {};
              state.expenseStatusChangeById = action.payload?.data;
            }
          );
          builder.addMatcher(isAnyOf(expenseStatusChangeAsync.rejected), (state, action) => {
            state.expenseStatusChangeLoading = false;
            state.expenseStatusChangeByIdInfo = {};
            state.expenseStatusChangeById = {};
          });
  },
  reducers: {
    emptyExpense: (state) => {
      return {
        ...initialState
      };
    }
  }
});
export const { emptyExpense } = expenseSlice.actions;
export default expenseSlice.reducer;
