import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllAccreditationAsync,
  createAccreditationAsync,
  deleteAccreditationAsync,
  getAccreditationByIdAsync,
  updateAccreditationAsync,
  getAllSitesAsync,
  getAllServiceAsync,
  getAllCityAsync
} from "./accreditation.async";


const initialState = {
  accreditationListLoader: false,
  accreditationList: [],
  accreditationListInfo: {},

  accreditationCreateLoader: false,
  accreditationCreateInfo: {},

  accreditationByIdLoading: false,
  accreditationById: {},
  accreditationByIdInfo: {},

  accreditationUpdateLoader: false,
  accreditationUpdateInfo: {},

  accreditationDeleteLoading: false,
  accreditationDeleteInfo: {},

  siteListLoader: false,
  siteList: [],
  siteListInfo: {},

  serviceListLoader: false,
  serviceList: [],
  serviceListInfo: {},

  cityListLoader: false,
  cityList: [],
  cityListInfo: {},
};

export const accreditationSlice = createSlice({
  name: "accreditation",
  initialState,
  extraReducers: (builder) => {
    // Create Accreditation
    builder.addMatcher(isAnyOf(createAccreditationAsync.pending), (state) => {
      state.accreditationCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createAccreditationAsync.fulfilled),
      (state, action) => {
        state.accreditationCreateLoader = false;
        state.accreditationCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createAccreditationAsync.rejected),
      (state, action) => {
        state.accreditationCreateLoader = false;
      }
    );

    //Accreditation List
    builder.addMatcher(isAnyOf(getAllAccreditationAsync.pending), (state) => {
      state.accreditationListLoader = true;
      state.accreditationListInfo = {};
      state.accreditationList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllAccreditationAsync.fulfilled),
      (state, action) => {
        state.accreditationListLoader = false;
        state.accreditationListInfo = {};
        state.accreditationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllAccreditationAsync.rejected),
      (state, action) => {
        state.accreditationListLoader = false;
        state.accreditationListInfo = {};
        state.accreditationList = [];
      }
    );

    //Accreditation Delete
    builder.addMatcher(isAnyOf(deleteAccreditationAsync.pending), (state) => {
      state.accreditationDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteAccreditationAsync.fulfilled),
      (state, action) => {
        state.accreditationDeleteLoading = false;
        state.accreditationDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteAccreditationAsync.rejected),
      (state, action) => {
        state.accreditationDeleteLoading = false;
      }
    );

    //Accreditation By Id
    builder.addMatcher(isAnyOf(getAccreditationByIdAsync.pending), (state) => {
      state.accreditationByIdLoading = true;
      state.accreditationByIdInfo = {};
      state.accreditationById = {};
    });
    builder.addMatcher(
      isAnyOf(getAccreditationByIdAsync.fulfilled),
      (state, action) => {
        state.accreditationByIdLoading = false;
        state.accreditationByIdInfo = {};
        state.accreditationById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getAccreditationByIdAsync.rejected),
      (state, action) => {
        state.accreditationByIdLoading = false;
        state.accreditationByIdInfo = {};
        state.accreditationById = {};
      }
    );

    //Accreditation Update
    builder.addMatcher(isAnyOf(updateAccreditationAsync.pending), (state) => {
      state.accreditationUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateAccreditationAsync.fulfilled),
      (state, action) => {
        state.accreditationUpdateLoader = false;
        state.accreditationUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(updateAccreditationAsync.rejected), (state, action) => {
      state.accreditationUpdateLoader = false;
    });

    // for site list
    builder.addMatcher(isAnyOf(getAllSitesAsync.pending), (state) => {
      state.siteListLoader = true;
      state.siteListInfo = {};
      state.siteList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllSitesAsync.fulfilled),
      (state, action) => {
        state.siteListLoader = false;
        state.siteListInfo = {};
        state.siteList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllSitesAsync.rejected),
      (state, action) => {
        state.siteListLoader = false;
        state.siteListInfo = {};
        state.siteList = [];
      }
    );

    // for Service
    builder.addMatcher(isAnyOf(getAllServiceAsync.pending), (state) => {
      state.serviceListLoader = true;
      state.serviceListInfo = {};
      state.serviceList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllServiceAsync.fulfilled),
      (state, action) => {
        state.serviceListLoader = false;
        state.serviceListInfo = {};
        state.serviceList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllServiceAsync.rejected),
      (state, action) => {
        state.serviceListLoader = false;
        state.serviceListInfo = {};
        state.serviceList = [];
      }
    );

    // for City
    builder.addMatcher(isAnyOf(getAllCityAsync.pending), (state) => {
      state.cityListLoader = true;
      state.cityListInfo = {};
      state.cityList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllCityAsync.fulfilled),
      (state, action) => {
        state.cityListLoader = false;
        state.cityListInfo = {};
        state.cityList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCityAsync.rejected),
      (state, action) => {
        state.cityListLoader = false;
        state.cityListInfo = {};
        state.cityList = [];
      }
    );


  },
  reducers: {
    emptyAccreditation: () => initialState
  }
});
export const { emptyAccreditation } = accreditationSlice.actions;

export default accreditationSlice.reducer;
