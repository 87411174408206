import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

//GET ALL JOBS
export const getAllAttendanceAsync = createAsyncThunk(
  "attendance/getAllAttendanceAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/punches/list-all?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

// Detail Attendance

export const getAttendanceDetailAsync = createAsyncThunk(
  "attendance/getAttendanceDetailAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/punches/get-single-record/${payload?._id}`,
      [],
      toolkit
    );
  }
);


