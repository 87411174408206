import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createvendorskuAsync,
  getvendorskuWithoutPaginationAsync,
  getAllvendorskuAsync,
  deletevendorskuAsync,
  getvendorskuByIdAsync,
  updatevendorskuAsync,
  getPcmwSkuWithoutPaginationAsync
} from "./sku.async";

const initialState = {
  vendorskuCreateLoader: false,
  vendorskuCreateInfo: {},

  vendorskuListLoader: false,
  vendorskuList: [],
  vendorskuListInfo: {},

  PcmwSkuListLoader: false,
  PcmwSkuList: [],
  PcmwSkuListInfo: {},

  vendorskuDeleteLoading: false,
  vendorskuDeleteInfo: {},

  vendorskuByIdLoading: false,
  vendorskuById: {},
  vendorskuByIdInfo: {},

  vendorskuUpdateLoader: false,
  vendorskuUpdateInfo: {},

  vendorskuWithoutPaginationAsyncLoader: false,
  vendorskuWithoutPaginationAsync: [],
  vendorskuWithoutPaginationAsyncInfo: {},
};

export const vendorskuSlice = createSlice({
  name: "vendorsku",
  initialState,
  extraReducers: (builder) => {
    // Create vendorsku
    builder.addMatcher(isAnyOf(createvendorskuAsync.pending), (state) => {
      state.vendorskuCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createvendorskuAsync.fulfilled),
      (state, action) => {
        state.vendorskuCreateLoader = false;
        state.vendorskuCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createvendorskuAsync.rejected),
      (state, action) => {
        state.vendorskuCreateLoader = false;
      }
    );

    // getvendorskuWithoutPaginationAsync

    builder.addMatcher(
      isAnyOf(getvendorskuWithoutPaginationAsync.pending),
      (state) => {
        state.vendorskuWithoutPaginationAsyncLoader = true;
        state.vendorskuWithoutPaginationAsyncInfo = {};
        state.vendorskuWithoutPaginationAsync = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getvendorskuWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.vendorskuWithoutPaginationAsyncLoader = false;
        state.vendorskuWithoutPaginationAsyncInfo = {};
        state.vendorskuListWithoutPagination = action.payload?.data || [];
      }
    );

    builder.addMatcher(
      isAnyOf(getvendorskuWithoutPaginationAsync.rejected),
      (state, action) => {
        state.vendorskuWithoutPaginationAsyncLoader = false;
        state.vendorskuWithoutPaginationAsyncInfo = {};
        state.vendorskuWithoutPaginationAsync = [];
      }
    );

    // Pcmw sku 

    builder.addMatcher(
      isAnyOf(getPcmwSkuWithoutPaginationAsync.pending),
      (state) => {
        state.PcmwSkuListLoader = true;
        state.PcmwSkuListInfo = {};
        state.PcmwSkuList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getPcmwSkuWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.PcmwSkuListLoader = false;
        state.PcmwSkuListInfo = {};
        state.PcmwSkuList = action.payload?.data || [];
      }
    );

    builder.addMatcher(
      isAnyOf(getPcmwSkuWithoutPaginationAsync.rejected),
      (state, action) => {
        state.PcmwSkuListLoader = false;
        state.PcmwSkuListInfo = {};
        state.PcmwSkuList = [];
      }
    );

    //vendor sku List
    builder.addMatcher(isAnyOf(getAllvendorskuAsync.pending), (state) => {
      state.vendorskuListLoader = true;
      state.vendorskuListInfo = {};
      state.vendorskuList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllvendorskuAsync.fulfilled),
      (state, action) => {
        state.vendorskuListLoader = false;
        state.vendorskuListInfo = {};
        state.vendorskuList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllvendorskuAsync.rejected),
      (state, action) => {
        state.vendorskuListLoader = false;
        state.vendorskuListInfo = {};
        state.vendorskuList = [];
      }
    );

    //vendor sku Delete
    builder.addMatcher(isAnyOf(deletevendorskuAsync.pending), (state) => {
      state.vendorskuDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deletevendorskuAsync.fulfilled),
      (state, action) => {
        state.vendorskuDeleteLoading = false;
        state.vendorskuDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deletevendorskuAsync.rejected),
      (state, action) => {
        state.vendorskuDeleteLoading = false;
      }
    );

    //vendorsku By Id
    builder.addMatcher(isAnyOf(getvendorskuByIdAsync.pending), (state) => {
      state.vendorskuByIdLoading = true;
      state.vendorskuByIdInfo = {};
      state.vendorskuById = {};
    });
    builder.addMatcher(
      isAnyOf(getvendorskuByIdAsync.fulfilled),
      (state, action) => {
        state.vendorskuByIdLoading = false;
        state.vendorskuByIdInfo = {};
        state.vendorskuById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getvendorskuByIdAsync.rejected),
      (state, action) => {
        state.vendorskuByIdLoading = false;
        state.vendorskuByIdInfo = {};
        state.vendorskuById = {};
      }
    );

    //vendor sku Update
    builder.addMatcher(isAnyOf(updatevendorskuAsync.pending), (state) => {
      state.vendorskuUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updatevendorskuAsync.fulfilled),
      (state, action) => {
        state.vendorskuUpdateLoader = false;
        state.vendorskuUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updatevendorskuAsync.rejected),
      (state, action) => {
        state.vendorskuUpdateLoader = false;
      }
    );
  },
  reducers: {
    emptyvendorsku: () => initialState,
  },
});
export const { emptyvendorsku } = vendorskuSlice.actions;

export default vendorskuSlice.reducer;
