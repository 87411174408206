function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/app';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  // changePassword: path(ROOTS_AUTH, "/change-password"),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/dashboard'),
  master: path(ROOTS_DASHBOARD, '/dashboard/master'),

  changePassword: path(ROOTS_DASHBOARD, '/change-password'),
  master: path(ROOTS_DASHBOARD, '/master'),

  // Country
  country: path(ROOTS_DASHBOARD, '/master/country'),
  createcountry: path(ROOTS_DASHBOARD, '/master/country/createcountry'),
  editcountry: path(ROOTS_DASHBOARD, '/master/country/edit'),
  viewcountry: path(ROOTS_DASHBOARD, '/master/country/view'),

  // State
  state: path(ROOTS_DASHBOARD, '/master/state'),
  createstate: path(ROOTS_DASHBOARD, '/master/state/createstate'),
  editstate: path(ROOTS_DASHBOARD, '/master/state/edit'),
  viewstate: path(ROOTS_DASHBOARD, '/master/state/view'),

  // City
  city: path(ROOTS_DASHBOARD, '/master/city'),
  createcity: path(ROOTS_DASHBOARD, '/master/city/createcity'),
  editcity: path(ROOTS_DASHBOARD, '/master/city/edit'),
  viewcity: path(ROOTS_DASHBOARD, '/master/city/view'),

  // pincode
  pincode: path(ROOTS_DASHBOARD, '/master/pincode'),
  createpincode: path(ROOTS_DASHBOARD, '/master/pincode/createpincode'),
  editpincode: path(ROOTS_DASHBOARD, '/master/pincode/edit'),
  viewpincode: path(ROOTS_DASHBOARD, '/master/pincode/view'),

  // Currency
  currency: path(ROOTS_DASHBOARD, '/master/currency'),
  createcurrency: path(ROOTS_DASHBOARD, '/master/currency/createcurrency'),
  editcurrency: path(ROOTS_DASHBOARD, '/master/currency/edit'),
  viewcurrency: path(ROOTS_DASHBOARD, '/master/currency/view'),

  // pest
  pest: path(ROOTS_DASHBOARD, '/master/pest'),
  createpest: path(ROOTS_DASHBOARD, '/master/pest/createpest'),
  editpest: path(ROOTS_DASHBOARD, '/master/pest/edit'),
  viewpest: path(ROOTS_DASHBOARD, '/master/pest/view'),

  // unit of maesurement
  measurement: path(ROOTS_DASHBOARD, 'master/measurement'),
  createmeasurement: path(
    ROOTS_DASHBOARD,
    'master/measurement/createmeasurement'
  ),

  // vendor
  vendor: path(ROOTS_DASHBOARD, '/master/vendor'),
  createvendor: path(ROOTS_DASHBOARD, '/master/vendor/createvendor'),
  editvendor: path(ROOTS_DASHBOARD, '/master/vendor/edit'),
  viewvendor: path(ROOTS_DASHBOARD, '/master/vendor/view'),

  // Line of business
  business: path(ROOTS_DASHBOARD, '/master/business'),
  createbusiness: path(ROOTS_DASHBOARD, '/master/business/createbusiness'),
  editbusiness: path(ROOTS_DASHBOARD, '/master/business/edit'),
  viewbusiness: path(ROOTS_DASHBOARD, '/master/business/view'),

  // unit of measurement
  measurement: path(ROOTS_DASHBOARD, '/master/measurement'),
  createmeasurement: path(
    ROOTS_DASHBOARD,
    '/master/measurement/createmeasurement'
  ),
  editmeasurement: path(ROOTS_DASHBOARD, '/master/measurement/edit'),
  viewmeasurement: path(ROOTS_DASHBOARD, '/master/measurement/view'),

  // Service
  service: path(ROOTS_DASHBOARD, '/master/service'),
  sendslip: path(ROOTS_DASHBOARD, '/services/mainservice/send-slip'),
  createservice: path(ROOTS_DASHBOARD, '/master/service/createservice'),
  editservice: path(ROOTS_DASHBOARD, '/master/service/edit'),
  viewservice: path(ROOTS_DASHBOARD, '/master/service/view'),
  jobsschedule: path(ROOTS_DASHBOARD, '/jobs-schedule'),

  // Sites
  sites: path(ROOTS_DASHBOARD, '/master/sites'),
  createsites: path(ROOTS_DASHBOARD, '/master/sites/createsites'),
  editsites: path(ROOTS_DASHBOARD, '/master/sites/edit'),
  viewsites: path(ROOTS_DASHBOARD, '/master/sites/view'),

  // Vissels
  vessels: path(ROOTS_DASHBOARD, '/master/Vessels'),
  createvessels: path(ROOTS_DASHBOARD, '/master/vessels/createvessels'),
  editvessels: path(ROOTS_DASHBOARD, '/master/vessels/edit'),
  viewvessels: path(ROOTS_DASHBOARD, '/master/vessels/view'),

  // Activities
  activities: path(ROOTS_DASHBOARD, '/master/activities'),
  createactivities: path(
    ROOTS_DASHBOARD,
    '/master/activities/createactivities'
  ),
  editactivities: path(ROOTS_DASHBOARD, '/master/activities/edit'),
  viewactivities: path(ROOTS_DASHBOARD, '/master/activities/view'),

  // VendorSku
  vendorsku: path(ROOTS_DASHBOARD, '/master/vendorsku'),
  createvendorsku: path(ROOTS_DASHBOARD, '/master/vendorsku/createvendorsku'),
  editvendorsku: path(ROOTS_DASHBOARD, '/master/vendorsku/edit'),
  viewvendorsku: path(ROOTS_DASHBOARD, '/master/vendorsku/view'),

  // Inventory
  inventory: path(ROOTS_DASHBOARD, '/master/inventory'),
  createinventory: path(ROOTS_DASHBOARD, '/master/inventory/createinventory'),
  editinventory: path(ROOTS_DASHBOARD, '/master/inventory/edit'),
  viewinventory: path(ROOTS_DASHBOARD, '/master/inventory/view'),

  // Document
  document: path(ROOTS_DASHBOARD, '/master/document'),
  createdocument: path(ROOTS_DASHBOARD, '/master/document/createdocument'),
  editdocument: path(ROOTS_DASHBOARD, '/master/document/edit'),
  viewdocument: path(ROOTS_DASHBOARD, '/master/document/view'),

  // LeadSource
  leadsource: path(ROOTS_DASHBOARD, '/master/leadsource'),
  createsource: path(ROOTS_DASHBOARD, '/master/leadsource/createsource'),
  editsource: path(ROOTS_DASHBOARD, '/master/leadsource/edit'),
  viewsource: path(ROOTS_DASHBOARD, '/master/leadsource/view'),

  // Accreditation
  accreditation: path(ROOTS_DASHBOARD, '/master/accreditation'),
  createaccreditation: path(
    ROOTS_DASHBOARD,
    '/master/accreditation/createaccreditation'
  ),
  editaccreditation: path(ROOTS_DASHBOARD, '/master/accreditation/edit'),
  viewaccreditation: path(ROOTS_DASHBOARD, '/master/accreditation/view'),

  // Gst Credential
  credential: path(ROOTS_DASHBOARD, '/master/credential'),
  createcredential: path(
    ROOTS_DASHBOARD,
    '/master/credential/createcredential'
  ),
  editcredential: path(ROOTS_DASHBOARD, '/master/credential/edit'),
  viewcredential: path(ROOTS_DASHBOARD, '/master/credential/view'),

  sitesetting: path(ROOTS_DASHBOARD, '/general-setting/site-setting'),
  indivisualsetting: path(
    ROOTS_DASHBOARD,
    '/general-setting/indivisual-setting'
  ),

  //User Management
  users: path(ROOTS_DASHBOARD, '/user-management'),
  permission: path(ROOTS_DASHBOARD, '/user-management/roles'),
  createUser: path(ROOTS_DASHBOARD, '/user-management/user/create'),
  user: path(ROOTS_DASHBOARD, '/user-management/user-list'),
  edituser: path(ROOTS_DASHBOARD, '/user-management/user/edit'),
  viewuser: path(ROOTS_DASHBOARD, '/user-management/user/view'),
  createrolepermission: path(
    ROOTS_DASHBOARD,
    '/user-management/user/role-permission/create'
  ),
  assignpermission: path(
    ROOTS_DASHBOARD,
    '/user-management/user/role-permission/permission'
  ),

  //Lead
  lead: path(ROOTS_DASHBOARD, '/lead'),
  createLead: path(ROOTS_DASHBOARD, '/lead/create'),
  customers: path(ROOTS_DASHBOARD, '/customers'),
  leadview: path(ROOTS_DASHBOARD, '/lead/leadview'),
  editlead: path(ROOTS_DASHBOARD, '/lead/edit'),

  // leadview: path(ROOTS_DASHBOARD, "/leadview"),

  //Quotation
  quotation: path(ROOTS_DASHBOARD, '/quotation'),
  createQuotation: path(ROOTS_DASHBOARD, '/quotation/create'),
  editquotation: path(ROOTS_DASHBOARD, '/quotation/edit'),
  quotationview: path(ROOTS_DASHBOARD, '/quotation/quotationview'),
  quotationduplicate: path(ROOTS_DASHBOARD, '/quotation/duplicate'),

  // purchase
  purchase: path(ROOTS_DASHBOARD, '/purchasemenu/purchase'),
  createpurchase: path(
    ROOTS_DASHBOARD,
    '/purchasemenu/purchase/createpurchase'
  ),
  editpurchase: path(ROOTS_DASHBOARD, '/purchasemenu/purchase/edit'),
  viewPurchase: path(ROOTS_DASHBOARD, '/purchasemenu/purchase/view'),

  // Sales
  sales: path(ROOTS_DASHBOARD, '/purchasemenu/sales'),
  createsales: path(ROOTS_DASHBOARD, '/purchasemenu/sales/createsales'),
  editsales: path(ROOTS_DASHBOARD, '/purchasemenu/sales/edit'),
  viewsales: path(ROOTS_DASHBOARD, '/purchasemenu/sales/view'),

  // Contract
  contract: path(ROOTS_DASHBOARD, '/contract'),
  createcontract: path(ROOTS_DASHBOARD, '/contract/createcontract'),
  editcontract: path(ROOTS_DASHBOARD, '/contract/edit'),
  viewcontract: path(ROOTS_DASHBOARD, '/contract/view'),

  inbound: path(ROOTS_DASHBOARD, '/purchasemenu/inbound'),
  createinbound: path(ROOTS_DASHBOARD, '/purchasemenu/inbound/createinbound'),
  editinbound: path(ROOTS_DASHBOARD, '/purchasemenu/inbound/edit'),
  viewInbound: path(ROOTS_DASHBOARD, '/purchasemenu/inbound/view'),

  //Attendance

  attendance: path(ROOTS_DASHBOARD, '/attendance'),

  // Roster
  roster: path(ROOTS_DASHBOARD, '/roster'),
  createroster: path(ROOTS_DASHBOARD, '/roster/createroster'),

  //Services
  services: path(ROOTS_DASHBOARD, '/services'),
  mainservice: path(ROOTS_DASHBOARD, '/services/mainservice'),
  //Jobs
  jobs: path(ROOTS_DASHBOARD, '/services/jobs'),
  createjobs: path(ROOTS_DASHBOARD, '/services/jobs/createjobs'),
  editjobs: path(ROOTS_DASHBOARD, '/services/jobs/edit'),
  editjobs: path(ROOTS_DASHBOARD, '/services/jobs/edit'),
  // editjobs: path(ROOTS_DASHBOARD, "/services/jobs/edit"),

  certificates: path(ROOTS_DASHBOARD, '/services/certificates'),
  createcertificate: path(
    ROOTS_DASHBOARD,
    '/services/certificates/createcertificate'
  ),
  editcertificate: path(
    ROOTS_DASHBOARD,
    '/services/certificates/editcertificate'
  ),
  customeredit: path(ROOTS_DASHBOARD, '/services/certificates/customeredit'),

  //Operations
  operations: path(ROOTS_DASHBOARD, '/operations'),

  renewalsystem: path(ROOTS_DASHBOARD, '/renewalsystem'), 
  
  inventoryallocations: path(
    ROOTS_DASHBOARD,
    '/operations/inventoryallocations'
  ),
  createinventoryallocations: path(
    ROOTS_DASHBOARD,
    '/operations/inventoryallocations/createinventoryallocations'
  ),
  editinventoryallocations: path(
    ROOTS_DASHBOARD,
    '/operations/inventoryallocations/edit'
  ),
  viewintoryallocations: path(
    ROOTS_DASHBOARD,
    '/operations/inventoryallocations/view'
  ),

  // Goverment Document
  documents: path(ROOTS_DASHBOARD, '/governmentdocument'),
  appendix: path(ROOTS_DASHBOARD, '/governmentdocument/appendix'),

  InventoryNoMenuList: path(ROOTS_DASHBOARD, '/appendix/inventory-view'),

  // Invoice
  invoices: path(ROOTS_DASHBOARD, '/invoice/invoices'),
  createinvoice: path(ROOTS_DASHBOARD, '/invoice/createinvoice'),
  editinvoice: path(ROOTS_DASHBOARD, '/invoice/edit'),
  viewinvoice: path(ROOTS_DASHBOARD, '/invoice/view'),
  getallreceipt: path(ROOTS_DASHBOARD, '/get-all-receipt'),
  updatereceipt: path(ROOTS_DASHBOARD, '/edit-receipt'),
  // receipt
  receipt: path(ROOTS_DASHBOARD, '/invoice/receipt'),
  createReceipt: path(ROOTS_DASHBOARD, '/invoice/receipt/create'),
  editReceipt: path(ROOTS_DASHBOARD, '/invoice/receipt/edit'),
  // credit note
  creditnote: path(ROOTS_DASHBOARD, '/invoice/creditnote'),
  createCreditnote: path(ROOTS_DASHBOARD, '/invoice/creditnote/create'),

  // QuotationsView
  quotationsview: path(ROOTS_DASHBOARD, '/quotationsview'),

  // Feedback
  feedback: path(ROOTS_DASHBOARD, '/services/jobs/feedback'),
  viewactivitiesjob: path(ROOTS_DASHBOARD, '/services/jobs/viewactivities'),

  //Expense
  expense: path(ROOTS_DASHBOARD, '/expense'),

  // Port
  portlist: path(ROOTS_DASHBOARD, '/master/port'),
  createport: path(ROOTS_DASHBOARD, '/master/port/create-port'),
  editport: path(ROOTS_DASHBOARD, '/master/port/edit'),
  viewport: path(ROOTS_DASHBOARD, '/master/port/view'),

  // MIS
  mis: path(ROOTS_DASHBOARD, '/mis'),
  leadconversion: path(ROOTS_DASHBOARD, '/mis/leadconversion'),
  quotationconversion: path(ROOTS_DASHBOARD, '/mis/quotationconversion'),
  contractconversion: path(ROOTS_DASHBOARD, '/mis/contractconversion'),
  revenuenumbers: path(ROOTS_DASHBOARD, '/mis/revenuenumbers'),
  profitability: path(ROOTS_DASHBOARD, '/mis/profitability'),
  collectionpercentage: path(ROOTS_DASHBOARD, '/mis/collectionpercentage'),
  manPowerUtilization: path(ROOTS_DASHBOARD, '/mis/manPowerUtilization'),
  totalCustomerCount: path(ROOTS_DASHBOARD, '/mis/totalCustomerCount'),
  customersrevenue: path(ROOTS_DASHBOARD, '/mis/customersrevenue'),
  customerspayables: path(ROOTS_DASHBOARD, '/mis/customerspayables'),
  customersdelaying: path(ROOTS_DASHBOARD, '/mis/customersdelaying'),
  gstreports: path(ROOTS_DASHBOARD, '/mis/gstreports'),
  outstandingreports: path(ROOTS_DASHBOARD, '/mis/outstandingreport'),
  containerreports: path(ROOTS_DASHBOARD, '/mis/containerreport'),
};
