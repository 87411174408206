import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getVesselAsync,
  createVesselAsync,
  getVesselByIdAsync,
  updateVesselAsync,
  deleteVesselAsync,
} from "./vessel.async";

const initialState = {
  vesselCreateLoader: false,
  vesselCreateInfo: {},

  vesselListLoader: false,
  vesselList: [],
  vesselListInfo: {},

  vesselByIdLoading: false,
  vesselById: {},
  vesselByIdInfo: {},

  vesselUpdateLoader: false,
  vesselUpdateInfo: {},

  vesselLoading: false,
  vesselDeleteInfo: {},
};

export const vesselSlice = createSlice({
  name: "vessel",
  initialState,
  extraReducers: (builder) => {
    //Vessel Create
    builder.addMatcher(isAnyOf(createVesselAsync.pending), (state) => {
      state.vesselCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createVesselAsync.fulfilled),
      (state, action) => {
        state.vesselCreateLoader = false;
        state.vesselCreateInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(createVesselAsync.rejected), (state, action) => {
      state.vesselCreateLoader = false;
    });

    //Vessel By ID

    builder.addMatcher(isAnyOf(getVesselByIdAsync.pending), (state) => {
      state.vesselByIdLoading = true;
      state.vesselByIdInfo = {};
      state.vesselById = {};
    });
    builder.addMatcher(
      isAnyOf(getVesselByIdAsync.fulfilled),
      (state, action) => {
        state.vesselByIdLoading = false;
        state.vesselByIdInfo = {};
        state.vesselById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getVesselByIdAsync.rejected),
      (state, action) => {
        state.vesselByIdLoading = false;
        state.vesselByIdInfo = {};
        state.vesselById = {};
      }
    );
    //Vessel List
    builder.addMatcher(isAnyOf(getVesselAsync.pending), (state) => {
      state.vesselListLoader = true;
      state.vesselListInfo = {};
      state.vesselList = [];
    });
    builder.addMatcher(isAnyOf(getVesselAsync.fulfilled), (state, action) => {
      state.vesselListLoader = false;
      state.vesselListInfo = {};
      state.vesselList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getVesselAsync.rejected), (state, action) => {
      state.vesselListLoader = false;
      state.vesselListInfo = {};
      state.vesselList = [];
    });
    //Vessel Update
    builder.addMatcher(isAnyOf(updateVesselAsync.pending), (state) => {
      state.vesselUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateVesselAsync.fulfilled),
      (state, action) => {
        state.vesselUpdateLoader = false;
        state.vesselUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(updateVesselAsync.rejected), (state, action) => {
      state.vesselUpdateLoader = false;
    });
    //Vessel Delete
    builder.addMatcher(isAnyOf(deleteVesselAsync.pending), (state) => {
      state.vesselDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteVesselAsync.fulfilled),
      (state, action) => {
        state.vesselDeleteLoading = false;
        state.vesselDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(deleteVesselAsync.rejected), (state, action) => {
      state.vesselDeleteLoading = false;
    });
  },
  reducers: {
    emptyVessel: () => initialState,
  },
});
export const { emptyVessel } = vesselSlice.actions;

export default vesselSlice.reducer;
