import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllDocumentAsync,
  deleteDocumentAsync,
  createDocumentAsync,
  getDocumentByIdAsync,
  updateDocumentAsync
} from "./document.async";

const initialState = {
  documentListLoader: false,
  documentList: [],
  documentListInfo: {},

  createDocumentLoader: false,
  createDocumentInfo: {},

  documentByIdLoading: false,
  documentById: {},
  documentByIdInfo: {},

  updateDocumentLoader: false,
  updateDocumentInfo: {},

  deleteDocumentLoading: false,
  deleteDocumentInfo: {},
};

export const documentSlice = createSlice({
  name: "document",
  initialState,
  extraReducers: (builder) => {
    //Document List
     builder.addMatcher(isAnyOf(getAllDocumentAsync.pending), (state) => {
      state.documentListLoader = true;
      state.documentListInfo = {};
      state.documentList = [];
    });
    builder.addMatcher(isAnyOf(getAllDocumentAsync.fulfilled), (state, action) => {
      state.documentListLoader = false;
      state.documentListInfo = {};
      state.documentList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllDocumentAsync.rejected), (state, action) => {
      state.documentListLoader = false;
      state.documentListInfo = {};
      state.documentList = [];
    });

   
    // Create Document
    builder.addMatcher(isAnyOf(createDocumentAsync.pending), (state) => {
      state.createDocumentLoader = true;
    });
    builder.addMatcher(isAnyOf(createDocumentAsync.fulfilled), (state, action) => {
      state.createDocumentLoader = false;
      state.createDocumentInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createDocumentAsync.rejected), (state, action) => {
      state.createDocumentLoader = false;
    });


     //Document By Id
     builder.addMatcher(isAnyOf(getDocumentByIdAsync.pending), (state) => {
      state.documentByIdLoading = true;
      state.documentByIdInfo = {};
      state.documentById = {};
    });
    builder.addMatcher(
      isAnyOf(getDocumentByIdAsync.fulfilled),
      (state, action) => {
        state.documentByIdLoading = false;
        state.documentByIdInfo = {};
        state.documentById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getDocumentByIdAsync.rejected),
      (state, action) => {
        state.documentByIdLoading = false;
        state.documentByIdInfo = {};
        state.documentById = {};
      }
    );

// Document Update
builder.addMatcher(isAnyOf(updateDocumentAsync.pending), (state) => {
  state.updateDocumentLoader = true;
});
builder.addMatcher(
  isAnyOf(updateDocumentAsync.fulfilled),
  (state, action) => {
    state.updateDocumentLoader = false;
    state.updateDocumentInfo = action.payload;
  }
);
builder.addMatcher(
  isAnyOf(updateDocumentAsync.rejected),
  (state, action) => {
    state.updateDocumentLoader = false;
  }
);



    //Document Delete
    builder.addMatcher(isAnyOf(deleteDocumentAsync.pending), (state) => {
      state.deleteDocumentLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteDocumentAsync.fulfilled), (state, action) => {
      state.deleteDocumentLoading = false;
      state.deleteDocumentInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteDocumentAsync.rejected), (state, action) => {
      state.deleteDocumentLoading = false;
    });
    
  },
  reducers: {
    emptyDocument: () => initialState
  }
});
export const { emptyDocument } = documentSlice.actions;

export default documentSlice.reducer;
