import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllCurrencyAsync,
  getCurrencyWithoutPaginationAsync,
  deleteCurrencyAsync,
  createCurrencyAsync,
  getCurrencyByIdAsync,
  updateCurrencyAsync,
} from "./currency.async";

const initialState = {
  currencyListLoader: false,
  currencyList: [],
  currencyListInfo: {},

  currencyWithoutPaginationLoader: false,
  currencyListWithoutPagination: [],
  currencyListWithoutPaginationInfo: {},
  
  currencyDeleteLoading: false,
  currencyDeleteInfo: {},

  currencyCreateLoader: false,
  currencyCreateInfo: {},

  currencyByIdLoading: false,
  currencyById: {},
  currencyByIdInfo: {},

  currencyUpdateLoader: false,
  currencyUpdateInfo: {},
};

export const CurrencySlice = createSlice({
  name: "currency",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllCurrencyAsync.pending,
      ),
      (state) => {
        state.currencyListLoader = true;
        state.currencyListInfo = {};
        state.currencyList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCurrencyAsync.fulfilled),
      (state, action) => {
        state.currencyListLoader = false;
        state.currencyListInfo = {};
        state.currencyList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(
        getAllCurrencyAsync.rejected,
      ),
      (state) => {
        state.currencyListLoader = false;
        state.currencyListInfo = {};
        state.currencyList = [];
      }
    );

    // Currency without pagination and filter
    builder.addMatcher(isAnyOf(getCurrencyWithoutPaginationAsync.pending), (state) => {
      state.currencyWithoutPaginationLoader = true;
      state.currencyListWithoutPaginationInfo = {};
      state.currencyListWithoutPagination = [];
    });
    builder.addMatcher(
      isAnyOf(getCurrencyWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.currencyWithoutPaginationLoader = false;
        state.currencyListWithoutPaginationInfo = {}
        state.currencyListWithoutPagination = action?.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getCurrencyWithoutPaginationAsync.rejected),
      (state) => {
        state.currencyWithoutPaginationLoader = false;
        state.currencyListWithoutPaginationInfo = {};
        state.currencyListWithoutPagination = [];
      }
    );

    //Currency Delete 
    builder.addMatcher(isAnyOf(deleteCurrencyAsync.pending), (state) => {
      state.currencyDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteCurrencyAsync.fulfilled), (state, action) => {
      state.currencyDeleteLoading = false;
      state.currencyDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteCurrencyAsync.rejected), (state) => {
      state.currencyDeleteLoading = false;
    });

    // Currency Create
    builder.addMatcher(isAnyOf(createCurrencyAsync.pending), (state) => {
      state.currencyCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createCurrencyAsync.fulfilled), (state, action) => {
      state.currencyCreateLoader = false;
      state.currencyCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createCurrencyAsync.rejected), (state) => {
      state.currencyCreateLoader = false;
    });

    //Currency By Id 
    builder.addMatcher(isAnyOf(getCurrencyByIdAsync.pending), (state) => {
      state.currencyByIdLoading = true;
      state.currencyByIdInfo = {};
      state.currencyById = {};
    });
    builder.addMatcher(isAnyOf(getCurrencyByIdAsync.fulfilled), (state, action) => {
      state.currencyByIdLoading = false;
      state.currencyByIdInfo = {};
      state.currencyById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getCurrencyByIdAsync.rejected), (state) => {
      state.currencyByIdLoading = false;
      state.currencyByIdInfo = {};
      state.currencyById = {};
    });

    //Currency Update 
    builder.addMatcher(isAnyOf(updateCurrencyAsync.pending), (state) => {
      state.currencyUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateCurrencyAsync.fulfilled), (state, action) => {
      state.currencyUpdateLoader = false;
      state.currencyUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateCurrencyAsync.rejected), (state) => {
      state.currencyUpdateLoader = false;
    });
  },
  reducers: {
    emptyCurrency: () => initialState
  }
});
export const { emptyCurrency } = CurrencySlice.actions;

export default CurrencySlice.reducer;
