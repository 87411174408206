import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllUserAsync = createAsyncThunk(
  "admin/getAllUser",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/user/list-user`, [], toolkit, payload);
  }
);
export const getAllUserWithoutPaginationAsync = createAsyncThunk(
  "admin/getAllUserWithoutPaginationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/user/list-user`,
      [],
      toolkit
    );
  }
);
export const createUserAsync = createAsyncThunk(
  "admin/createUser",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/user/add-user`, payload, toolkit);
  }
);
export const updateStaffByIdAsync = createAsyncThunk(
  "admin/updateStaffById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PATCH",
      `/updateStaffById/${payload.id}`,
      payload,
      toolkit
    );
  }
);
export const updateUserAsync = createAsyncThunk(
  "admin/updateUser",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/user/update-user/${payload.id}`,
      payload,
      toolkit
    );
  }
);
export const getUserByIdAsync = createAsyncThunk(
  "admin/getUserById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/user/get-user/${payload}`, [], toolkit);
  }
);

export const getSubjectByMultipleClassIdAsync = createAsyncThunk(
  "admin/getSubjectByMultipleClassIds",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getSubjectByMultipleClassIds`,
      payload,
      toolkit
    );
  }
);

export const updateStaffBatchDetailsAsync = createAsyncThunk(
  "admin/updateStaffBatchDetails",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PATCH",
      `/updateStaffBatchDetails/${payload.id}`,
      payload,
      toolkit
    );
  }
);
export const deleteUserDetailsAsync = createAsyncThunk(
  "admin/deleteUserDetails",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/user/delete-user/${payload}`,
      {},
      toolkit
    );
  }
);
