import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllInvoiceAsync,
  createInvoiceAsync,
  getInvoiceByIdAsync,
  updateInvoiceAsync,
  invoicedateUpdateAsync,
  deleteInvoiceAsync,
  getQuotationAndJobByCustomerIdAsync,
  updateInvoiceStatusAsync,
  getInvoiceDocumentHtmlByDocumentIdAsync,
  getInvoiceDocumentDropdownAsync,
  getTotalAmount,
  invoiceEmailPdfAsync,
  getBankAccountsByIdAsync,
  getAllReceiptsByIdAsync,
  getReceiptsByIdAsync,
  updateReceiptAsync,
  getBankAccountsBySiteIdAsync,
} from "./invoice.async";

const initialState = {
  invoiceCreateLoader: false,
  invoiceCreateInfo: {},

  invoiceListLoader: false,
  invoiceList: [],
  invoiceListInfo: {},

  invoiceDeleteLoading: false,
  invoiceDeleteInfo: {},

  invoiceByIdLoading: false,
  invoiceById: {},
  invoiceByIdInfo: {},

  invoiceUpdateLoader: false,
  invoiceUpdateInfo: {},

  bankAccountsByIdLoading: false,
  bankAccountsByIdList: [],
  bankAccountsByIdInfo: {},

  bankAccountsBySiteIdLoading: false,
  bankAccountsBySiteIdList: [],
  bankAccountsBySiteIdInfo: {},

  getAllReceiptsByIdLoading: false,
  getAllReceiptsByIdList: [],
  getAllReceiptsByIdInfo: {},

  quotationAndJobByCustomerIdByIdLoading: false,
  quotationAndJobByCustomerIdList: {},
  quotationAndJobByCustomerIdInfo: {},

  invoiceStatusUpdateLoader: false,

  invoiceStatusUpdateInfo: {},

  invoiceDocumentDropDownLoader: false,
  invoiceDocumentDropDown: [],

  invoiceDocumentHtmlByDocumentIdLoading: false,
  invoiceDocumentHtmlByDocumentId: {},
  invoiceDocumentHtmlByDocumentIdInfo: {},
  invoiceTotalAmountLoader: false,
  invoiceTotalAmount: [],

  invoiceEmailPdfLoader: false,
  invoiceEmailPdfInfo: {},

  receiptByIdLoading: false,
  receiptById: {},
  receiptByIdInfo: {},

  receiptUpdateLoader: false,
  receiptUpdateInfo: {},
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  extraReducers: (builder) => {
    //Invoice Email Pdf
    builder.addMatcher(isAnyOf(invoiceEmailPdfAsync.pending), (state) => {
      state.invoiceEmailPdfLoader = true;
    });
    builder.addMatcher(
      isAnyOf(invoiceEmailPdfAsync.fulfilled),
      (state, action) => {
        state.invoiceEmailPdfLoader = false;
        state.invoiceEmailPdfInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(invoiceEmailPdfAsync.rejected),
      (state, action) => {
        state.invoiceEmailPdfLoader = false;
      }
    );

    // Create Invoice
    builder.addMatcher(isAnyOf(createInvoiceAsync.pending), (state) => {
      state.invoiceCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createInvoiceAsync.fulfilled),
      (state, action) => {
        state.invoiceCreateLoader = false;
        state.invoiceCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createInvoiceAsync.rejected),
      (state, action) => {
        state.invoiceCreateLoader = false;
      }
    );

    //State List
    builder.addMatcher(isAnyOf(getAllInvoiceAsync.pending), (state) => {
      state.invoiceListLoader = true;
      state.invoiceListInfo = {};
      state.invoiceList = [];
      state.stateListLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllInvoiceAsync.fulfilled),
      (state, action) => {
        state.invoiceListLoader = false;
        state.invoiceListInfo = {};
        state.invoiceList = action.payload || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInvoiceAsync.rejected),
      (state, action) => {
        state.invoiceListLoader = false;
        state.invoiceListInfo = {};
        state.invoiceList = [];
      }
    );

    //State Delete
    builder.addMatcher(isAnyOf(deleteInvoiceAsync.pending), (state) => {
      state.invoiceDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteInvoiceAsync.fulfilled),
      (state, action) => {
        state.invoiceDeleteLoading = false;
        state.invoiceDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteInvoiceAsync.rejected),
      (state, action) => {
        state.invoiceDeleteLoading = false;
      }
    );

    //State By Id
    builder.addMatcher(isAnyOf(getInvoiceByIdAsync.pending), (state) => {
      state.invoiceByIdLoading = true;
      state.invoiceByIdInfo = {};
      state.invoiceById = {};
    });
    builder.addMatcher(
      isAnyOf(getInvoiceByIdAsync.fulfilled),
      (state, action) => {
        state.invoiceByIdLoading = false;
        state.invoiceByIdInfo = {};
        state.invoiceById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoiceByIdAsync.rejected),
      (state, action) => {
        state.invoiceByIdLoading = false;
        state.invoiceByIdInfo = {};
        state.invoiceById = {};
      }
    );

    //Invoice Update
    builder.addMatcher(isAnyOf(updateInvoiceAsync.pending), (state) => {
      state.invoiceUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateInvoiceAsync.fulfilled),
      (state, action) => {
        state.invoiceUpdateLoader = false;
        state.invoiceUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInvoiceAsync.rejected),
      (state, action) => {
        state.invoiceUpdateLoader = false;
      }
    );

    //Invoice Update
    builder.addMatcher(isAnyOf(invoicedateUpdateAsync.pending), (state) => {
      state.invoicedateUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(invoicedateUpdateAsync.fulfilled),
      (state, action) => {
        state.invoicedateUpdateLoader = false;
        state.invoicedateUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(invoicedateUpdateAsync.rejected),
      (state, action) => {
        state.invoicedateUpdateLoader = false;
      }
    );

    // Quotation and Job by Customer Id
    builder.addMatcher(
      isAnyOf(getQuotationAndJobByCustomerIdAsync.pending),
      (state) => {
        state.quotationAndJobByCustomerIdByIdLoading = true;
        state.quotationAndJobByCustomerIdInfo = {};
        state.quotationAndJobByCustomerIdList = [];
        state.invoiceListLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationAndJobByCustomerIdAsync.fulfilled),
      (state, action) => {
        state.quotationAndJobByCustomerIdByIdLoading = false;
        state.quotationAndJobByCustomerIdInfo = {};
        state.quotationAndJobByCustomerIdList = action.payload || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getQuotationAndJobByCustomerIdAsync.rejected),
      (state, action) => {
        state.quotationAndJobByCustomerIdByIdLoading = false;
        state.quotationAndJobByCustomerIdInfo = {};
        state.quotationAndJobByCustomerIdList = [];
      }
    );

    // Bank Accounts by Site Id
    builder.addMatcher(
      isAnyOf(getBankAccountsBySiteIdAsync.pending),
      (state) => {
        state.bankAccountsByIdLoading = true;
        state.bankAccountsByIdInfo = {};
        state.bankAccountsBySiteIdList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getBankAccountsBySiteIdAsync.fulfilled),
      (state, action) => {
        state.bankAccountsBySiteIdLoading = false;
        state.bankAccountsBySiteIdInfo = {};
        state.bankAccountsBySiteIdList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getBankAccountsBySiteIdAsync.rejected),
      (state, action) => {
        state.bankAccountsBySiteIdLoading = false;
        state.bankAccountsBySiteIdInfo = {};
        state.bankAccountsBySiteIdList = [];
      }
    );
    // Bank Accounts by Site Id
    builder.addMatcher(isAnyOf(getBankAccountsByIdAsync.pending), (state) => {
      state.bankAccountsBySiteIdLoading = true;
      state.bankAccountsBySiteIdInfo = {};
      state.bankAccountsByIdList = [];
    });
    builder.addMatcher(
      isAnyOf(getBankAccountsByIdAsync.fulfilled),
      (state, action) => {
        state.bankAccountsByIdLoading = false;
        state.bankAccountsByIdInfo = {};
        state.bankAccountsByIdList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getBankAccountsByIdAsync.rejected),
      (state, action) => {
        state.bankAccountsByIdLoading = false;
        state.bankAccountsByIdInfo = {};
        state.bankAccountsByIdList = [];
      }
    );
    // Bank Accounts by Id
    builder.addMatcher(isAnyOf(getAllReceiptsByIdAsync.pending), (state) => {
      state.getAllReceiptsByIdLoading = true;
      state.getAllReceiptsByIdInfo = {};
      state.getAllReceiptsByIdList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllReceiptsByIdAsync.fulfilled),
      (state, action) => {
        state.getAllReceiptsByIdLoading = false;
        state.getAllReceiptsByIdInfo = {};
        state.getAllReceiptsByIdList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllReceiptsByIdAsync.rejected),
      (state, action) => {
        state.getAllReceiptsByIdLoading = false;
        state.getAllReceiptsByIdInfo = {};
        state.getAllReceiptsByIdList = [];
      }
    );

    //Invoice Status Update
    builder.addMatcher(isAnyOf(updateInvoiceStatusAsync.pending), (state) => {
      state.invoiceStatusUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateInvoiceStatusAsync.fulfilled),
      (state, action) => {
        state.invoiceStatusUpdateLoader = false;

        state.invoiceStatusUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInvoiceStatusAsync.rejected),
      (state, action) => {
        state.invoiceStatusUpdateLoader = false;
      }
    );

    // Contract document Drop Down List

    builder.addMatcher(
      isAnyOf(getInvoiceDocumentDropdownAsync.pending),
      (state) => {
        state.invoiceDocumentDropDownLoader = true;
        state.invoiceDocumentDropDown = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoiceDocumentDropdownAsync.fulfilled),
      (state, action) => {
        state.invoiceDocumentDropDownLoader = false;
        state.invoiceDocumentDropDown = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoiceDocumentDropdownAsync.rejected),
      (state, action) => {
        state.invoiceDocumentDropDownLoader = false;
        state.invoiceDocumentDropDown = [];
      }
    );
    // Contract document Drop Down List

    builder.addMatcher(isAnyOf(getTotalAmount.pending), (state) => {
      state.invoiceTotalAmountLoader = true;
      state.invoiceTotalAmount = [];
    });
    builder.addMatcher(isAnyOf(getTotalAmount.fulfilled), (state, action) => {
      state.invoiceTotalAmountLoader = false;
      state.invoiceTotalAmount = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getTotalAmount.rejected), (state, action) => {
      state.invoiceTotalAmountLoader = false;
      state.invoiceTotalAmount = [];
    });

    //Document Html By Document Id
    builder.addMatcher(
      isAnyOf(getInvoiceDocumentHtmlByDocumentIdAsync.pending),
      (state) => {
        state.invoiceDocumentHtmlByDocumentIdLoading = true;
        state.invoiceDocumentHtmlByDocumentIdInfo = {};
        state.invoiceDocumentHtmlByDocumentId = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoiceDocumentHtmlByDocumentIdAsync.fulfilled),
      (state, action) => {
        state.invoiceDocumentHtmlByDocumentIdLoading = false;
        state.invoiceDocumentHtmlByDocumentIdInfo = {};
        // required later
        // state.invoiceDocumentHtmlByDocumentId = action.payload?.data;
        state.invoiceDocumentHtmlByDocumentId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoiceDocumentHtmlByDocumentIdAsync.rejected),
      (state, action) => {
        state.invoiceDocumentHtmlByDocumentIdLoading = false;
        state.invoiceDocumentHtmlByDocumentIdInfo = {};
        state.invoiceDocumentHtmlByDocumentId = {};
      }
    );

    //Receipt edit
    builder.addMatcher(isAnyOf(getReceiptsByIdAsync.pending), (state) => {
      state.receiptByIdLoading = true;
      state.receiptByIdInfo = {};
      state.receiptById = {};
    });
    builder.addMatcher(
      isAnyOf(getReceiptsByIdAsync.fulfilled),
      (state, action) => {
        state.receiptByIdLoading = false;
        state.receiptByIdInfo = {};
        state.receiptById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getReceiptsByIdAsync.rejected),
      (state, action) => {
        state.receiptByIdLoading = false;
        state.receiptByIdInfo = {};
        state.receiptById = {};
      }
    );

    //Receipt Update
    builder.addMatcher(isAnyOf(updateReceiptAsync.pending), (state) => {
      state.receiptUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateReceiptAsync.fulfilled),
      (state, action) => {
        state.receiptUpdateLoader = false;
        state.receiptUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateReceiptAsync.rejected),
      (state, action) => {
        state.receiptUpdateLoader = false;
      }
    );
  },
  reducers: {
    emptyInvoice: (state) => {
      return {
        ...initialState,
      };
    },
  },
});
export const { emptyInvoice } = invoiceSlice.actions;
export default invoiceSlice.reducer;
