import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";
import { id } from "date-fns/locale";

export const getRolesAsync = createAsyncThunk(
  "admin/getRolesAsync",
  async (params, toolkit) => {
    return await AxiosClient("GET", `/setting/list-role`, [], toolkit, params);
  }
);

export const getRoleWithoutPaginationAsync = createAsyncThunk(
  "admin/getRoleWithoutPaginationAsync",
  async (params, toolkit) => {
    return await AxiosClient("GET", `/setting/list-role`, [], toolkit);
  }
);

// getRoleWithoutPaginationAsync

// required later
// export const getRoleWithoutPaginationAsync = createAsyncThunk(
//   "admin/getAllRoleWithoutPaginationAsync",
//   async (payload, toolkit) => {
//     return await AxiosClient("GET", `/seetting/list-role`, [], toolkit);
//   }
// );

export const createRoleAsync = createAsyncThunk(
  "admin/createRoles",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/setting/create-role`, payload, toolkit);
  }
);

export const getRoleByIdAsync = createAsyncThunk(
  "admin/getRoleById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/setting/get-role/${payload}`,
      payload,
      toolkit
    );
  }
);

export const updatRoleByIdAsync = createAsyncThunk(
  "admin/updateRoleById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/setting/update-role/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const deleteRoleByIdAsync = createAsyncThunk(
  "admin/deleteRoleById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/setting/delete-role/${payload}`,
      [],
      toolkit
    );
  }
);

// Assign Permission
export const assignPermissionAsync = createAsyncThunk(
  "admin/addPermissionById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-permission/${payload.menu}`,
      payload,
      toolkit
    );
  }
);
// Assign Permission
export const getPermissionByRoleIdAsync = createAsyncThunk(
  "admin/get-permission",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-permission/payload, toolkit`,
      payload,
      toolkit
    );
  }
);

export const getMenuAsync = createAsyncThunk(
  "admin/getMenuAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-permission/${payload}`,
      [],
      toolkit
    );
  }
);

// Get Permission By Id
export const getRolePermissionByIdAsync = createAsyncThunk(
  "admin/getRolePermissionByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-permission/${payload}`,
      [],
      toolkit
    );
  }
);
