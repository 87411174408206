import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getTotalCustomerCountAreaChartDataAsync = createAsyncThunk(
  "mis/getTotalCustomerCountAreaChartDataAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-total-customer-count`,
      payload,
      toolkit
    );
  }
);
