import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

// getAllLeadSourcewithoutpaginationAsync
export const getAllLeadSourcewithoutpaginationAsync = createAsyncThunk(
  "master/getAllLeadSourcewithoutpaginationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-source`,[],
      toolkit
    );
  }
);

// getAllLeadSourceAsync

export const getAllLeadSourceAsync = createAsyncThunk(
  "master/getAllLeadSourceAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-source?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createLeadSourceAsync = createAsyncThunk(
  "master/createLeadSourceAsync",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-source`, payload, toolkit);
  }
);

export const updateLeadSourceAsync = createAsyncThunk(
  "admin/updateLeadSourceAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-source/${payload.id}`,
      payload?.info,
      toolkit
    );
  }
);

export const getLeadSourceByIdAsync = createAsyncThunk(
  "master/getLeadSourceByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-source/${payload}`,
      [],
      toolkit
    );
  }
);

export const deleteLeadSourceAsync = createAsyncThunk(
  "master/deleteLeadSourceAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-source/${payload}`,
      [],
      toolkit
    );
  }
);
