import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createUomAsync,
  getAllUomAsync,
  deleteUomAsync,
  getUomByIdAsync,
  updateUomAsync,
  getUomWithoutPaginationAsync
} from "./uom.async";

const initialState = {
  uomCreateLoader: false,
  uomCreateInfo: {},

  uomListLoader: false,
  uomList: [],
  uomListInfo: {},

  uomDeleteLoading: false,
  uomDeleteInfo:{},

  uomByIdLoading: false,
  uomById: {},
  uomByIdInfo:{}, 

  uomUpdateLoader: false,
  uomUpdateInfo: {},

  uomWithoutPaginationLoader: false,
  uomListWithoutPagination: [],
  uomListWithoutPaginationInfo: {},
};

export const uomSlice = createSlice({
  name: "uom",
  initialState,
  extraReducers: (builder) => {

    // Create uom
    builder.addMatcher(isAnyOf(createUomAsync.pending), (state) => {
      state.uomCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createUomAsync.fulfilled), (state, action) => {
      state.uomCreateLoader = false;
      state.uomCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createUomAsync.rejected), (state, action) => {
      state.uomCreateLoader = false;
    });

    //Uom List 
    builder.addMatcher(isAnyOf(getAllUomAsync.pending), (state) => {
      state.uomListLoader = true;
      state.uomListInfo = {};
      state.uomList = [];
    });
    builder.addMatcher(isAnyOf(getAllUomAsync.fulfilled), (state, action) => {
      state.uomListLoader = false;
      state.uomListInfo = {};
      state.uomList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllUomAsync.rejected), (state, action) => {
      state.uomListLoader = false;
      state.uomListInfo = {};
      state.uomList = [];
    });

    //Uom Delete 
    builder.addMatcher(isAnyOf(deleteUomAsync.pending), (state) => {
      state.uomDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteUomAsync.fulfilled), (state, action) => {
      state.uomDeleteLoading = false;
      state.uomDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteUomAsync.rejected), (state, action) => {
      state.uomDeleteLoading = false;
    });
    
    
    //Uom By Id 
    builder.addMatcher(isAnyOf(getUomByIdAsync.pending), (state) => {
      state.uomByIdLoading = true;
      state.uomByIdInfo = {};
      state.uomById = {};
    });
    builder.addMatcher(isAnyOf(getUomByIdAsync.fulfilled), (state, action) => {
      state.uomByIdLoading = false;
      state.uomByIdInfo = {};
      state.uomById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getUomByIdAsync.rejected), (state, action) => {
      state.uomByIdLoading = false;
      state.uomByIdInfo = {};
      state.uomById = {};
    });

    //Uom Update 
    builder.addMatcher(isAnyOf(updateUomAsync.pending), (state) => {
      state.uomUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateUomAsync.fulfilled), (state, action) => {
      state.uomUpdateLoader = false;
      state.uomUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateUomAsync.rejected), (state, action) => {
      state.uomUpdateLoader = false;
    });

    // UOM  (unit of measurement without pagination and search filter)
    builder.addMatcher(isAnyOf(getUomWithoutPaginationAsync.pending), (state) => {
      state.uomWithoutPaginationLoader = true;
      state.uomListWithoutPaginationInfo = {};
      state.uomListWithoutPagination = [];
    });
    builder.addMatcher(isAnyOf(getUomWithoutPaginationAsync.fulfilled), (state, action) => {
      state.uomWithoutPaginationLoader = false;
      state.uomListWithoutPaginationInfo = {};
      state.uomListWithoutPagination = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getUomWithoutPaginationAsync.rejected), (state, action) => {
      state.uomWithoutPaginationLoader = false;
      state.uomListWithoutPaginationInfo = {};
      state.uomListWithoutPagination = [];
    });
  },
  reducers: {
    emptyUom: () => initialState,
  },
});
export const { emptyUom } = uomSlice.actions;

export default uomSlice.reducer;
