import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllPortAsync = createAsyncThunk(
  "master/getAllPort", 
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-ports?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

//create post

export const createPortAsync = createAsyncThunk(
  "master/port",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-port`, payload, toolkit);
  }
);

//getByID State
export const getPortByIdAsync = createAsyncThunk(
  "master/PortById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-port/${payload}`, [], toolkit);
  });

  //update State
  export const updatePortAsync = createAsyncThunk(
    "admin/updatePort",
    async (payload, toolkit) => {
      return await AxiosClient("PUT", `/master/update-port/${payload.id}`, payload, toolkit);
    }
  );

   //Delete State

  export const deletePortAsync = createAsyncThunk(
    "master/deletePort",
    async (payload, toolkit) => {
      return await AxiosClient("DELETE", `/master/delete-port/${payload}`, [], toolkit);
    });


    //getState By CountryId
    export const getPortByCountryIdAsync = createAsyncThunk(
      "master/getStateByCountry",
      async (payload, toolkit) => {
        return await AxiosClient("GET",`/master/lookup-ports?countryId=${payload?.countryid}`, [], toolkit);
      });

      export const getAllPortWithOutPaginationAsync = createAsyncThunk(
        "master/getAllPortWithOutPagination", 
        async (payload, toolkit) => {
          return await AxiosClient(
            "GET",
            `/master/list-ports`,
            [],
            toolkit
          );
        }
      );
  

      export const getMultiplePortByMultipleCountryIdAsync = createAsyncThunk(
        "master/port",
        async (payload, toolkit) => {
          return await AxiosClient("POST", `/master/lookup-port`, payload, toolkit);
        }
      );



