import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllSalesAsync,
  deleteSalesAsync,
  getSalesByIdAsync,
  deleteExpectedItemAsync,
  updateSaleAsync,
  createSaleAsync,
  getItemSkuDropdownByPOIdAsync,
  updateSalesStatusAsync
} from "./sales.async";

const initialState = {
  salesCreateLoader: false,
  salesCreateInfo: {},

  salesListLoader: false,
  salesList: [],
  salesListInfo: {},

  salesDeleteLoading: false,
  salesDeleteInfo: {},

  salesByIdLoading: false,
  salesById: {},
  salesByIdInfo: {},

  salesUpdateLoader: false,
  salesUpdateInfo: {},

  ItemSkuDropdownByPOIdLoading: false,
  ItemSkuDropdownByPOId: [],
  ItemSkuDropdownByPOIdInfo: [],

  updateSalesStatusLoader: false,
  updateSalesStatusInfo: {}
};

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  extraReducers: (builder) => {
    //Sales Status Update
    builder.addMatcher(isAnyOf(updateSalesStatusAsync.pending), (state) => {
      state.updateSalesStatusLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateSalesStatusAsync.fulfilled),
      (state, action) => {
        state.updateSalesStatusLoader = false;
        state.updateSalesStatusInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateSalesStatusAsync.rejected),
      (state, action) => {
        state.updateSalesStatusLoader = false;
      }
    );

    //ItemSku Dropdown By PO Id
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownByPOIdAsync.pending),
      (state) => {
        state.ItemSkuDropdownByPOIdLoading = true;
        state.ItemSkuDropdownByPOIdInfo = {};
        state.ItemSkuDropdownByPOId = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownByPOIdAsync.fulfilled),
      (state, action) => {
        state.ItemSkuDropdownByPOIdLoading = false;
        state.ItemSkuDropdownByPOIdInfo = {};
        state.ItemSkuDropdownByPOId = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownByPOIdAsync.rejected),
      (state, action) => {
        state.ItemSkuDropdownByPOIdLoading = false;
        state.ItemSkuDropdownByPOIdInfo = {};
        state.ItemSkuDropdownByPOId = {};
      }
    );

    // Create sales
    builder.addMatcher(isAnyOf(createSaleAsync.pending), (state) => {
      state.salesCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createSaleAsync.fulfilled), (state, action) => {
      state.salesCreateLoader = false;
      state.salesCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createSaleAsync.rejected), (state, action) => {
      state.salesCreateLoader = false;
    });

    // Update sales
    builder.addMatcher(isAnyOf(updateSaleAsync.pending), (state) => {
      state.salesUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateSaleAsync.fulfilled), (state, action) => {
      state.salesUpdateLoader = false;
      state.salesUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateSaleAsync.rejected), (state, action) => {
      state.saleUpdateLoader = false;
    });

    //sales List
    builder.addMatcher(isAnyOf(getAllSalesAsync.pending), (state) => {
      state.salesListLoader = true;
      state.salesListInfo = {};
      state.salesList = [];
    });
    builder.addMatcher(isAnyOf(getAllSalesAsync.fulfilled), (state, action) => {
      state.salesListLoader = false;
      state.salesListInfo = {};
      state.salesList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllSalesAsync.rejected), (state, action) => {
      state.salesListLoader = false;
      state.salesListInfo = {};
      state.salesList = [];
    });

    // sales Delete
    builder.addMatcher(isAnyOf(deleteSalesAsync.pending), (state) => {
      state.salesDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteSalesAsync.fulfilled), (state, action) => {
      state.salesDeleteLoading = false;
      state.salesListInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteSalesAsync.rejected), (state, action) => {
      state.salesDeleteLoading = false;
    });

    //Sales By Id
    builder.addMatcher(isAnyOf(getSalesByIdAsync.pending), (state) => {
      state.salesByIdLoading = true;
      state.salesByIdInfo = {};
      state.salesById = {};
    });
    builder.addMatcher(
      isAnyOf(getSalesByIdAsync.fulfilled),
      (state, action) => {
        state.salesByIdLoading = false;
        state.salesByIdInfo = {};
        state.salesById = action.payload?.data;
      }
    );
    builder.addMatcher(isAnyOf(getSalesByIdAsync.rejected), (state, action) => {
      state.salesByIdLoading = false;
      state.salesByIdInfo = {};
      state.salesById = {};
    });

    // delete expected id
    builder.addMatcher(isAnyOf(deleteExpectedItemAsync.pending), (state) => {
      state.expectedItemDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteExpectedItemAsync.fulfilled),
      (state, action) => {
        state.expectedItemDeleteLoading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteExpectedItemAsync.rejected),
      (state, action) => {
        state.expectedItemDeleteLoading = false;
      }
    );
  },
  reducers: {
    emptySales: () => initialState
  }
});
export const { emptySales } = salesSlice.actions;

export default salesSlice.reducer;
