import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllSalesAsync = createAsyncThunk(
  "/getAllSalesAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/sales/list-sales?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const deleteSalesAsync = createAsyncThunk(
  "sales/deletesale",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/sales/delete-sales/${payload}`,
      [],
      toolkit
    );
  }
);
export const deleteExpectedItemAsync = createAsyncThunk(
  "sales/deleteExpectedItem",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/sales/delete-sales/${payload?.Id}/expecteditems/${payload?.expectedId}`,
      [],
      toolkit
    );
  }
);
export const createSaleAsync = createAsyncThunk(
  "/sales/create-sales",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/sales/create-sales`, payload, toolkit);
  }
);
export const updateSaleAsync = createAsyncThunk(
  "admin/updateSaleAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/sales/update-sales/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const getSalesByIdAsync = createAsyncThunk(
  "/getSalesById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/sales/get-sales/${payload}`, [], toolkit);
  }
);

export const getItemSkuDropdownByPOIdAsync = createAsyncThunk(
  "/getItemSkuDropdownByPOId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/sales/get-expectedSKU-dropdown/${payload.POId}`,
      [],
      toolkit
    );
  }
);

// Update Sales status
export const updateSalesStatusAsync = createAsyncThunk(
  "updateSalesStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/sales/update-sales-status/${payload.id}`,
      payload,
      toolkit
    );
  }
);
