import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createCityAsync,
  getAllCityAsync,
  deleteCityAsync,
  getCityByIdAsync,
  updateCityAsync,
  getCityByStateIdAsync,
  getAllCityWithoutPagginationAsync,
} from "./city.async";

const initialState = {
  cityCreateLoader: false,
  cityCreateInfo: {},

  cityListLoader: false,
  cityList: [],
  cityListInfo: {},

  cityListWithoutPagginationLoader: false,
  cityListWithoutPaggination: [],
  cityListWithoutPagginationInfo: {},

  cityDeleteLoading: false,
  cityDeleteInfo: {},

  cityByIdLoading: false,
  cityById: {},
  cityByIdInfo: {},

  cityUpdateLoader: false,
  cityUpdateInfo: {},

  getCityByStateIdLoader: false,
  getCityByStateId: [],
};

export const citySlice = createSlice({
  name: "city",
  initialState,
  extraReducers: (builder) => {
    // Create city
    builder.addMatcher(isAnyOf(createCityAsync.pending), (state) => {
      state.cityCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createCityAsync.fulfilled), (state, action) => {
      state.cityCreateLoader = false;
      state.cityCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createCityAsync.rejected), (state, action) => {
      state.cityCreateLoader = false;
    });

    // List  city
    builder.addMatcher(isAnyOf(getAllCityAsync.pending), (state) => {
      state.cityListLoader = true;
      state.cityListInfo = {};
      state.cityList = [];
    });
    builder.addMatcher(isAnyOf(getAllCityAsync.fulfilled), (state, action) => {
      state.cityListLoader = false;
      state.cityListInfo = {};
      state.cityList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllCityAsync.rejected), (state, action) => {
      state.cityListLoader = false;
      state.cityListInfo = {};
      state.cityList = [];
    });

    // get  city without paggination
    builder.addMatcher(
      isAnyOf(getAllCityWithoutPagginationAsync.pending),
      (state) => {
        state.cityListWithoutPagginationLoader = true;
        state.cityListWithoutPagginationInfo = {};
        state.cityListWithoutPaggination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCityWithoutPagginationAsync.fulfilled),
      (state, action) => {
        state.cityListWithoutPagginationLoader = false;
        state.cityListWithoutPagginationInfo = {};
        state.cityListWithoutPaggination = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCityWithoutPagginationAsync.rejected),
      (state, action) => {
        state.cityListWithoutPagginationLoader = false;
        state.cityListWithoutPagginationInfo = {};
        state.cityListWithoutPaggination = [];
      }
    );

    // Delete  city
    builder.addMatcher(isAnyOf(deleteCityAsync.pending), (state) => {
      state.cityDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteCityAsync.fulfilled), (state, action) => {
      state.cityDeleteLoading = false;
      state.cityDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteCityAsync.rejected), (state, action) => {
      state.cityDeleteLoading = false;
    });

    //city By Id
    builder.addMatcher(isAnyOf(getCityByIdAsync.pending), (state) => {
      state.cityByIdLoading = true;
      state.cityByIdInfo = {};
      state.cityById = {};
    });
    builder.addMatcher(isAnyOf(getCityByIdAsync.fulfilled), (state, action) => {
      state.cityByIdLoading = false;
      state.cityByIdInfo = {};
      state.cityById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getCityByIdAsync.rejected), (state, action) => {
      state.cityByIdLoading = false;
      state.cityByIdInfo = {};
      state.cityById = {};
    });

    // Update city
    builder.addMatcher(isAnyOf(updateCityAsync.pending), (state) => {
      state.cityUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateCityAsync.fulfilled), (state, action) => {
      state.cityUpdateLoader = false;
      state.cityUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateCityAsync.rejected), (state, action) => {
      state.cityUpdateLoader = false;
    });

    //getCity BY State Id
    builder.addMatcher(isAnyOf(getCityByStateIdAsync.pending), (state) => {
      state.getCityByStateIdLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getCityByStateIdAsync.fulfilled),
      (state, action) => {
        state.getCityByStateIdLoader = false;
        state.getCityByStateId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getCityByStateIdAsync.rejected),
      (state, action) => {
        state.getCityByStateIdLoader = false;
      }
    );
  },
  reducers: {
    emptyCity: () => initialState,
  },
});
export const { emptyCity } = citySlice.actions;

export default citySlice.reducer;
