import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

export const getAllContractAsync = createAsyncThunk(
  "contract/getAllcontract",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/list-contract?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&quotation=${
        payload.quotation
      }&customer=${payload.customer || ""}&lob=${payload.lob || ""}&site=${payload.site || ""}`,
      [],
      toolkit
    );
  }
);

export const deleteContractAsync = createAsyncThunk(
  "contract/deletecontract",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/contract/delete-contract/${payload}`,
      [],
      toolkit
    );
  }
);

export const createContractAsync = createAsyncThunk(
  "contract/contract",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/contract/create-contract`,
      payload,
      toolkit
    );
  }
);

export const getContractByIdAsync = createAsyncThunk(
  "contract/getcontractById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/get-contract/${payload}`,
      [],
      toolkit
    );
  }
);

export const getServiceByQuotationAsync = createAsyncThunk(
  "contract/get-quotation-service",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/get-quotation-service/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateContractAsync = createAsyncThunk(
  "contract/updatecontract",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/contract/update-contract/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// Get Contract without pagination and search filter
export const getContractWithoutPaginationAsync = createAsyncThunk(
  "contract/getAllcontract",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/contract/list-contract`, [], toolkit);
  }
);

// get-contract-document

export const getContractDocumentDropdownAsync = createAsyncThunk(
  "contract/getContractDocumentDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/list-document-dropdown/`,
      [],
      toolkit
    );
  }
);

// get-document-html
export const getContractDocumentHtmlByDocumentIdAsync = createAsyncThunk(
  "contract/getContractDocumentHtmlByDocumentId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/contract/get-document-html/${payload?.documentId}/${payload?.contractId}`,
      [],
      toolkit
    );
  }
);

//download Contract Csv File
export const contractExcelDownloadAsync = ({
  page,
  limit,
  search,
  quotation,
}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/contract/contract-export-to-csv?token=${
      isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null
    }&page=${page || 1}&limit=${limit || 10}&search=${
      search || ""
    }&quotation=${quotation}`,
    "_parent"
  );
};

//  Contract Send Email
export const contractEmailPdfAsync = createAsyncThunk(
  "contractEmailPdf",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/contract/save-pdf-and-mail/${payload.id}`,
      payload,
      toolkit
    );
  }
);
