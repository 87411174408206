import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllCountryAsync = createAsyncThunk(
  "master/getAllCountry",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-country?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createCountryAsync = createAsyncThunk(
  "master/country",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-country`,
      payload,
      toolkit
    );
  }
);

export const deleteCountryAsync = createAsyncThunk(
  "master/deletecountry",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-country/${payload}`,
      [],
      toolkit
    );
  }
);

export const getCountryByIdAsync = createAsyncThunk(
  "master/getcountryById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-country/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateCountryAsync = createAsyncThunk(
  "admin/updatecountry",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-country/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// Get Country without pagination and search filter
export const getCountryWithoutPaginationAsync = createAsyncThunk(
  "master/getAllCountry",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-country`,
      [],
      toolkit
    );
  }
);
