import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

//GET ALL List
export const getAllInventoryAllocationAsync = createAsyncThunk(
  "operations/getAllInventoryAllocationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inventoryallocation/list-inventoryAllocation?page=${
        payload?.page || 1
      }&limit=${payload?.limit || 10}&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

// delete
export const deleteInventoryAllocationAsync = createAsyncThunk(
  "operations/deleteInventoryAllocationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/inventoryallocation/delete-inventoryAllocation/${payload}`,
      [],
      toolkit
    );
  }
);

//itemSkku-dropdown
export const getItemSkuDropdownAsync = createAsyncThunk(
  "operations/getItemSkuDropdownAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inventoryallocation/list-itemSku-dropDown`,
      [],
      toolkit
    );
  }
);

// create
export const createInventoryAllocationAsync = createAsyncThunk(
  "operations/createInventoryAllocationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/inventoryallocation/create-inventoryAllocation`,
      payload,
      toolkit
    );
  }
);

// get by id
export const getInventoryAllocationByIdAsync = createAsyncThunk(
  "operations/getInventoryAllocationByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/inventoryallocation/list-single-inventoryAllocation/${payload}`,
      [],
      toolkit
    );
  }
);

//   update
export const updateInventoryAllocationAsync = createAsyncThunk(
  "operations/updateInventoryAllocationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/inventoryallocation/update-inventoryAllocation/${payload.id}`,
      payload,
      toolkit
    );
  }
);

//   update
export const updateInventoryAllocationStatusAsync = createAsyncThunk(
  "operations/updateInventoryAllocationStatusAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/inventoryallocation/update-inventoryAllocation-status/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// requires quantity update
export const updateReturnedQuantityAllocationAsync = createAsyncThunk(
  "operations/updateReturnedQuantityAllocationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/inventoryallocation/update-returned-quantity/${payload.id}`,
      payload,
      toolkit
    );
  }
);
