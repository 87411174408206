import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllServicesAsync = createAsyncThunk(
  "master/getAllServices",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/list-services?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&quotation=${payload?.quotation || ""}`,
      [],
      toolkit
    );
  }
);
export const getAllJobsSchedule = createAsyncThunk(
  "master/getAllJobsSchedule",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/list-job-schedule/${payload?.id}?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }`
      // Required later 
      // &search=${payload?.search || ""}`
      ,
      [],
      toolkit
    );
  }
);

export const getServiceSlipListDocumentDropdowndAsync = createAsyncThunk(
  "contract/getContractDocumentDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/get-service-document-dropdown/`,
      [],
      toolkit
    );
  }
);


// get-document-html
export const getServiceSlipDocumentHtmlByServiceIdAsync = createAsyncThunk(
  "service/getServiceSlipDocumentHtmlByServiceId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/get-document-html/${payload?.documentId}/${payload?.serviceId}`,
      [],
      toolkit
    );
  }
);

// get-document-html
export const getDocumentsOfServiceIdAsync = createAsyncThunk(
  "service/getServiceSlipDocumentHtmlByServiceId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/service/get-documents-of-service/${payload?.serviceId}`,
      [],
      toolkit
    );
  }
);

export const cancelCertificateAsync = createAsyncThunk(
  "service/cancelCertificateAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/service/certificate/cancel-status/${payload?.id}`,
      payload,
      toolkit
    );
  }
);

export const cancelJobAsync = createAsyncThunk(
  "service/cancelJobAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/service/job/cancel-status/${payload?.id}`,
      payload,
      toolkit
    );
  }
);

export const shareJobsSiteAsync = createAsyncThunk(
  "service/shareJobsSiteAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/service/job/share-jobs-site/${payload?.id}`,
      payload,
      toolkit
    );
  }
);



