import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createPestAsync,
  getAllPestAsync,
  deletePestAsync,
  getPestByIdAsync,
  updatePestAsync,
  getAllPestWithoutPaginationAsync,
} from "./pest.async";

const initialState = {
  PestCreateLoader: false,
  PestCreateInfo: {},

  PestListLoader: false,
  PestList: [],
  PestListInfo: {},

  PestListWithoutPaginationLoader: false,
  PestListWithoutPagination: [],
  PestListWithoutPaginationInfo: {},

  PestDeleteLoading: false,
  PestDeleteInfo: {},

  PestByIdLoading: false,
  PestById: {},
  PestByIdInfo: {},

  PestUpdateLoader: false,
  PestUpdateInfo: {},

 
};

export const PestSlice = createSlice({
  name: "Pest",
  initialState,
  extraReducers: (builder) => {
    // Create Pest
    builder.addMatcher(isAnyOf(createPestAsync.pending), (state) => {
      state.PestCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createPestAsync.fulfilled), (state, action) => {
      state.PestCreateLoader = false;
      state.PestCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createPestAsync.rejected), (state, action) => {
      state.PestCreateLoader = false;
    });

    // List  Pest
    builder.addMatcher(isAnyOf(getAllPestAsync.pending), (state) => {
      state.PestListLoader = true;
      state.PestListInfo = {};
      state.PestList = [];
    });
    builder.addMatcher(isAnyOf(getAllPestAsync.fulfilled), (state, action) => {
      state.PestListLoader = false;
      state.PestListInfo = {};
      state.PestList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllPestAsync.rejected), (state, action) => {
      state.PestListLoader = false;
      state.PestListInfo = {};
      state.PestList = [];
    });

    // get  Pest without Pagination
    builder.addMatcher(
      isAnyOf(getAllPestWithoutPaginationAsync.pending),
      (state) => {
        state.PestListWithoutPaginationLoader = true;
        state.PestListWithoutPaginationInfo = {};
        state.PestListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPestWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.PestListWithoutPaginationLoader = false;
        state.PestListWithoutPaginationInfo = {};
        state.PestListWithoutPagination = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPestWithoutPaginationAsync.rejected),
      (state, action) => {
        state.PestListWithoutPaginationLoader = false;
        state.PestListWithoutPaginationInfo = {};
        state.PestListWithoutPagination = [];
      }
    );

    // Delete  Pest
    builder.addMatcher(isAnyOf(deletePestAsync.pending), (state) => {
      state.PestDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deletePestAsync.fulfilled), (state, action) => {
      state.PestDeleteLoading = false;
      state.PestDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deletePestAsync.rejected), (state, action) => {
      state.PestDeleteLoading = false;
    });

    //Pest By Id
    builder.addMatcher(isAnyOf(getPestByIdAsync.pending), (state) => {
      state.PestByIdLoading = true;
      state.PestByIdInfo = {};
      state.PestById = {};
    });
    builder.addMatcher(isAnyOf(getPestByIdAsync.fulfilled), (state, action) => {
      state.PestByIdLoading = false;
      state.PestByIdInfo = {};
      state.PestById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getPestByIdAsync.rejected), (state, action) => {
      state.PestByIdLoading = false;
      state.PestByIdInfo = {};
      state.PestById = {};
    });

    // Update Pest
    builder.addMatcher(isAnyOf(updatePestAsync.pending), (state) => {
      state.PestUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updatePestAsync.fulfilled), (state, action) => {
      state.PestUpdateLoader = false;
      state.PestUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updatePestAsync.rejected), (state, action) => {
      state.PestUpdateLoader = false;
    });

  
  },
  reducers: {
    emptyPest: () => initialState,
  },
});
export const { emptyPest } = PestSlice.actions;

export default PestSlice.reducer;
