import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getCollectionPercentageChartDataAsync = createAsyncThunk(
  "mis/getCollectionPercentageChartData",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-collection-percentage-chart-data`,
      payload,
      toolkit
    );
  }
);

