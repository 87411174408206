import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createUomAsync = createAsyncThunk(
  "master/Uom",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-uom`, payload, toolkit);
  });

export const getAllUomAsync = createAsyncThunk(
  "master/getAllUom",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-uom?page=${payload?.page || 1}&limit=${payload?.limit || 10}&search=${payload?.search || ""}`, [], toolkit);
  });

export const deleteUomAsync = createAsyncThunk(
  "master/deleteUom",
  async (payload, toolkit) => {
    return await AxiosClient("DELETE", `/master/delete-uom/${payload}`, [], toolkit);
  });

export const getUomByIdAsync = createAsyncThunk(
  "master/getUomById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-uom/${payload}`, [], toolkit);
  });

  
export const updateUomAsync = createAsyncThunk(
  "admin/updateUom",
  async (payload, toolkit) => {
    return await AxiosClient("PUT", `/master/update-uom/${payload.id}`, payload, toolkit);
  }
);

// UOM  (unit of measurement without pagination and search filter)
export const getUomWithoutPaginationAsync = createAsyncThunk(
  "master/getAllUom",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-uom`, [], toolkit);
  });