import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createLOBAsync,
  getAllLOBAsync,
  deleteLOBAsync,
  getLOBByIdAsync,
  updateLOBAsync,
  getAllLOBwithoutpaginationAsync
} from "./lob.async";

const initialState = {
  lobCreateLoader: false,
  lobCreateInfo: {},

  lobListLoader: false,
  lobList: [],
  lobListInfo: {},

  lobListwithoutpaginationLoader: false,
  lobListwithoutpagination: [],
  lobListwithoutpaginationInfo: {},

  lobDeleteLoading: false,
  lobDeleteInfo:{},

  lobByIdLoading: false,
  lobById: {},
  lobByIdInfo:{}, 

  lobUpdateLoader: false,
  lobUpdateInfo: {},
};

export const lobSlice = createSlice({
  name: "lob",
  initialState,
  extraReducers: (builder) => {

    // Create LOB
    builder.addMatcher(isAnyOf(createLOBAsync.pending), (state) => {
      state.lobCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createLOBAsync.fulfilled), (state, action) => {
      state.lobCreateLoader = false;
      state.lobCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createLOBAsync.rejected), (state, action) => {
      state.lobCreateLoader = false;
    });

     //LOB List  without pagination
     builder.addMatcher(isAnyOf(getAllLOBwithoutpaginationAsync.pending), (state) => {
      state.lobListwithoutpaginationLoader = true;
      state.lobListwithoutpaginationInfo = {};
      state.lobListwithoutpagination = [];
    });
    builder.addMatcher(isAnyOf(getAllLOBwithoutpaginationAsync.fulfilled), (state, action) => {
      state.lobListwithoutpaginationLoader = false;
      state.lobListwithoutpaginationInfo = {};
      state.lobListwithoutpagination = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllLOBwithoutpaginationAsync.rejected), (state, action) => {
      state.lobListwithoutpaginationLoader = false;
      state.lobListwithoutpaginationInfo = {};
      state.lobListwithoutpagination = [];
    });

    //LOB List 
    builder.addMatcher(isAnyOf(getAllLOBAsync.pending), (state) => {
      state.lobListLoader = true;
      state.lobListInfo = {};
      state.lobList = [];
    });
    builder.addMatcher(isAnyOf(getAllLOBAsync.fulfilled), (state, action) => {
      state.lobListLoader = false;
      state.lobListInfo = {};
      state.lobList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllLOBAsync.rejected), (state, action) => {
      state.lobListLoader = false;
      state.lobListInfo = {};
      state.lobList = [];
    });

    //LOB Delete 
    builder.addMatcher(isAnyOf(deleteLOBAsync.pending), (state) => {
      state.lobDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deleteLOBAsync.fulfilled), (state, action) => {
      state.lobDeleteLoading = false;
      state.lobDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deleteLOBAsync.rejected), (state, action) => {
      state.lobDeleteLoading = false;
    });
    
    
    //LOB By Id 
    builder.addMatcher(isAnyOf(getLOBByIdAsync.pending), (state) => {
      state.lobByIdLoading = true;
      state.lobByIdInfo = {};
      state.lobById = {};
    });
    builder.addMatcher(isAnyOf(getLOBByIdAsync.fulfilled), (state, action) => {
      state.lobByIdLoading = false;
      state.lobByIdInfo = {};
      state.lobById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getLOBByIdAsync.rejected), (state, action) => {
      state.lobByIdLoading = false;
      state.lobByIdInfo = {};
      state.lobById = {};
    });

    //LOB Update 
    builder.addMatcher(isAnyOf(updateLOBAsync.pending), (state) => {
      state.lobUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateLOBAsync.fulfilled), (state, action) => {
      state.lobUpdateLoader = false;
      state.lobUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updateLOBAsync.rejected), (state, action) => {
      state.lobUpdateLoader = false;
    });
  },
  reducers: {
    emptyLOB: () => initialState,
  },
});
export const { emptyLOB } = lobSlice.actions;

export default lobSlice.reducer;
