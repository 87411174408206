import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllInventoryAllocationAsync,
  deleteInventoryAllocationAsync,
  createInventoryAllocationAsync,
  getInventoryAllocationByIdAsync,
  updateInventoryAllocationAsync,
  updateInventoryAllocationStatusAsync,
  getItemSkuDropdownAsync,
  updateReturnedQuantityAllocationAsync
} from "./allocation.async";

const initialState = {
  allocationListLoader: false,
  allocationList: [],
  allocationListInfo: {},

  itemSkuDropdownListLoader: false,
  itemSkuDropdownList: [],
  itemSkuDropdownListInfo: {},

  allocationDeleteLoading: false,
  allocationDeleteInfo: {},

  allocationCreateLoader: false,
  allocationCreateInfo: {},

  allocationByIdLoading: false,
  allocationById: {},
  allocationByIdInfo: {},

  allocationUpdateLoader: false,
  allocationUpdateInfo: {},

  allocationStatusUpdateLoader: false,
  allocationStatusUpdateInfo: {},

  updateReturnedQuantityAllocationLoader: false,
  returnedQuantityAllocationUpdateInfo: {}
};

export const allocationSlice = createSlice({
  name: "allocation",
  initialState,
  extraReducers: (builder) => {
    //  List
    builder.addMatcher(
      isAnyOf(getAllInventoryAllocationAsync.pending),
      (state) => {
        state.allocationListLoader = true;
        state.allocationListInfo = {};
        state.allocationList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInventoryAllocationAsync.fulfilled),
      (state, action) => {
        state.allocationListLoader = false;
        state.allocationListInfo = {};
        state.allocationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllInventoryAllocationAsync.rejected),
      (state, action) => {
        state.allocationListLoader = false;
        state.allocationListInfo = {};
        state.allocationList = [];
      }
    );

    // delete
    builder.addMatcher(
      isAnyOf(deleteInventoryAllocationAsync.pending),
      (state) => {
        state.allocationDeleteLoading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteInventoryAllocationAsync.fulfilled),
      (state, action) => {
        state.allocationDeleteLoading = false;
        state.allocationDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteInventoryAllocationAsync.rejected),
      (state, action) => {
        state.allocationDeleteLoading = false;
      }
    );

    // create
    builder.addMatcher(
      isAnyOf(createInventoryAllocationAsync.pending),
      (state) => {
        state.allocationCreateLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(createInventoryAllocationAsync.fulfilled),
      (state, action) => {
        state.allocationCreateLoader = false;
        state.allocationCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createInventoryAllocationAsync.rejected),
      (state, action) => {
        state.allocationCreateLoader = false;
      }
    );

    //get By Id
    builder.addMatcher(
      isAnyOf(getInventoryAllocationByIdAsync.pending),
      (state) => {
        state.allocationByIdLoading = true;
        state.allocationByIdInfo = {};
        state.allocationById = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getInventoryAllocationByIdAsync.fulfilled),
      (state, action) => {
        state.allocationByIdLoading = false;
        state.allocationByIdInfo = {};
        state.allocationById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getInventoryAllocationByIdAsync.rejected),
      (state, action) => {
        state.allocationByIdLoading = false;
        state.allocationByIdInfo = {};
        state.allocationById = {};
      }
    );

    // Update
    builder.addMatcher(
      isAnyOf(updateInventoryAllocationAsync.pending),
      (state) => {
        state.allocationUpdateLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInventoryAllocationAsync.fulfilled),
      (state, action) => {
        state.allocationUpdateLoader = false;
        state.allocationUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInventoryAllocationAsync.rejected),
      (state, action) => {
        state.allocationUpdateLoader = false;
      }
    );

    // Update Status
    builder.addMatcher(
      isAnyOf(updateInventoryAllocationStatusAsync.pending),
      (state) => {
        state.allocationStatusUpdateLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInventoryAllocationStatusAsync.fulfilled),
      (state, action) => {
        state.allocationStatusUpdateLoader = false;
        state.allocationStatusUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInventoryAllocationStatusAsync.rejected),
      (state, action) => {
        state.allocationStatusUpdateLoader = false;
      }
    );

    //   ItemSku-dropdown
    builder.addMatcher(isAnyOf(getItemSkuDropdownAsync.pending), (state) => {
      state.itemSkuDropdownListLoader = true;
      state.itemSkuDropdownListInfo = {};
      state.itemSkuDropdownList = [];
    });
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownAsync.fulfilled),
      (state, action) => {
        state.itemSkuDropdownListLoader = false;
        state.itemSkuDropdownListInfo = {};
        state.itemSkuDropdownList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getItemSkuDropdownAsync.rejected),
      (state, action) => {
        state.itemSkuDropdownListLoader = false;
        state.itemSkuDropdownListInfo = {};
        state.itemSkuDropdownList = [];
      }
    );

    //  return quantity
    builder.addMatcher(
      isAnyOf(updateReturnedQuantityAllocationAsync.pending),
      (state) => {
        state.updateReturnedQuantityAllocationLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(updateReturnedQuantityAllocationAsync.fulfilled),
      (state, action) => {
        state.updateReturnedQuantityAllocationLoader = false;
        state.returnedQuantityAllocationUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateReturnedQuantityAllocationAsync.rejected),
      (state, action) => {
        state.updateReturnedQuantityAllocationLoader = false;
      }
    );
  },

  reducers: {
    emptyAllocation: () => initialState
  }
});

export const { emptyAllocation } = allocationSlice.actions;

export default allocationSlice.reducer;
