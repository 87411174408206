import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const createCityAsync = createAsyncThunk(
  "master/city",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-city`, payload, toolkit);
  }
);

export const getAllCityAsync = createAsyncThunk(
  "master/getAllcity",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-city?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const deleteCityAsync = createAsyncThunk(
  "master/deletecity",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-city/${payload}`,
      [],
      toolkit
    );
  }
);

export const getCityByIdAsync = createAsyncThunk(
  "master/getcityById",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/get-city/${payload}`, [], toolkit);
  }
);

export const updateCityAsync = createAsyncThunk(
  "admin/updatecity",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-city/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// get city without paggination
export const getAllCityWithoutPagginationAsync = createAsyncThunk(
  "master/getAllCityWithoutPagginationAsync",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-city`, [], toolkit);
  }
);

//get city by stateId
export const getCityByStateIdAsync = createAsyncThunk(
  "master/getStateByCountryId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/lookup-city?stateId=${payload?.stateId}`,
      [],
      toolkit
    );
  }
);
