import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

// getAllservicewithoutpagination

export const getAllServicewithoutpaginationAsync = createAsyncThunk(
  "master/getAllServicewithoutpagination",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-service`, [], toolkit);
  }
);

// getallservice with pagination
export const getAllServiceAsync = createAsyncThunk(
  "master/getAllService",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-service?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

//create post

export const createServiceAsync = createAsyncThunk(
  "master/state",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-service`,
      payload,
      toolkit
    );
  }
);

//getByID Service
export const getServiceByIdAsync = createAsyncThunk(
  "master/getStateById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-service/${payload}`,
      [],
      toolkit
    );
  }
);

//update Service
export const updateServiceAsync = createAsyncThunk(
  "admin/updateLob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-service/${payload.id}`,
      payload,
      toolkit
    );
  }
);

//Delete Service

export const deleteServiceAsync = createAsyncThunk(
  "master/deleteLob",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-service/${payload}`,
      [],
      toolkit
    );
  }
);

export const getAllActivityAsync = createAsyncThunk(
  "master/getAlllistActivity",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-activity`, [], toolkit);
  }
);

//list inventory getAll for equipment and chemical
export const getAllInventoryAsync = createAsyncThunk(
  "master/getAllInventory",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/master/list-inventory`, [], toolkit);
  }
);
