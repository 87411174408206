import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllLeadSourceAsync,
  createLeadSourceAsync,
  getLeadSourceByIdAsync,
  updateLeadSourceAsync,
  deleteLeadSourceAsync,
  getAllLeadSourcewithoutpaginationAsync,
} from "./leadsource.async";

const initialState = {
  leadsourceCreateLoader: false,
  leadsourceCreateInfo: {},


  leadsourceListwithoutpaginationLoader: false,
  leadsourceListwithoutpagination: [],
  leadsourceListwithoutpaginationInfo: {},

  leadsourceListLoader: false,
  leadsourceList: [],
  leadsourceListInfo: {},

  leadsourceByIdLoading: false,
  leadsourceById: {},
  leadsourceByIdInfo: {},

  loleadsourceUpdateLoader: false,
  leadsourceUpdateInfo: {},

  leadsourceUpdateLoader: false,
  leadsourceUpdateInfo: {},

  leadsourceLoading: false,
  leadsourceDeleteInfo: {},
};

export const leadsourceSlice = createSlice({
  name: "leadsource",
  initialState,
  extraReducers: (builder) => {


       //LeadSource List without pagination
       builder.addMatcher(isAnyOf(getAllLeadSourcewithoutpaginationAsync.pending), (state) => {
        state.leadsourceListwithoutpaginationLoader = true;
        state.leadsourceListwithoutpaginationInfo = {};
        state.leadsourceListwithoutpaginationInfo = [];
      });
      builder.addMatcher(
        isAnyOf(getAllLeadSourcewithoutpaginationAsync.fulfilled),
        (state, action) => {
          state.leadsourceListwithoutpaginationLoader = false;
          state.leadsourceListwithoutpaginationInfo = {};
          state.leadsourceListwithoutpagination = action.payload?.data || [];
        }
      );
      builder.addMatcher(
        isAnyOf(getAllLeadSourcewithoutpaginationAsync.rejected),
        (state, action) => {
          state.leadsourceListwithoutpaginationLoader = false;
          state.leadsourceListwithoutpaginationInfo = {};
          state.leadsourceListwithoutpagination = [];
        }
      );
    //LeadSource Create
    builder.addMatcher(isAnyOf(createLeadSourceAsync.pending), (state) => {
      state.leadsourceCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createLeadSourceAsync.fulfilled),
      (state, action) => {
        state.leadsourceCreateLoader = false;
        state.leadsourceCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createLeadSourceAsync.rejected),
      (state, action) => {
        state.leadsourceCreateLoader = false;
      }
    );

    //LeadSource By ID

    builder.addMatcher(isAnyOf(getLeadSourceByIdAsync.pending), (state) => {
      state.leadsourceByIdLoading = true;
      state.leadsourceByIdInfo = {};
      state.leadsourceById = {};
    });
    builder.addMatcher(
      isAnyOf(getLeadSourceByIdAsync.fulfilled),
      (state, action) => {
        state.leadsourceByIdLoading = false;
        state.leadsourceByIdInfo = {};
        state.leadsourceById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getLeadSourceByIdAsync.rejected),
      (state, action) => {
        state.leadsourceByIdLoading = false;
        state.leadsourceByIdInfo = {};
        state.leadsourceById = {};
      }
    );
    //LeadSource List
    builder.addMatcher(isAnyOf(getAllLeadSourceAsync.pending), (state) => {
      state.leadsourceListLoader = true;
      state.leadsourceListInfo = {};
      state.leadsourceList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllLeadSourceAsync.fulfilled),
      (state, action) => {
        state.leadsourceListLoader = false;
        state.leadsourceListInfo = {};
        state.leadsourceList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllLeadSourceAsync.rejected),
      (state, action) => {
        state.leadsourceListLoader = false;
        state.leadsourceListInfo = {};
        state.leadsourceList = [];
      }
    );
    //LeadSource Update
    builder.addMatcher(isAnyOf(updateLeadSourceAsync.pending), (state) => {
      state.leadsourceUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateLeadSourceAsync.fulfilled),
      (state, action) => {
        state.leadsourceUpdateLoader = false;
        state.leadsourceUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateLeadSourceAsync.rejected),
      (state, action) => {
        state.leadsourceUpdateLoader = false;
      }
    );
    //LeadSource Delete
    builder.addMatcher(isAnyOf(deleteLeadSourceAsync.pending), (state) => {
      state.leadsourceDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteLeadSourceAsync.fulfilled),
      (state, action) => {
        state.leadsourceDeleteLoading = false;
        state.leadsourceDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteLeadSourceAsync.rejected),
      (state, action) => {
        state.leadsourceDeleteLoading = false;
      }
    );
  },
  reducers: {
    emptyLeadSource: () => initialState,
  },
});
export const { emptyLeadSource } = leadsourceSlice.actions;

export default leadsourceSlice.reducer;
