import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllJobsAsync,
  createJobsAsync,
  getAllServicesAsync,
  getJobByIdAsync,
  getCurrencyAsync,
  getAllJobsWithoutPaginationAsync,
  updateJobAsync,
  updateJobStatusAsync,
  getAllPortAsyncForJob,
  listItemCovered,
  getCommonSiteById,
  getActivityViewByJobIdAsync,
  getAccreditationByServiceIdAsync,
  getJobByCustomerIdAsync,
  deleteJobAsync,
  getAddressByServiceIdAsync

} from "./jobs.async";

const initialState = {
  jobsCreateLoader: false,
  jobsCreated: {},

  jobsListServicesLoader: false,
  jobsListServicesInfo: {},
  jobsListServices: {},

  siteLoader : false,
  siteInfo : {},
  siteList : {},

  jobsListLoader: false,
  jobsList: [],
  jobsListInfo: {},

  jobByIdLoading: false,
  jobById: {},

  jobDeleteLoading: false,
  jobDeleteInfo: {},

  getCurrencyLoader: false,
  getCurrencyForCreateJob: {},

  jobsListWithoutPaginationLoader: false,
  jobsListWithoutPagination: [],
  jobsListWithoutPaginationInfo: {},

  jobUpdateLoader: false,
  jobUpdateData: {},

  jobStatusLoading: false,
  jobStatusUpdateById: {},

  getAllPortsLoader: false,
  getAllPort: {},
  listItemLoader: false,
  listItem: {},

  
  activityViewByJobIdLoading: false,
  activityViewByJobIdInfo: {},
  activityViewByJobId: {},

  
  accreditationByServiceIdLoader: false,
  accreditationByServiceIdInfo: {},
  accreditationByServiceId:[],

  getJobByCustomerIdLoader: false,
  getJobByCustomerIdInfo: {},
  getJobByCustomerId:[],

  
  addressByServiceIdLoading: false,
  addressByServiceId: [],
  addressByServiceIdInfo: {},

};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  extraReducers: (builder) => {
    // Jobs List
    builder.addMatcher(isAnyOf(getAllJobsAsync.pending), (state) => {
      state.jobsListLoader = true;
      state.jobsListInfo = {};
      state.jobsList = [];
    });
    builder.addMatcher(isAnyOf(getAllJobsAsync.fulfilled), (state, action) => {
      state.jobsListLoader = false;
      state.jobsListInfo = {};
      state.jobsList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllJobsAsync.rejected), (state, action) => {
      state.jobsListLoader = false;
      state.jobsListInfo = {};
      state.jobsList = [];
    });

        //Job Delete
        builder.addMatcher(isAnyOf(deleteJobAsync.pending), (state) => {
          state.jobDeleteLoading = true;
        });
        builder.addMatcher(isAnyOf(deleteJobAsync.fulfilled), (state, action) => {
          state.jobDeleteLoading = false;
          state.jobDeleteInfo = action.payload;
        });
        builder.addMatcher(isAnyOf(deleteJobAsync.rejected), (state, action) => {
          state.jobDeleteLoading = false;
        });

    // Get Service List
    builder.addMatcher(isAnyOf(getAllServicesAsync.pending), (state) => {
      state.jobsListServicesLoader = true;
      state.jobsListServicesInfo = {};
      state.jobsListServices = [];
    });
    builder.addMatcher(
      isAnyOf(getAllServicesAsync.fulfilled),
      (state, action) => {
        state.jobsListServicesLoader = false;
        state.jobsListServicesInfo = {};
        state.jobsListServices = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllServicesAsync.rejected),
      (state, action) => {
        state.jobsListServicesLoader = false;
        state.jobsListServicesInfo = {};
        state.jobsListServices = [];
      }
    );

    // Get Site List By Id
    builder.addMatcher(isAnyOf(getCommonSiteById.pending), (state) => {
      state.siteLoader = true;
      state.siteInfo = {};
      state.siteList = [];
    });
    builder.addMatcher(
      isAnyOf(getCommonSiteById.fulfilled),
      (state, action) => {
        state.siteLoader = false;
        state.siteInfo = {};
        state.siteList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCommonSiteById.rejected),
      (state, action) => {
        state.siteLoader = false;
        state.siteInfo = {};
        state.siteList = [];
      }
    );

    // Get Currency from Backend
    builder.addMatcher(isAnyOf(getCurrencyAsync.pending), (state) => {
      state.getCurrencyLoader = true;
      state.getCurrencyInfo = {};
    });
    builder.addMatcher(isAnyOf(getCurrencyAsync.fulfilled), (state, action) => {
      state.getCurrencyLoader = false;
      state.getCurrencyInfo = {};
      state.getCurrencyForCreateJob = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getCurrencyAsync.rejected), (state, action) => {
      state.getCurrencyLoader = false;
      state.getCurrencyInfo = {};
    });

    // Create Jobs
    builder.addMatcher(isAnyOf(createJobsAsync.pending), (state) => {
      state.jobsCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createJobsAsync.fulfilled), (state, action) => {
      state.jobsCreateLoader = false;
      state.jobsCreated = action.payload;
    });
    builder.addMatcher(isAnyOf(createJobsAsync.rejected), (state, action) => {
      state.jobsCreateLoader = false;
    });

    //Job By ID

    builder.addMatcher(isAnyOf(getJobByIdAsync.pending), (state) => {
      state.jobByIdLoading = true;
      state.jobByIdInfo = {};
      state.jobById = {};
    });
    builder.addMatcher(isAnyOf(getJobByIdAsync.fulfilled), (state, action) => {
      state.jobByIdLoading = false;
      state.jobByIdInfo = {};
      state.jobById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getJobByIdAsync.rejected), (state, action) => {
      state.jobByIdLoading = false;
    });

    //ItemCovered By ID

    builder.addMatcher(isAnyOf(listItemCovered.pending), (state) => {
      state.listItemLoader = true;
      state.listItem = {};
    });
    builder.addMatcher(isAnyOf(listItemCovered.fulfilled), (state, action) => {
      state.listItemLoader = false;
      state.listItem = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(listItemCovered.rejected), (state, action) => {
      state.listItemLoader = false;
      state.listItem = {};

    });

    //Update Job Status By ID

    builder.addMatcher(isAnyOf(updateJobStatusAsync.pending), (state) => {
      state.jobStatusLoading = true;
      state.jobStatusInfo = {};
    });
    builder.addMatcher(
      isAnyOf(updateJobStatusAsync.fulfilled),
      (state, action) => {
        state.jobStatusLoading = false;
        state.jobStatusInfo = {};
        state.jobStatusUpdateById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateJobStatusAsync.rejected),
      (state, action) => {
        state.jobStatusLoading = false;
      }
    );

    //  without pagianation
    builder.addMatcher(
      isAnyOf(getAllJobsWithoutPaginationAsync.pending),
      (state) => {
        state.jobsListWithoutPaginationLoader = true;
        state.jobsListWithoutPaginationInfo = {};
        state.jobsListWithoutPagination = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllJobsWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.jobsListWithoutPaginationLoader = false;
        state.jobsListWithoutPaginationInfo = {};
        state.jobsListWithoutPagination = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllJobsWithoutPaginationAsync.rejected),
      (state, action) => {
        state.jobsListWithoutPaginationLoader = false;
        state.jobsListWithoutPaginationInfo = {};
        state.jobsListWithoutPagination = [];
      }
    );

    //Job Update

    builder.addMatcher(isAnyOf(updateJobAsync.pending), (state) => {
      state.jobUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updateJobAsync.fulfilled), (state, action) => {
      state.jobUpdateLoader = false;
      state.jobUpdateData = action.payload;
    });
    builder.addMatcher(isAnyOf(updateJobAsync.rejected), (state, action) => {
      state.jobUpdateLoader = false;
    });

    //Get Ports name for Job

    builder.addMatcher(isAnyOf(getAllPortAsyncForJob.pending), (state) => {
      state.getAllPortsLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAllPortAsyncForJob.fulfilled),
      (state, action) => {
        state.getAllPortsLoader = false;
        state.getAllPort = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllPortAsyncForJob.rejected),
      (state, action) => {
        state.getAllPortsLoader = false;
      }
    );

      //Get Activity View By JobId
      builder.addMatcher(isAnyOf(getActivityViewByJobIdAsync.pending), (state) => {
        state.activityViewByJobIdLoading = true;
        state.activityViewByJobIdInfo = {};
        state.activityViewByJobId = {};
      });
      builder.addMatcher(
        isAnyOf(getActivityViewByJobIdAsync.fulfilled),
        (state, action) => {
          state.activityViewByJobIdLoading = false;
          state.activityViewByJobIdInfo = {};
          state.activityViewByJobId = action.payload?.data;
        }
      );
      builder.addMatcher(isAnyOf(getActivityViewByJobIdAsync.rejected), (state, action) => {
        state.activityViewByJobIdLoading = false;
        state.activityViewByJobIdInfo = {};
        state.activityViewByJobId = {};
      });

          // Accreditation By Service id
    builder.addMatcher(isAnyOf(getAccreditationByServiceIdAsync.pending), (state) => {
      state.accreditationByServiceIdLoader = true;
      state.accreditationByServiceIdInfo = {};
      state.accreditationByServiceId = [];
    });
    builder.addMatcher(isAnyOf(getAccreditationByServiceIdAsync.fulfilled), (state, action) => {
      state.accreditationByServiceIdLoader = false;
      state.accreditationByServiceIdInfo = {};
      state.accreditationByServiceId = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAccreditationByServiceIdAsync.rejected), (state, action) => {
      state.accreditationByServiceIdLoader = false;
      state.accreditationByServiceIdInfo = {};
      state.accreditationByServiceId = [];
    });


        // get Job By Customer Id List
        builder.addMatcher(isAnyOf(getJobByCustomerIdAsync.pending), (state) => {
          state.getJobByCustomerIdLoader = true;
          state.getJobByCustomerIdInfo = {};
          state.getJobByCustomerId = [];
        });
        builder.addMatcher(isAnyOf(getJobByCustomerIdAsync.fulfilled), (state, action) => {
          state.getJobByCustomerIdLoader = false;
          state.getJobByCustomerIdInfo = {};
          state.getJobByCustomerId = action.payload.data || [];
        });
        builder.addMatcher(isAnyOf(getJobByCustomerIdAsync.rejected), (state, action) => {
          state.getJobByCustomerIdLoader = false;
          state.getJobByCustomerIdInfo = {};
          state.getJobByCustomerId = [];
        });

        // Get Address By ServiceIdd
    builder.addMatcher(isAnyOf(getAddressByServiceIdAsync.pending), (state) => {
      state.addressByServiceIdLoading = true;
      state.addressByServiceIdInfo = {};
      state.addressByServiceId = [];
    });
    builder.addMatcher(
      isAnyOf(getAddressByServiceIdAsync.fulfilled),
      (state, action) => {
        state.addressByServiceIdLoading = false;
        state.addressByServiceIdInfo = {};
        state.addressByServiceId = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAddressByServiceIdAsync.rejected),
      (state, action) => {
        state.addressByServiceIdLoading = false;
        state.addressByServiceIdInfo = {};
        state.addressByServiceId = [];
      }
    );


  },
  reducers: {
    emptyJobs: () => initialState,
    resetGetJobByCustomerId: (state) => {
      state.getJobByCustomerId = [];
    }
  },
});

export const { emptyJobs , resetGetJobByCustomerId} = jobsSlice.actions;

export default jobsSlice.reducer;
