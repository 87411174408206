import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllContractAsync,
  deleteContractAsync,
  createContractAsync,
  updateContractAsync,
  getContractByIdAsync,
  getServiceByQuotationAsync,
  getContractWithoutPaginationAsync,
  getContractDocumentDropdownAsync,
  getContractDocumentHtmlByDocumentIdAsync,
  contractEmailPdfAsync
} from "./contract.async";

const initialState = {
  contractListLoader: false,
  contractList: [],
  contractListInfo: {},

  contractDeleteLoading: false,
  contractDeleteInfo: {},
  
  contractrCreateLoader: false,
  contractCreateInfo: {},


  contractByIdLoading: false,
  contractById: {},
  contractByIdInfo: {},

  ServiceByQuotationLoading:false,
  ServiceByQuotation:{},

  contractUpdateLoader: false,
  contractUpdateInfo: {},
  
  contractWithoutPaginationLoader: false,
  contractWithoutPaginationList: [],
  contractWithoutPaginationInfo: {},


  contractDocumentDropDownLoader: false,
  contractDocumentDropDown: [],

  contractDocumentHtmlByDocumentIdLoading: false,
  contractDocumentHtmlByDocumentId: {},
  contractDocumentHtmlByDocumentIdInfo: {},

  contractEmailPdfLoader: false,
  contractEmailPdfInfo: {}
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  extraReducers: (builder) => {


//Contract Email Pdf
builder.addMatcher(isAnyOf(contractEmailPdfAsync.pending), (state) => {
  state.contractEmailPdfLoader = true;
});
builder.addMatcher(
  isAnyOf(contractEmailPdfAsync.fulfilled),
  (state, action) => {
    state.contractEmailPdfLoader = false;
    state.contractEmailPdfInfo = action.payload;
  }
);
builder.addMatcher(
  isAnyOf(contractEmailPdfAsync.rejected),
  (state, action) => {
    state.contractEmailPdfLoader = false;
  }
);









 //Document Html By Document Id
 builder.addMatcher(
  isAnyOf(getContractDocumentHtmlByDocumentIdAsync.pending),
  (state) => {
    state.contractDocumentHtmlByDocumentIdLoading = true;
    state.contractDocumentHtmlByDocumentIdInfo = {};
    state.contractDocumentHtmlByDocumentId = {};
  }
);
builder.addMatcher(
  isAnyOf(getContractDocumentHtmlByDocumentIdAsync.fulfilled),
  (state, action) => {
    state.contractDocumentHtmlByDocumentIdLoading = false;
    state.contractDocumentHtmlByDocumentIdInfo = {};
    state.contractDocumentHtmlByDocumentId = action.payload;
  }
);
builder.addMatcher(
  isAnyOf(getContractDocumentHtmlByDocumentIdAsync.rejected),
  (state, action) => {
    state.contractDocumentHtmlByDocumentIdLoading = false;
    state.contractDocumentHtmlByDocumentIdInfo = {};
    state.contractDocumentHtmlByDocumentId = {};
  }
);

 // Document document Drop Down List

 builder.addMatcher(
  isAnyOf(getContractDocumentDropdownAsync.pending),
  (state) => {
    state.contractDocumentDropDownLoader = true;
    state.contractDocumentDropDown = [];
  }
);
builder.addMatcher(
  isAnyOf(getContractDocumentDropdownAsync.fulfilled),
  (state, action) => {
    state.contractDocumentDropDownLoader = false;
    state.contractDocumentDropDown = action.payload?.data || [];
  }
);
builder.addMatcher(
  isAnyOf(getContractDocumentDropdownAsync.rejected),
  (state, action) => {
    state.contractDocumentDropDownLoader = false;
    state.contractDocumentDropDown = [];
  }
);

// contract List
    builder.addMatcher(isAnyOf(getAllContractAsync.pending), (state) => {
      state.contractListLoader = true;
      state.contractListInfo = {};
      state.contractList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllContractAsync.fulfilled),
      (state, action) => {
        state.contractListLoader = false;
        state.contractListInfo = {};
        state.contractList = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllContractAsync.rejected),
      (state, action) => {
        state.contractListLoader = false;
        state.contractListInfo = {};
        state.contractList = [];
      }
    );

    // create contract
    builder.addMatcher(isAnyOf(createContractAsync.pending), (state) => {
      state.contractCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createContractAsync.fulfilled),
      (state, action) => {
        state.contractCreateLoader = false;
        state.contractCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createContractAsync.rejected),
      (state, action) => {
        state.contractCreateLoader = false;
      }
    );

    //contract Delete
    builder.addMatcher(isAnyOf(deleteContractAsync.pending), (state) => {
      state.contractDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteContractAsync.fulfilled),
      (state, action) => {
        state.contractDeleteLoading = false;
        state.contractDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteContractAsync.rejected),
      (state, action) => {
        state.contractDeleteLoading = false;
      }
    );

    //contract By Id
    builder.addMatcher(isAnyOf(getContractByIdAsync.pending), (state) => {
      state.contractByIdLoading = true;
      state.contractByIdInfo = {};
      state.contractById = {};
    });
    builder.addMatcher(
      isAnyOf(getContractByIdAsync.fulfilled),
      (state, action) => {
        state.contractByIdLoading = false;
        state.contractByIdInfo = {};
        state.contractById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getContractByIdAsync.rejected),
      (state, action) => {
        state.contractByIdLoading = false;
        state.contractByIdInfo = {};
        state.contractById = {};
      }
    );
    //contract By Id
    builder.addMatcher(isAnyOf(getServiceByQuotationAsync.pending), (state) => {
      state.ServiceByQuotationLoading = true;
      state.ServiceByQuotation = {};
    });
    builder.addMatcher(
      isAnyOf(getServiceByQuotationAsync.fulfilled),
      (state, action) => {
        state.ServiceByQuotationLoading = false;
        state.ServiceByQuotation = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getServiceByQuotationAsync.rejected),
      (state, action) => {
        state.ServiceByQuotationLoading = false;
        state.ServiceByQuotation = {};
      }
    );

    //contract Update
    builder.addMatcher(isAnyOf(updateContractAsync.pending), (state) => {
      state.contractUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateContractAsync.fulfilled),
      (state, action) => {
        state.contractUpdateLoader = false;
        state.contractUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateContractAsync.rejected),
      (state, action) => {
        state.contractUpdateLoader = false;
      }
    );
    // Get contract without pagination and search filter
    builder.addMatcher(
      isAnyOf(getContractWithoutPaginationAsync.pending),
      (state) => {
        state.contractWithoutPaginationLoader = true;
        state.contractWithoutPaginationInfo = {};
        state.contractWithoutPaginationList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getContractWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.contractWithoutPaginationLoader = false;
        state.contractWithoutPaginationInfo = {};
        state.contractWithoutPaginationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getContractWithoutPaginationAsync.rejected),
      (state, action) => {
        state.contractWithoutPaginationLoader = false;
        state.contractWithoutPaginationInfo = {};
        state.contractWithoutPaginationList = [];
      }
    );

    
  },
  reducers: {
    emptycontract: () => initialState,
  },
});
export const { emptycontract } = contractSlice.actions;

export default contractSlice.reducer;
