import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { uploadAsync } from "./upload.asyc";

const initialState = {
    uploadLoader: false,
    upload: [],
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(uploadAsync.pending), (state) => {
      state.uploadLoader = true;
    });
    builder.addMatcher(
      isAnyOf(uploadAsync.fulfilled),
      (state, action) => {
        state.uploadLoader = false;
        state.dashboard = action.payload.data;
      }
    );

    builder.addMatcher(isAnyOf(uploadAsync.rejected), (state, action) => {
      state.uploadLoader = false;
    });
  },
  reducers: {
    emptyupload: (state) => initialState,
  },
});

export const { emptyupload } = uploadSlice.actions;

export default uploadSlice.reducer;
