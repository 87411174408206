import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllRosterAsync,
  getUserByRoleAsync,
  getAllListUnassignedJobsAsync,
  createRosterAsync,
  getRosterByIdAsync,
  deleteRoasterByIdAsync,
  updateRoasterByIdAsync
} from "./roster.async";

const initialState = {
  rosterListLoader: false,
  rosterList: [],
  rosterListInfo: {},

  getRosterListByIdLoader: false,
  getRosterListById: [],
  getRosterListByIdInfo: {},

  deleteRosterListByIdLoader: false,
  deleteRosterListById: [],
  deleteRosterListByIdInfo: {},

  updateRosterListByIdLoader: false,
  updateRosterListById: [],
  updateRosterListByIdInfo: {},

  userByRoleLoader: false,
  userByRoleInfo: {},
  userByRole: [],

  unassignedJobsListLoader: false,
  unassignedJobsList: [],
  unassignedJobsInfo: {},

  createRosterLoader: false,
  createRosterInfo: {},
  createRoster: [],
};

export const rosterSlice = createSlice({
  name: "roster",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getUserByRoleAsync.pending), (state) => {
      state.userByRoleLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getUserByRoleAsync.fulfilled),
      (state, action) => {
        state.userByRoleLoader = false;
        state.userByRoleInfo = {};
        state.userByRole = action.payload?.data || [];
      }
    );

    builder.addMatcher(isAnyOf(getAllRosterAsync.pending), (state) => {
      state.rosterListLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getAllRosterAsync.fulfilled),
      (state, action) => {
        state.rosterListLoader = false;
        state.rosterListInfo = {};
        state.rosterList = action.payload || [];

      }
    );

    builder.addMatcher(isAnyOf(getAllRosterAsync.rejected), (state, action) => {
      state.rosterListLoader = false;
      state.rosterListInfo = {};
      state.rosterList = [];
    });

    // get Roaster by id 
    builder.addMatcher(isAnyOf(getRosterByIdAsync.pending), (state) => {
      state.getRosterListByIdLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getRosterByIdAsync.fulfilled),
      (state, action) => {
        state.getRosterListByIdLoader = false;
        state.getRosterListByIdInfo = {};
        state.getRosterListById = action.payload?.data || [];

      }
    );

    builder.addMatcher(isAnyOf(deleteRoasterByIdAsync.rejected), (state, action) => {
      state.deleteRosterListByIdLoader = false;
      state.deleteRosterListByIdInfo = {};
      state.deleteRosterListById = [];
    });

    builder.addMatcher(isAnyOf(deleteRoasterByIdAsync.pending), (state) => {
      state.deleteRosterListByIdLoader = true;
    });

    builder.addMatcher(
      isAnyOf(deleteRoasterByIdAsync.fulfilled),
      (state, action) => {
        state.deleteRosterListByIdLoader = false;
        state.deleteRosterListByIdInfo = action.payload;
        state.deleteRosterListById = action.payload?.data || [];

      }
    );

    builder.addMatcher(isAnyOf(updateRoasterByIdAsync.rejected), (state, action) => {
      state.updateRosterListByIdLoader = false;
      state.updateRosterListByIdInfo = {};
      state.updateRosterListById = [];
    });

    builder.addMatcher(isAnyOf(updateRoasterByIdAsync.pending), (state) => {
      state.updateRosterListByIdLoader = true;
    });

    builder.addMatcher(
      isAnyOf(updateRoasterByIdAsync.fulfilled),
      (state, action) => {
        state.updateRosterListByIdLoader = false;
        state.updateRosterListByIdInfo = action.payload;
        state.updateRosterListById = action.payload?.data || [];

      }
    );

    builder.addMatcher(isAnyOf(getRosterByIdAsync.rejected), (state, action) => {
      state.getRosterListByIdLoader = false;
      state.getRosterListByIdInfo = {};
      state.getRosterListById = [];
    });

    builder.addMatcher(
      isAnyOf(getAllListUnassignedJobsAsync.pending),
      (state) => {
        state.unassignedJobsListLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(getAllListUnassignedJobsAsync.fulfilled),
      (state, action) => {
        state.unassignedJobsListLoader = false;
        state.unassignedJobsInfo = {};
        state.unassignedJobsList = action.payload?.data || [];
      }
    );

    builder.addMatcher(
      isAnyOf(getAllListUnassignedJobsAsync.rejected),
      (state, action) => {
        state.unassignedJobsListLoader = false;
        state.unassignedJobsInfo = {};
        state.unassignedJobsList = [];
      }
    );

    builder.addMatcher(isAnyOf(createRosterAsync.pending), (state) => {
      state.createRosterLoader = true;
    });

    builder.addMatcher(
      isAnyOf(createRosterAsync.fulfilled),
      (state, action) => {
        state.createRosterLoader = false;
        state.createRosterInfo = {};
        state.createRoster = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(createRosterAsync.rejected), (state, action) => {
      state.createRosterLoader = false;
      state.createRosterInfo = {};
      state.createRoster = [];
    });
  },
  reducers: {
    emptyRoster: (state) => {
      return {
        ...initialState,
      };
    },
  },
});
export const { emptyRoster } = rosterSlice.actions;
export default rosterSlice.reducer;


