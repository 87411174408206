import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllCountryAsync,
  createCountryAsync,
  updateCountryAsync,
  getCountryByIdAsync,
  deleteCountryAsync,
  getCountryWithoutPaginationAsync
} from "./country.async";

const initialState = {
  countryListLoader: false,
  countryList: [],
  countryListInfo: {},

  // currency

  currencyListLoader: false,
  currencyList: [],
  currencyListInfo: {},

  countryCreateLoader: false,
  countryCreateInfo: {},

  countryDeleteLoading: false,
  countryDeleteInfo: {},

  countryByIdLoading: false,
  countryById: {},
  countryByIdInfo: {},

  countryUpdateLoader: false,
  countryUpdateInfo: {},

  countryWithoutPaginationLoader: false,
  countryWithoutPaginationList: [],
  countryWithoutPaginationInfo: {}
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  extraReducers: (builder) => {
    // country List
    builder.addMatcher(isAnyOf(getAllCountryAsync.pending), (state) => {
      state.countryListLoader = true;
      state.countryListInfo = {};
      state.countryList = [];
    });
    builder.addMatcher(
      isAnyOf(getAllCountryAsync.fulfilled),
      (state, action) => {
        state.countryListLoader = false;
        state.countryListInfo = {};
        state.countryList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getAllCountryAsync.rejected),
      (state, action) => {
        state.countryListLoader = false;
        state.countryListInfo = {};
        state.countryList = [];
      }
    );

    // create country
    builder.addMatcher(isAnyOf(createCountryAsync.pending), (state) => {
      state.countryCreateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(createCountryAsync.fulfilled),
      (state, action) => {
        state.countryCreateLoader = false;
        state.countryCreateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(createCountryAsync.rejected),
      (state, action) => {
        state.countryCreateLoader = false;
      }
    );

    //country Delete
    builder.addMatcher(isAnyOf(deleteCountryAsync.pending), (state) => {
      state.countryDeleteLoading = true;
    });
    builder.addMatcher(
      isAnyOf(deleteCountryAsync.fulfilled),
      (state, action) => {
        state.countryDeleteLoading = false;
        state.countryDeleteInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteCountryAsync.rejected),
      (state, action) => {
        state.countryDeleteLoading = false;
      }
    );

    //country By Id
    builder.addMatcher(isAnyOf(getCountryByIdAsync.pending), (state) => {
      state.countryByIdLoading = true;
      state.countryByIdInfo = {};
      state.countryById = {};
    });
    builder.addMatcher(
      isAnyOf(getCountryByIdAsync.fulfilled),
      (state, action) => {
        state.countryByIdLoading = false;
        state.countryByIdInfo = {};
        state.countryById = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getCountryByIdAsync.rejected),
      (state, action) => {
        state.countryByIdLoading = false;
        state.countryByIdInfo = {};
        state.countryById = {};
      }
    );

    //country Update
    builder.addMatcher(isAnyOf(updateCountryAsync.pending), (state) => {
      state.countryUpdateLoader = true;
    });
    builder.addMatcher(
      isAnyOf(updateCountryAsync.fulfilled),
      (state, action) => {
        state.countryUpdateLoader = false;
        state.countryUpdateInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateCountryAsync.rejected),
      (state, action) => {
        state.countryUpdateLoader = false;
      }
    );

    // Get Country without pagination and search filter
    builder.addMatcher(
      isAnyOf(getCountryWithoutPaginationAsync.pending),
      (state) => {
        state.countryWithoutPaginationLoader = true;
        state.countryWithoutPaginationInfo = {};
        state.countryWithoutPaginationList = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCountryWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.countryWithoutPaginationLoader = false;
        state.countryWithoutPaginationInfo = {};
        state.countryWithoutPaginationList = action.payload?.data || [];
      }
    );
    builder.addMatcher(
      isAnyOf(getCountryWithoutPaginationAsync.rejected),
      (state, action) => {
        state.countryWithoutPaginationLoader = false;
        state.countryWithoutPaginationInfo = {};
        state.countryWithoutPaginationList = [];
      }
    );
  },
  reducers: {
    emptyCountry: () => initialState
  }
});
export const { emptyCountry } = countrySlice.actions;

export default countrySlice.reducer;
