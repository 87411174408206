import React, { useState,useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  IconButton
} from "@mui/material";
import moment from "moment";
import CustomBreadcrumbs from "components/custom-breadcrumbs/CustomBreadcrumbs";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { toast } from "react-hot-toast";
import { toastoptions } from "utils/toastoptions";

import { PATH_DASHBOARD } from "routes/paths";

import Iconify from "components/iconify/Iconify";
import CustomTable from "components/CustomTable/CustomTable";
import { useSettingsContext } from "components/settings";
import MenuPopover from "components/menu-popover/index";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "components/confirm-dialog/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import { emptyLead } from "redux/slices/lead/lead.slice";
import {
  deleteQuotationAsync,
} from "redux/slices/quotation/quotation.async";
import { getLeadByIdAsync } from "redux/slices/lead/lead.async";
const data = [
  {
    id: "1",
    name: "DE/INJB/07210000010",
    Qun: "1"
  },
  {
    id: "2",
    name: "DE/INJB/07210000010",
    Qun: "1"
  }
];

const LeadQuotation = () => {
  const dispatch = useDispatch();
  const { leadById = {}, leadByIdLoading } = useSelector(
    (state) => state?.lead
  );
  const {
    quotationDeleteInfo = {},
    quotationDeleteLoading
  } = useSelector((state) => state?.quotation);
  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      width: "90px"
    },
    {
      name: "Quot Number",
      selector: (row) => row?.quotationNo
    },
    {
      name: "Name",
      selector: (row) => row?.contactPerson?.contactPerson || "N/A"
    },
    {
      name: "Date",
      selector: (row) => moment(row?.contactStart.split("T")[0]).format("DD MMM YYYY")
    },
    {
      name: "Type",
      selector: (row) => row.status || "N/A"
    },
    {
      name: "Services",
      selector: (row) => row.services?.map((ev)=>ev?.serviceName
      )
    },
    {
      name: "Amount",
      selector: (row) => row.Qun || "N/A"
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              handleOpenPopover(e, row);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        );
      }
    }
  ];

  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    // for activities delete
    if (Object.keys(quotationDeleteInfo)?.length) {
      setOpenConfirm(false);
      toast.success(quotationDeleteInfo?.message, toastoptions);
      dispatch(emptyLead());
      dispatch(getLeadByIdAsync(leadById?.leadData?._id))
    }
  }, [quotationDeleteInfo]);
  return (
    <Box sx={{ marginTop: "-70px" }}>
      <Stack alignItems="flex-start">
        <h5>Quotation</h5>
      </Stack>
      <CustomTable columns={columns} data={leadById?.quotationData} />
      
      <MenuPopover
          open={openPopover}
          onClose={handleClosePopover}
          arrow="right-top"
          sx={{ width: 130 }}
        >
        <MenuItem
          onClick={() => {
            navigate(`${PATH_DASHBOARD.editquotation}/${leadById?.quotationData?.[0]?._id}`);
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

          <MenuItem
            onClick={() => {
              setOpenConfirm(true);
            }}
            sx={{ color: "error.main" }}
          >
            <Iconify icon="eva:trash-2-outline" />
            Delete
          </MenuItem>
          <ConfirmDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <LoadingButton
            variant="contained"
            color="error"
            loading={quotationDeleteLoading}
            onClick={() => dispatch(deleteQuotationAsync(leadById?.quotationData?.[0]?._id))}
          >
            Delete
          </LoadingButton>
        }
      />
        </MenuPopover>
    </Box>
  );
};

export default LeadQuotation;
