import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getRolesAsync,
  createRoleAsync,
  getRoleWithoutPaginationAsync,
  getRoleByIdAsync,
  updatRoleByIdAsync,
  deleteRoleByIdAsync,
  assignPermissionAsync,
  getPermissionByRoleIdAsync,
  getRolePermissionByIdAsync,
} from "./roles.async";

const initialState = {
  rolesLoader: false,
  roles: [],
  roleadd: null,
  roleById: null,
  updateRoleById: null,
  deleteRoleById: null,
  assignPermissionByRoleId: null,
  PermissionByRoleId: null,
  getPermissionByRoleId: null,
  getRolePermissionById: null,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  extraReducers: (builder) => {
    // PENDING
    builder.addMatcher(
      isAnyOf(
        getRolesAsync.pending,
        getRoleWithoutPaginationAsync.pending,
        createRoleAsync.pending,
        getRoleByIdAsync.pending,
        updatRoleByIdAsync.pending,
        deleteRoleByIdAsync.pending,
        assignPermissionAsync.pending,
        getPermissionByRoleIdAsync.pending,
        getRolePermissionByIdAsync.pending
      ),
      (state) => {
        state.rolesLoader = true;
      }
    );

    // FULLFILLED
    builder.addMatcher(isAnyOf(getRolesAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      state.roles = action?.payload ?? [];
    });

    // FULLFILLED
    builder.addMatcher(
      isAnyOf(getRoleWithoutPaginationAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.roles = action?.payload?.data?.roles ?? [];
      }
    );
    builder.addMatcher(isAnyOf(createRoleAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      state.roleadd = action.payload;
    });
    builder.addMatcher(isAnyOf(getRoleByIdAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      state.roleById = action?.payload?.data ?? null;
    });
    builder.addMatcher(
      isAnyOf(updatRoleByIdAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.updateRoleById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteRoleByIdAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.deleteRoleById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(assignPermissionAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.assignPermissionByRoleId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getPermissionByRoleIdAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.getPermissionByRoleId = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getRolePermissionByIdAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.getRolePermissionById = action.payload;
      }
    );

    // REJECTED
    builder.addMatcher(
      isAnyOf(
        getRolesAsync.rejected,
        getRoleWithoutPaginationAsync.rejected,
        createRoleAsync.rejected,
        getRoleByIdAsync.rejected,
        updatRoleByIdAsync.rejected,
        deleteRoleByIdAsync.rejected,
        assignPermissionAsync.rejected,
        getPermissionByRoleIdAsync.rejected,
        getRolePermissionByIdAsync.rejected
      ),
      (state, action) => {
        state.rolesLoader = false;
      }
    );
  },
  reducers: {
    emptyroles: () => ({ ...initialState }),
    emptyById: (state) => {
      state.roleById = null;
    },
  },
});

export const { emptyroles, emptyById } = rolesSlice.actions;

export default rolesSlice.reducer;
