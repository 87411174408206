import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

// Get All Quotation 

export const getAllQuotationAsync = createAsyncThunk(
  "master/getAllQuotationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/list-quotation?page=${payload?.page || 1}&limit=${payload?.limit || 10
      }&search=${payload?.search || ""}&customer=${payload?.customer || ""}&lob=${payload?.lob || ""}&site=${payload?.site || ""}`,
      [],
      toolkit
    );
  }
);

export const getAllRenewalQuotationAsync = createAsyncThunk(
  "master/getAllRenewalQuotationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/list-renewal-quotation?page=${payload?.page || 1}&limit=${payload?.limit || 10}`,
      [],
      toolkit
    );
  }
);

// Create quotation


export const createQuotationAsync = createAsyncThunk(
  "master/createQuotationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/quotation/create-quotation`,
      payload,
      toolkit
    );
  }
);

// Update quotation


export const updateQuotationAsync = createAsyncThunk(
  "admin/updateQuotationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/quotation/update-quotation/${payload.id}`,
      payload,
      toolkit
    );
  }
);


// Update quotation


export const updateRenewalQuotationAsync = createAsyncThunk(
  "admin/updateRenewalQuotationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/quotation/renewal-quotation/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// Get quotationById

export const getQuotationByIdAsync = createAsyncThunk(
  "master/getQuotationByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/get-quotation/${payload}`,
      [],
      toolkit
    );
  }
);

export const getQuotationModalServices = createAsyncThunk(
  "master/getQuotationByIdAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/get-services/${payload}`,
      [],
      toolkit
    );
  }
);

// Get QuotationListDropDown

export const getQuotationListDropDownAsync = createAsyncThunk(
  "master/getQuotationListDropDownAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/list-lead-dropdown/`,
      [],
      toolkit
    );
  }
);
// Get QuotationListDropDown

export const getQuotationListContactLocationServiceAsync = createAsyncThunk(
  "master/getQuotationListContactLocationServiceAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/list-contact-location-service-dropdown/${payload}`,
      [],
      toolkit
    );
  }
);
export const getListChemicalDropDownAsync = createAsyncThunk(
  "master/getListChemicalDropDownAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/list-chemical-dropdown/${payload}`,
      [],
      toolkit
    );
  }
);

// Delete quotation

export const deleteQuotationAsync = createAsyncThunk(
  "master/deleteQuotationAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/quotation/delete-quotation/${payload}`,
      [],
      toolkit
    );
  }
);


// Update quotation status

export const updateQuotationStatusAsync = createAsyncThunk(
  "admin/updateQuotationStatus",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/quotation/update-quotation-status/${payload.id}`,
      payload,
      toolkit
    );
  }
);



// get-quotation-document

export const getQuotationDocumentDropdownAsync = createAsyncThunk(
  "master/getQuotationDocumentDropdown",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/get-quotation-document/`,

      [],
      toolkit
    );
  }
);

// Get All Quotation  Without  pagination  

export const getAllQuotationWithOutPaginationAsync = createAsyncThunk(
  "master/getAllQuotationWithOutPagination",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/list-quotation`,
      [],
      toolkit
    );
  }
);

// get-document-html
export const getDocumentHtmlByDocumentIdAsync = createAsyncThunk(
  "quotation/getDocumentHtmlByDocumentId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/quotation/get-document-html/${payload?.documentId}/${payload?.quotationId}`,
      [],
      toolkit
    );
  }
);


//  Quotation Save Pdf 
export const quotationSavePdfAsync = createAsyncThunk(
  "quotation/quotationSavePdf",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/quotation/save-pdf/${payload.documentId}`,
      payload,
      toolkit
    );
  }
);

//download Quotation Csv File
export const quotationExcelDownloadAsync = ({ page, limit, search, customer}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/quotation/export-quotation-to-csv?token=${isJson(localStorage.getItem("auth"))
      ? JSON.parse(localStorage.getItem("auth"))?.token
      : null
    }&page=${page || 1}&limit=${limit || 10
    }&search=${search || ""}&customer=${customer || ""}`,
    "_parent"
  );
};

//  Quotation Send Email 
export const quotationEmailPdfAsync = createAsyncThunk(
  "quotation/email-pdf",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/quotation/email-pdf/${payload.id}`,
      payload,
      toolkit
    );
  }
);
