import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllVendorAsync = createAsyncThunk(
  "master/getAllvendor",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-vendor?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
    
  }
);

// get allVendor without pagination 

export const getVendorWithoutPaginationAsync = createAsyncThunk(
  "master/getAllvendorWithoutPagination",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-vendor`,
      [],
      toolkit
    );
    
  }
);

export const createVendorAsync = createAsyncThunk(
  "createVendor",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/master/create-vendor`, payload, toolkit);
  }
);

export const getVendorByIdAsync = createAsyncThunk(
  "master/getVendorById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-vendor/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateVendorAsync = createAsyncThunk(
  "master/updateVendor",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-vendor/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const deleteVendorAsync = createAsyncThunk(
  "master/deleteVendor",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-vendor/${payload}`,
      [],
      toolkit
    );
  }
);

// get state by countryId
export const getStateBycountryIdAsync = createAsyncThunk(
  "master/getStateBycountryId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/lookup-states?countryId=${payload.countryId}`,
      [],
      toolkit
    );
  }
);
  
// get city by StateId
export const getCityByStateIdAsync = createAsyncThunk(
  "master/getCityByStateId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/lookup-city?stateId=${payload.stateId}`,
      [],
      toolkit
    );
  }
);
