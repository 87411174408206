import React, { useEffect } from "react";
import Label from "components/label";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import axios from "axios";
import { useState } from "react";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import { IconButton } from "@mui/material";
import Iconify from "components/iconify/Iconify";
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "routes/paths";
import { TextField, Checkbox, Typography, Box } from "@mui/material";
import { Button, Stack } from "../../../../node_modules/@mui/material/index";
import Tooltip from "@mui/material/Tooltip";
import CopyButton from "components/Custom-Text-Copied/CopyButton";
import { capitalize } from "lodash";

export const CertificateColumnHandler = ({
  open,
  setOpen,
  pageNumber,
  perPageNumber,
  entity,
  setEntity,
  handleOpenPopover,
}) => {
  const columns = [
    {
      name: "Action",
      selector: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              handleOpenPopover(e, row);
            }}
          >
            <Iconify icon="eva:more-vertical-fill" color={row?.cancelStatus === true ? "white" : null} />
          </IconButton>
        );
      },
      width: "80px",
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Sl No.",
      selector: (row, index) =>
        { const serialNumber = (pageNumber - 1) * perPageNumber + (index + 1);
          return (
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {serialNumber}
            </Typography>
          );},
      width: "80px",
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    // required
    // {
    //   name: "Certificate Download",
    //   selector: (row, index) => {
    //     const pdfFile = row?.documentLinks;
    //     const handleDownloadFile = () => {
    //       if (pdfFile) {
    //         window.open(pdfFile, "");
    //       }
    //     };
    //     return row?.documentLinks ? (
    //       <Button variant="contained" onClick={handleDownloadFile}>
    //         Download
    //       </Button>
    //     ) : (
    //       "N/A"
    //     );
    //   },
    // },
    // my code
    {
      name: "Customer Name",
      selector: (row) => {
        const customerName =
          row?.customer?.businessName ??
          certificateById?.customer?.businessName;

        return (
          <Tooltip
            title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {customerName}
                <CopyButton textToCopy={customerName} />
              </Box>
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {customerName}
            </Typography>
          </Tooltip>
        );
      },
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Certificate Type",
      selector: (row) => {
        return (
          <Tooltip
            title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {row?.certificatePreview[0]?.certificateType}
                <CopyButton textToCopy={row?.certificatePreview[0]?.certificateType} />
              </Box>
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {row?.certificatePreview[0]?.certificateType}
            </Typography>
          </Tooltip>
        );
      },
      width: '150px',
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Job Number",
      selector: (row) => {
        const jobNo = row?.jobs ? row?.jobs[0]?.jobNo : "N/A";

        return (
          <Tooltip
            title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {jobNo}
                <CopyButton textToCopy={jobNo} />
              </Box>
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {jobNo}
            </Typography>
          </Tooltip>
        );
      },
      width:"300px",
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Certificate Number",
      selector: (row) => (
        <Tooltip
          title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                 {row.treatmentCertificateNumber
              ? row.treatmentCertificateNumber
              : "N/A"}
                <CopyButton textToCopy={ row.treatmentCertificateNumber
              ? row.treatmentCertificateNumber
              : "N/A"} />
              </Box>
          }
        >
          {row.treatmentCertificateNumber}
        </Tooltip>
      ),
      width: "230px",
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    

    {
      name: "Job Name",
      selector: (row) => {
        return (
          <Tooltip
            title={
              row?.jobs[0]?.generalSchema?.service?.service?.serviceName
                ? row?.jobs[0]?.generalSchema?.service?.service?.serviceName
                : "N/A"
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {row?.jobs[0]?.generalSchema?.service?.service?.serviceName
                ? row?.jobs[0]?.generalSchema?.service?.service?.serviceName
                : "N/A"}
            </Typography>
          </Tooltip>
        );
      },
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Service Number",
      selector: (row) => {
        const serviceNo =
          row?.jobs[0]?.generalSchema?.service?.serviceNo || "N/A";

        return (
          <Tooltip
            title={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {serviceNo}
                <CopyButton textToCopy={serviceNo} />
              </Box>
            }
          >
            <Typography component="div" noWrap sx={{ fontSize: "14px" }}>
              {serviceNo}
            </Typography>
          </Tooltip>
        );
      },
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Entity Name",
      selector: (row, certificateById) =>
        row?.entity ?? certificateById?.entity,
      width: "150px",
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Entity Certificate Created ",
      selector: (row) => {
        return (
          <Label
            variant="soft"
            color="success"
            sx={{
              textTransform: "capitalize",
              backgroundColor: row?.cancelStatus === true ? "#ff6666" : "#fff",
              color: row?.cancelStatus === true ? "#fff" : "#00bcd4",
              cursor: "pointer",
            }}
            onClick={() => {
              setEntity(row);
              setOpen(true);
            }}
          >
            {row?.certificateCreated}
          </Label>
        );
      },
      conditionalCellStyles: [
        {
          when: (cell) => cell?.cancelStatus === true,
          style: {
            backgroundColor: "#ff6666",
            color: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
  ];

  return columns;
};

export const EntityCertificateColumnHandler = () => {
  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: "Container",
      selector: (row) => row?.container,
    },
    {
      name: "Seal",
      selector: (row) => row?.seal,
    },
  ];

  return columns;
};

export const CertificateTotalEntityColumnHandler = (props) => {
  const {
    getCheckedAll,
    setCheckedAll,
    getCheckedValues,
    setCheckedValue,
    handleChangeCheckboxes,
    setTotalEntity,
    handleCheckedAll,
    certificateData,
    setCertificateData,
  } = props;
  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: (
        <Checkbox
          value={getCheckedAll}
          checked={getCheckedAll}
          onChange={(event) => handleCheckedAll(event?.target?.checked)}
        />
      ),
      selector: (data) => (
        <Checkbox
          value={JSON.stringify({
            id: data?._id,
            container: data?.entityName,
            seal: data?.sealNumber,
          })}
          checked={
            getCheckedValues.findIndex((i) => JSON.parse(i)?.id == data._id) !=
              -1 && data?.status !== "created"
          }
          disabled={data?.status === "created"}
          onChange={(event) => handleChangeCheckboxes(event.target.value)}
        />
      ),
      width: "80px",
    },
    {
      name: "Entity Name",
      selector: (row) => row?.entityName,
    },
    {
      name: "Seal Number",
      selector: (row) => row?.sealNumber,
    },
  ];

  return columns;
};

export const CreateCertificateColumnHandler = ({
  setRow,
  open,
  openModal,
  setOpen,
  formik,
  certificateById,
  getFileData,
  setFileDate,
  setOpenModal,
  setCheckedAll,
  id,
  setTotalEntity,
  totalEntity,
  handleOpenPopover,
  setResponseLoading,
  entityInput,
  setEntityInput,
  setCertificateData,
  certificateData,
}) => {
  const dispatch = useDispatch();
  const [jobData, SetjobData] = useState("");
  const [selectedRow, SetSelectedRow] = useState("");
  const [getCheckedValue, setCheckedValue] = useState([]);
  const [indexValue, setIndexValue] = useState(null);
  const [entity, setEntity] = useState();
  const [file, setFile] = useState(null);
  const [containerTrue, setContainerTrue] = useState(null);
  const [selectedData, SetSelectedData] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [newCertificateData, setNewCertificateData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    // Check if certificateById[0]?.count is available
    if (certificateById[0]?.count !== undefined) {
      setEntity(certificateById[0]?.count);
    }
  }, [certificateById]);
  // upload file
  const uploadFile = (selectedFile, index) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result; // Retrieve the base64 string from the FileReader

      const updatedCertificateData = [...certificateData]; // Create a copy of the certificateData array

      // Update the selected item in the copied array
      updatedCertificateData[index] = {
        ...updatedCertificateData[index], // Copy the existing object properties
        file: base64String, // Update the 'file' property with the base64 string
      };

      setCertificateData(updatedCertificateData); // Set the updated array using setCertificateData
    };

    reader.readAsDataURL(selectedFile); // Read the selectedFile as a data URL (base64)
  };

  const handleChangeCheckbox = (data, row, index) => {
    setIndexValue(true);
    setCheckedValue(index);
    setContainerTrue(row);
    setSelectedRowIndex(index);
  };

  const handleCheckboxChange = (index) => {
    const newData = [...certificateData];
    const name = newData[index]?.entity;
    const currentStatus = newData[index].status;

    // Toggle the status of the clicked checkbox
    newData[index].status = !currentStatus;

    // Set the status of other checkboxes with the same name to false
    newData.forEach((item, i) => {
      if (item?.entity !== name && i !== index) {
        item.status = false;
        item.check = [];
      }
    });

    setCertificateData(newData);
  };
  useEffect(() => {
    let newData = certificateData.filter((item) => item.status === true);
    setNewCertificateData(newData);
  }, [certificateData]);

  useEffect(() => {
    if (getFileData === true) {
      let validate = true;
      let hasValidData = true;

      newCertificateData.forEach((obj) => {
        if (
          (obj.count && obj.count != 0) ||
          obj.file ||
          (obj.check && obj.check.length > 0)
        ) {
          // Object meets the condition, continue to the next object
        } else {
          // Object does not meet the condition, set 'hasValidData' to false and exit the loop
          hasValidData = false;
          return;
        }
      });

      newCertificateData.forEach((obj) => {
        if (obj.count) {
          if (obj.totalEntity >= +obj.count + obj.certificateCreated) {
            validate = true;
          } else {
            validate = false;
            return;
          }
        }
      });
      // if (validate) {
      if (newCertificateData.length > 0) {
        if (hasValidData) {
          if (validate) {
            try {
              setResponseLoading(true); // Start the loader
              const modifiedData = newCertificateData.map((item) => {
                let modifiedCheck;
                if (item?.check?.length > 0) {
                  modifiedCheck = item.check;
                }
                return {
                  ...item,
                  entityId: item._id,
                  check: modifiedCheck,
                  csvData: undefined, // Remove the csvData  field
                  _id: undefined, // Remove the original _id field
                };
              });

              let payload = {
                dropdownShortCode:formik?.values?.dropdownShortCode?.value,
                customer: formik?.values?.customerName?.value,
                jobs: formik?.values?.jobsName?.map((ev) => ev?.value),
                entity: modifiedData,
                itemCovered: modifiedData[0]?.entity,
                prevCertificate: modifiedData[0]?.certificateCreated,
                targetOfFumigation: formik?.values?.targetofFumigation?.map(
                  (ev) => ev.value
                ),
                howFumigationConducted:
                  formik?.values?.howFumigationConducted?.map((ev) => ev.value),
                AQISPlasticWrapping: formik?.values?.AQISPlasticWrapping?.value,
                ventilationFinalTlvReading:
                  formik?.values?.ventilationFinalTLVReading,
                  dateOfFumigation:
                  formik?.values?.dateOfFumigation,
              };

              axios
                .post(
                  `${process.env.REACT_APP_BASE_URL}/service/certificate/create-certificate`,
                  payload,
                  {
                    headers: {
                      "content-type": "application/json",
                      Authorization: JSON.parse(localStorage.getItem("auth"))
                        ?.token,
                    },
                  }
                )
                .then((res) => {
                  toast.success(res?.data?.message, toastoptions);
                  setTimeout(() => {
                    navigate(
                      `${PATH_DASHBOARD.editcertificate}/${res?.data?.data?._id}`
                    );
                  }, 500);
                })
                .catch((error) => {
                  console.error(error);
                  toast.error(error?.response?.data?.message, toastoptions);
                })
                .finally(() => {
                  setResponseLoading(false); // Stop the loader
                });
            } catch (error) {
              console.error(error);
            }

            setFileDate(false);
          } else {
            setFileDate(false);
            toast.error(
              "Entity should not exceed Total Entities",
              toastoptions
            );
          }
        } else {
          setFileDate(false);
          toast.error("Please fill the data", toastoptions);
        }
      } else {
        setFileDate(false);
        toast.error("Please select any data", toastoptions);
      }
      //
    }
  }, [getFileData]);

  const column = [
    {
      name: "",
      selector: (row, index) => {
        if (getCheckedValue == index) {
          SetSelectedRow(row);
        }
        return (
          <Checkbox
            value={JSON.stringify({
              id: row?._id,
            })}
            disabled={!!id}
            checked={row?.status}
            onChange={() => {
              handleCheckboxChange(index);
            }}
          />
        );
      },
    },
    {
      name: "Job Number",
      // selector: (row) => row?.entityJobNo ?? row?.jobs?.[0]?.jobNo,
      selector: (row) => (
        <Tooltip
          title={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {capitalize(row?.entityJobNo ?? row?.jobs?.[0]?.jobNo)}
              <CopyButton
                textToCopy={capitalize(row?.entityJobNo ?? row?.jobs?.[0]?.jobNo)}
              />
            </Box>
          }
        >
          <Typography variant="subtitle2" gutterBottom sx={{ fontSize: "14px" }}>
            {capitalize(row?.entityJobNo ?? row?.jobs?.[0]?.jobNo)}
          </Typography>
        </Tooltip>
      ),
      width: "300px",
    },
    {
      name: "Site",
      selector: (row) =>
        row?.site ?? row?.jobs?.[0]?.generalSchema?.site?.locationName,
      width: "160px",
    },
    {
      name: "Entities",
      selector: (row) => row?.entity ?? row?.tempCsvData?.[0]?.entity,
      width: "160px",
    },
    {
      name: "Total Entities",
      selector: (row, index) => {
        SetjobData(row);
        setTotalEntity(row);
        return (
          <>
            <Label
              variant="soft"
              color="success"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#fff",
                color: "#00bcd4",
                cursor: "pointer",
              }}
              onClick={(e) => {
                setOpenModal(true);
                handleOpenPopover(e, row, index);
              }}
            >
              {row?.totalEntity ?? row.csvData?.[0]?.totalEntity}
            </Label>
          </>
        );
      },
      width: "160px",
    },
    {
      name: "Entities Certificate created",
      selector: (row, index) => {
        return (
          <>
            <Label
              variant="soft"
              color="success"
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#fff",
                color: "#00bcd4",
                cursor: "pointer",
              }}
              onClick={() => {
                setRow(row);
                setOpen(true);
              }}
            >
              {row?.certificateCreated}
            </Label>
          </>
        );
      },
    },

    {
      name: "New Certificate entities",
      selector: (row, index) => {
        const [selectedFile, setSelectedFile] = useState(null);
        const isContainer = ["container", "Container", "CoNtAiNeR"].some(
          (value) => value.toLowerCase() === row?.entity?.toLowerCase()
        );

        const [entityCount, setEntityCount] = useState("");
        const changeEntityHandler = (event, index) => {
          setEntityCount(event.target.value);
          const updatedCertificateData = [...certificateData]; // Create a copy of the certificateData array

          // Update the selected item in the copied array
          updatedCertificateData[index] = {
            ...updatedCertificateData[index], // Copy the existing object properties
            count: event.target.value, // Update the 'count' property with the selectedFile value
          };

          setCertificateData(updatedCertificateData); // Set the updated array using setCertificateData
        };

        const handleInputChange = (e, index) => {
          changeEntityHandler(e, index);
          const enteredEntity = parseInt(e.target.value);
          const totalEntity = row?.totalEntity;
          const certificateCreated = row?.certificateCreated;
          const sum = enteredEntity + certificateCreated;

          if (sum <= totalEntity) {
            setEntity(enteredEntity);
            setValidationError("");
            setValidationError("");

            if (getCheckedValue === index) {
              SetSelectedData(!!e.target.value);
            }
          } else {
            setValidationError("Entity should not exceed Total Entities");
          }

          if (!enteredEntity) {
            setValidationError("");
          }
          if (e.target.value == "") {
            SetSelectedData(false);
          }
        };

        if (isContainer === true) {
          if (getCheckedValue == index) {
            SetSelectedData(!!selectedFile);
          }
          return (
            <>
              <Stack alignItems="flex-end" sx={{ mt: 2 }}>
                <input
                  id={`fileUpload_${index}`}
                  type="file"
                  style={{ display: "none" }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                    uploadFile(event.target.files[0], index);
                  }}
                />
                <label htmlFor={`fileUpload_${index}`}>
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                  {selectedFile && <p>{selectedFile.name}</p>}
                </label>
              </Stack>
            </>
          );
        } else {
          if (getCheckedValue == index) {
            SetSelectedData(!!entityCount);
          }
          return (
            <TextField
              sx={{ paddingTop: "1px" }}
              InputLabelProps={{ shrink: "false" }}
              size="small"
              label="Entity"
              type="number"
              value={entityCount}
              error={selectedRowIndex === index && validationError !== ""}
              helperText={selectedRowIndex === index ? validationError : ""}
              onChange={(e) => {
                handleInputChange(e, index);
              }}
              onWheel={(e) => e.target.blur()}
            />
          );
        }
      },
      width: "400px",
    },
  ];

  return column;
};

// required later
// export const buttonDownloadColumnHandler = () => {
//   const column = [
//     {
//       name: "Name",
//       selector: (row) => <Typography>{row?.name}</Typography>,
//     },
//     {
//       name: "Download",
//       selector: (row) => {
//         const pdfFile = row?.link
//         const handleDownloadFile = () => {
//           if (pdfFile) {
//             window.open(pdfFile, '');
//           }
//         };
//       return  (
//           <Button variant="contained" onClick={handleDownloadFile}>Download</Button>
//       )},
//     },
//   ];

//   return column;
// };

// Formik
export const _initial = {
  customerName: {},
  jobsName: [],
  fileUpload: [],
  previewData: "",
  documentName: {},
  entity: "",
  certificateType: {},
  dropdownShortCode:{},
  targetofFumigation: [],
  howFumigationConducted: [],
  AQISPlasticWrapping: {},
};

export const _validate = yup.object().shape({
  customerName: yup.object({
    label: yup.string().required(),
  }),

  jobsName: yup.object({
    label: yup.string().required(),
  }),
  targetofFumigation: yup.array().min(1, "Required"),
  howFumigationConducted: yup.array().min(1, "Required"),
  AQISPlasticWrapping: yup.object({
    label: yup.string().required(),
  }),
});

export const fields = [
  { name: "exporterName", label: "Name of Exporter", type: "text" },
  { name: "importerName", label: "Name of Importer", type: "text" },
  { name: "cargoType", label: "Type of Cargo", type: "text" },

  { name: "quantity", label: "Quantity (MTs)", type: "text" },

  { name: "shippingMarks", label: "Shipping Marks or Brand", type: "text" },
  { name: "consigneeName", label: "Name of Consignee", type: "text" },

  { name: "notifyPartyName", label: "Name of Notify Party", type: "text" },

  { name: "vesselName", label: "Vessel Name", type: "text" },
  { name: "portOfLoading", label: "Port of Loading", type: "text" },
  { name: "portOfDischarge", label: "Port of Discharge", type: "text" },

  { name: "packing", label: "Packing", type: "text" },
  { name: "blNo", label: "BL No.", type: "text" },
  { name: "createdDate", label: "Created Date", type: "text" },
];
export const textAreaData = [
  { name: "container", label: "Container Number", type: "textarea" },
  { name: "seal", label: "Seal Number", type: "textarea" },
  { name: "exporterAddress", label: "Address of Exporter", type: "textarea" },
  { name: "importerAddress", label: "Address of Importer", type: "textarea" },
  {
    name: "cargoDescription",
    label: "Description of Cargo",
    type: "textarea",
  },
  {
    name: "packagingMaterialDescription",
    label: "Description of Packaging Material",
    type: "textarea",
  },
  {
    name: "consigneeAddress",
    label: "Address of Consignee",
    type: "textarea",
  },
  {
    name: "notifyPartyAddress",
    label: "Address of Notify Party",
    type: "textarea",
  },
  {
    name: "goodsDescription",
    label: "Description of Goods",
    type: "textarea",
  },
];

export const _certificateType = [
  {
    label: "MBr",
    value: "MBr",
  },
  {
    label: "ALP",
    value: "ALP",
  },
  {
    label: "AUS-ALP",
    value: "AUS-ALP",
  },
  {
    label: "AUS-MBr",
    value: "AUS-MBr",
  },
];

//enum for Target of Fumigation
export const targetofFumigation = [
  {
    label: "Commodity",
    value: "commodity",
  },
  {
    label: "Packing",
    value: "packing",
  },
  {
    label: "Both Commodity & Packing",
    value: "both commodity and packing",
  },
];

// enum for How fumigation conducted
export const howFumigationConducted = [
  {
    label: "Stack under sheet",
    value: "stack under sheet",
  },
  {
    label: "Container's under sheet",
    value: "container's under sheet",
  },
  {
    label: "Permanent chamber",
    value: "permanent chamber",
  },
  {
    label: "Pressure tested container/s",
    value: "pressure tested container/s",
  },
];
// enum for AQIS Plastic Wrapping
export const AQISPlasticWrapping = [
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];
