import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    createCreditNote,
    getCreditNoteListAsync
} from "./creditNote.async";

const initialState = {
    isLoading: false,
    creditNoteList : [],
    totalcreditNote: 0,
};

export const creditNoteSlice = createSlice({
  name: "invoice",
  initialState,
  extraReducers: (builder) => {
    // Create Invoice
    builder.addMatcher(isAnyOf(createCreditNote.pending), (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      isAnyOf(createCreditNote.fulfilled),
      (state, action) => {
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(createCreditNote.rejected),
      (state) => {
        state.isLoading = false;
      }
    );
    
      // Get Receipt List
      builder.addMatcher(isAnyOf(getCreditNoteListAsync.pending), (state) => {
        state.isLoading = true;
      });
      builder.addMatcher(
        isAnyOf(getCreditNoteListAsync.fulfilled),
        (state, action) => {
          state.isLoading = false;
          state.creditNoteList = action.payload?.data?.data;
          state.totalcreditNote = action.payload?.data?.totalItems;
        }
      );
      builder.addMatcher(
        isAnyOf(getCreditNoteListAsync.rejected),
        (state, action) => {
          state.isLoading = false;
          state.creditNoteList = [];
        }
      );
    }
});
export const { emptyInvoice } = creditNoteSlice.actions;
export default creditNoteSlice.reducer;
