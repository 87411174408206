import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getquotationConvertChartDataAsync = createAsyncThunk(
  "mis/getquotationConvertChartData",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/mis/get-quotation-pie-chart-data`,
      payload,
      toolkit
    );
  }
);
