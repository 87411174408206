import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Box, TextField, Container, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomTable from "components/CustomTable/CustomTable";
import { _followType, Notescolumns, initial, validate } from "./util";
import {
  getAllNotesAsync,
  createNotesAsync
} from "redux/slices/notes/notes.async";
import { toastoptions } from "utils/toastoptions";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import { emptyNotes } from "redux/slices/notes/notes.slice";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPageNumber, setPerPageNumber] = useState(10);

  const handlePerRowsChange = (perPageNo, page) => {
    setPageNumber(page);
    setPerPageNumber(perPageNo);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const {
    notesListLoader,
    notesList = [],
    notesCreateLoader,
    notesCreateInfo
  } = useSelector((state) => state?.notes);

  const getAllDataHandler = () => {
    const payload = {
      page: pageNumber,
      limit: perPageNumber,
      leadId: id
    };
    dispatch(getAllNotesAsync(payload));
  };

  useEffect(() => {
    getAllDataHandler();
  }, [pageNumber, perPageNumber]);

  const onSubmit = async (values) => {
    const payload = {
      leadId: id,
      title: values?.title
    };
    dispatch(createNotesAsync(payload));
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit,
    validationSchema: validate
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (notesCreateInfo?.success) {
      toast.success(notesCreateInfo?.message, toastoptions);
      dispatch(emptyNotes());
      formik.resetForm();
      getAllDataHandler();
    }
  }, [notesCreateInfo]);

  const resetHandler = () => {
    formik.resetForm();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 1, mt: 1 }}
        onClick={handleClickOpen}
      >
        Notes
      </Button>

      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Lead Notes
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ paddingBottom: "0px !important" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  rowGap={2}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)"
                  }}
                  sx={{ py: 2 }}
                >
                  <TextField
                    type="textarea"
                    multiline
                    rows={3}
                    maxRows={5}
                    name="title"
                    label="Notes"
                    {...formik.getFieldProps("title")}
                    onChange={(e) => {
                      formik.setFieldValue("title", e.target.value);
                    }}
                    error={formik.touched.title && formik.errors.title}
                  />
                </Box>
              </Grid>
            </Grid>
            <DialogActions
              sx={{
                pt: "0px !important",
                display: "flex",
                alignItems: "flex-end"
              }}
            >
              <LoadingButton
                type="submit"
                variant="contained"
                loading={notesCreateLoader}
              >
                Save
              </LoadingButton>
              <Button autoFocus variant="contained" onClick={resetHandler}>
                Reset Form
              </Button>
            </DialogActions>
            <CustomTable
              columns={Notescolumns({
                pageNumber
              })}
              loader={notesListLoader}
              data={notesList?.data}
              totalcount={notesList?.pagination?.totalItems}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </DialogContent>
        </form>
      </BootstrapDialog>
    </>
  );
}
