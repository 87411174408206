import { useDropzone } from "react-dropzone";
import { Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Iconify from "../iconify";
import RejectionFiles from "./errors/RejectionFiles";
import AvatarPreview from "./preview/AvatarPreview";

const StyledDropZone = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  display: "flex",
  cursor: "pointer",
  overflow: "hidden",
  borderRadius: "50%",
  alignItems: "center",
  position: "relative",
  justifyContent: "center",
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`
}));

const StyledPlaceholder = styled("div")(({ theme }) => ({
  zIndex: 7,
  display: "flex",
  borderRadius: "50%",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  width: `calc(100% - 16px)`,
  height: `calc(100% - 16px)`,
  color: theme.palette.text.disabled,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  })
}));

export default function UploadAvatar({
  error,
  file,
  disabled,
  helperText,
  sx,
  ...other
}) {
  const { getRootProps, getInputProps, isDragReject, fileRejections } =
    useDropzone({
      multiple: false,
      disabled,
      ...other
    });

  const hasFile = !!file;
  const isError = isDragReject || !!error;

  return (
    <>
      <StyledDropZone {...getRootProps()} className="zone">
        <input {...getInputProps()} />
        {hasFile && <AvatarPreview file={file} />}

        <StyledPlaceholder
          className="placeholder profileBg"
          sx={{
            "&:hover": {
              opacity: 0.72,
              backgroundColor: "transparent !important",
              color: "rgb(145, 158, 171) !important"
            },
            ...(hasFile && {
              zIndex: 9,
              opacity: 1,
              color: "common.secondary",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.64)
            }),
            ...(isError && {
              color: "error.main",
              bgcolor: "error.lighter"
            })
          }}
        >
          <Iconify icon="ic:round-add-a-photo" width={24} sx={{ mb: 1 }} />
          <Typography variant="caption">
            {file ? "Update photo" : "Upload photo"}
          </Typography>
        </StyledPlaceholder>
      </StyledDropZone>
      {helperText && helperText}
      <RejectionFiles fileRejections={fileRejections} />
    </>
  );
}
