import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    getAllFollowUpAsync,
    createFollowUpAsync
} from "./followup.async";

const initialState = {

  followUpListLoader: false,
  followUpList: [],
  followUpListInfo: {},


  followUpCreateLoader: false,
  followUpCreateInfo: {},
};

export const followUpSlice = createSlice({
  name: "followUp",
  initialState,
  extraReducers: (builder) => {

     // Follow Up Create
     builder.addMatcher(isAnyOf(createFollowUpAsync.pending), (state) => {
        state.followUpCreateLoader = true;
      });
      builder.addMatcher(isAnyOf(createFollowUpAsync.fulfilled), (state, action) => {
        state.followUpCreateLoader = false;
        state.followUpCreateInfo = action.payload;
      });
      builder.addMatcher(isAnyOf(createFollowUpAsync.rejected), (state, action) => {
        state.followUpCreateLoader = false;
      });
   
    // Follow Up List
    builder.addMatcher(isAnyOf(getAllFollowUpAsync.pending), (state) => {
      state.followUpListLoader = true;
      state.followUpListInfo = {};
      state.followUpList = [];
    });
    builder.addMatcher(isAnyOf(getAllFollowUpAsync.fulfilled), (state, action) => {
      state.followUpListLoader = false;
      state.followUpListInfo = {};
      state.followUpList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllFollowUpAsync.rejected), (state, action) => {
      state.followUpListLoader = false;
      state.followUpListInfo = {};
      state.followUpList = [];
    });
   

   
  },
  reducers: {
    emptyFollowUp: () => initialState
  }
});
export const { emptyFollowUp } = followUpSlice.actions;

export default followUpSlice.reducer;
