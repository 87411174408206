import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";
import { isJson } from "utils/isJson";

// getReceiptListAsync
export const getReceiptListAsync = createAsyncThunk(
  "receipt/list-receipt",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/receipt/list-receipt?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&customer=${
        payload?.customer || ""
      }&service=${payload?.service || ""}&site=${payload?.site || ""}&fromDate=${
        payload?.fromDate || ""
      }&toDate=${payload?.toDate || ""}`,
      [],
      toolkit
    );
  }
);

// getInvoiceListAsync
export const getAllInvoiceForReceiptAsync = createAsyncThunk(
  "master/receipt/getAllInvoice",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/invoice/list-invoice?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}&type=dropdown&client=${
        payload.client || ""
      }&status=${payload.status || ""}&site=${payload.site || ""}&eDate=${
        payload.eDate || ""
      }&sDate=${payload.sDate || ""}&invoiceNumber=${
        payload.invoiceNumber || ""
      }
      `,
      [],
      toolkit
    );
  }
);

// create Receipt Async
export const createReceiptAsync = createAsyncThunk(
  "receipt/create-receipt",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/receipt/create-receipt`,
      payload,
      toolkit
    );
  }
);

// getReceiptAsync
export const getReceiptAsync = createAsyncThunk(
  "receipt/get-receipt",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/receipt/get-receipt/${payload}`,
      [],
      toolkit
    );
  }
);

// updateReceiptAsync
export const updateReceiptAsync = createAsyncThunk(
  "receipt/updateReceiptAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/receipt/update-receipt/${payload.id}`,
      payload,
      toolkit
    );
  }
);

// For Cancel Receipt Invoice
export const cancelReceiptInvoiceAsync = createAsyncThunk(
  "admin/cancelReceiptInvoiceAsync",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/receipt/cancel-receipt/${payload?.id}`,
      payload,
      toolkit
    );
  }
);


//For Receipt Print



// export const gstPrintReceiptAsync = createAsyncThunk(
//   "receipt/get-receipt",
//   async (payload, thunkAPI) => {
//     try {
//       const token = isJson(localStorage.getItem("auth"))
//         ? JSON.parse(localStorage.getItem("auth")).token
//         : null;

//       const url = `/receipt/print-receipt/${payload}?token=${token}`;

//       // Assuming AxiosClient returns the data you want to display in the new window
//       const response = await AxiosClient("GET", url, [], thunkAPI);

//       // Check if the response is an object, and convert it to a string if necessary
//       const newWindowContent = typeof response === 'object' ? JSON.stringify(response) : response;

//       // Open a new window with the receipt content
//       const newWindow = window.open();
//       newWindow.document.write(newWindowContent);

//       return response;
//     } catch (error) {
//       // Handle errors appropriately
//       console.error("Error fetching receipt:", error);
//       throw error; // Propagate the error for the calling code to handle
//     }
//   }
// );

export const gstPrintReceiptAsync = createAsyncThunk(
  "receipt/get-receipt",
  async (payload, thunkAPI) => {
    try {
      const token = isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth")).token
        : null;

      const url = `/receipt/print-receipt/${payload}?token=${token}`;

      // Assuming AxiosClient returns the data you want to display in the new window
      const response = await AxiosClient("GET", url, [], thunkAPI);

      // Convert the response data to a Blob
      const blob = new Blob([response], { type: 'arraybuffer' }); // Change the type according to your document type

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Open a new window with the receipt content
      const newWindow = window.open(blobUrl);

      // Trigger a download
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = 'receipt.pdf'; // Set the desired filename
      downloadLink.click();

      return response;
    } catch (error) {
      // Handle errors appropriately
      console.error("Error fetching receipt:", error);
      throw error; // Propagate the error for the calling code to handle
    }
  }
);



//download Invoice Csv File
export const receiptExcelDownloadAsync = ({ page, limit, search, customer, service, site, fromDate, toDate}) => {
  window.open(
    `${process.env.REACT_APP_BASE_URL}/receipt/receipts-export-to-csv?token=${
      isJson(localStorage.getItem("auth"))
        ? JSON.parse(localStorage.getItem("auth"))?.token
        : null
    }&page=${page || 1}&limit=${
      limit || 10
    }&search=${search || ""}&customer=${
      customer || ""
    }&service=${service || ""}&site=${site || ""}&fromDate=${
      fromDate || ""
    }&toDate=${toDate || ""}`,
    "_parent"
  );
};




// export const gstPrintReceiptAsync = createAsyncThunk(
//   "receipt/get-receipt",
//   async (payload, thunkAPI) => {
//     try {
//       const token = isJson(localStorage.getItem("auth"))
//         ? JSON.parse(localStorage.getItem("auth")).token
//         : null;

//       const url = `/receipt/print-receipt/${payload}?token=${token}`;

//       // Fetch the PDF content as a Blob
//       const response = await AxiosClient("GET", url, { responseType: 'blob' }, thunkAPI);

//       // Create a Blob from the response
//       const blob = new Blob([response.data], { type: 'application/pdf' });

//       // Create a data URL from the Blob
//       const dataUrl = URL.createObjectURL(blob);

//       // Open a new window with the data URL
//       const newWindow = window.open(dataUrl);

//       // Wait for a short time for the new window to open
//       await new Promise(resolve => setTimeout(resolve, 1000));

//       // Revoke the data URL to free up resources
//       URL.revokeObjectURL(dataUrl);

//       return response;
//     } catch (error) {
//       // Handle errors appropriately
//       console.error("Error fetching or processing receipt:", error);
//       throw error; // Propagate the error for the calling code to handle
//     }
//   }
// );










// export const gstReportExcelDownloadAsync = () => {
//   window.open(
//     `${process.env.REACT_APP_BASE_URL}/mis/download-gst-report?token=${
//       isJson(localStorage.getItem("auth"))
//         ? JSON.parse(localStorage.getItem("auth"))?.token
//         : null
//     }`,
//     "_parent"
//   );
// };

