export const EntityContainerColumn = () => {
  const columns = [
    {
      name: 'Sl No.',
      selector: (row, index) => index + 1,
      width: '100px',
    },
    {
      name: 'Container',
      selector: (row) => row?.container,
    },
    {
      name: 'Seal',
      selector: (row) => row?.seal,
    },
  ];

  return columns;
};
