import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  getRevenueNumberChartDataAsync,
  getTopXCustomerRevenueAsync,
  getTopXCustomerPayablesAsync,
  getTopXCustomerDelayingAsync,
  getGstReportsAsync,
  getOutStandingReportAsync,
  getContainerReportAsync,
} from './revenueNumber.async';

const initialState = {
  revenueNumberChartDataLoader: false,
  revenueNumberChartDataInfo: {},

  topXCustomerRevenueLoader: false,
  topXCustomerRevenueInfo: {},

  topXCustomerPayablesLoader: false,
  topXCustomerPayablesInfo: {},

  topXCustomerDelayingLoader: false,
  topXCustomerDelayingInfo: {},

  GstReportsLoader: false,
  GstReports: [],

  outStandingLoader: false,
  outStandingReports: [],

  containerReportLoader: false,
  containerReports: [],
};

export const revenueNumberSlice = createSlice({
  name: 'revenueNumber',
  initialState,
  extraReducers: (builder) => {
    // Create State
    builder.addMatcher(
      isAnyOf(getRevenueNumberChartDataAsync.pending),
      (state) => {
        state.revenueNumberChartDataLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getRevenueNumberChartDataAsync.fulfilled),
      (state, action) => {
        state.revenueNumberChartDataLoader = false;
        state.revenueNumberChartDataInfo = action.payload?.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getRevenueNumberChartDataAsync.rejected),
      (state, action) => {
        state.revenueNumberChartDataLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getTopXCustomerRevenueAsync.pending),
      (state) => {
        state.topXCustomerRevenueLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getTopXCustomerRevenueAsync.fulfilled),
      (state, action) => {
        state.topXCustomerRevenueLoader = false;
        state.topXCustomerRevenueInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getTopXCustomerRevenueAsync.rejected),
      (state, action) => {
        state.topXCustomerRevenueLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getTopXCustomerPayablesAsync.pending),
      (state) => {
        state.topXCustomerPayablesLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getTopXCustomerPayablesAsync.fulfilled),
      (state, action) => {
        state.topXCustomerPayablesLoader = false;
        state.topXCustomerPayablesInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getTopXCustomerPayablesAsync.rejected),
      (state, action) => {
        state.topXCustomerPayablesLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getTopXCustomerDelayingAsync.pending),
      (state) => {
        state.topXCustomerDelayingLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getTopXCustomerDelayingAsync.fulfilled),
      (state, action) => {
        state.topXCustomerDelayingLoader = false;
        state.topXCustomerDelayingInfo = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getTopXCustomerDelayingAsync.rejected),
      (state, action) => {
        state.topXCustomerDelayingLoader = false;
      }
    );
    // Gst Reports
    builder.addMatcher(isAnyOf(getGstReportsAsync.pending), (state) => {
      state.GstReportsLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getGstReportsAsync.fulfilled),
      (state, action) => {
        state.GstReportsLoader = false;
        state.GstReports = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getGstReportsAsync.rejected),
      (state, action) => {
        state.GstReportsLoader = false;
      }
    );
    // Outstanding Reports
    builder.addMatcher(isAnyOf(getOutStandingReportAsync.pending), (state) => {
      state.outStandingLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getOutStandingReportAsync.fulfilled),
      (state, action) => {
        state.outStandingLoader = false;
        state.outStandingReports = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getOutStandingReportAsync.rejected), (state) => {
      state.outStandingLoader = false;
    });
    // Container Reports
    builder.addMatcher(isAnyOf(getContainerReportAsync.pending), (state) => {
      state.containerReportLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getContainerReportAsync.fulfilled),
      (state, action) => {
        state.containerReportLoader = false;
        state.containerReports = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getContainerReportAsync.rejected), (state) => {
      state.containerReportLoader = false;
    });
  },
  reducers: {
    emptyrRevenueNumber: (state) => {
      return {
        ...initialState,
      };
    },
    emptyContainerReport: (state) => {
      state.containerReports = [];
    },
  },
});
export const { emptyReveNueNumber, emptyContainerReport } =
  revenueNumberSlice.actions;
export default revenueNumberSlice.reducer;
