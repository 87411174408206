import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createPincodeAsync,
  getAllPincodeAsync,
  deletePincodeAsync,
  getPincodeByIdAsync,
  getPincodeByCity,
  updatePincodeAsync,
  getAllPincodewithoutpaginationAsync
} from "./pincode.async";

const initialState = {
    pincodeCreateLoader: false,
    pincodeCreateInfo: {},

    pincodeListwithoutpaginationLoader: false,
  pincodeListwithoutpagination: [],
  pincodeListwithoutpaginationInfo: {},

  pincodeListLoader: false,
  pincodeList: [],
  pincodeListInfo: {},

  pincodeDeleteLoading: false,
  pincodeDeleteInfo:{},

  pincodeByIdLoading: false,
  pincodeById: {},
  pincodeByIdInfo:{}, 

  pincodeByCityLoading: false,
  pincodeByCity: {},
  pincodeByCityInfo:{}, 

  pincodeUpdateLoader: false,
  pincodeUpdateInfo: {},
};

export const pincodeSlice = createSlice({
  name: "pincode",
  initialState,
  extraReducers: (builder) => {

    // Create Pincode
    builder.addMatcher(isAnyOf(createPincodeAsync.pending), (state) => {
      state.pincodeCreateLoader = true;
    });
    builder.addMatcher(isAnyOf(createPincodeAsync.fulfilled), (state, action) => {
      state.pincodeCreateLoader = false;
      state.pincodeCreateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(createPincodeAsync.rejected), (state, action) => {
      state.pincodeCreateLoader = false;
    });

    
    //Pincode  List  withoutpagination 
    builder.addMatcher(isAnyOf(getAllPincodewithoutpaginationAsync.pending), (state) => {
      state.pincodeListwithoutpaginationLoader = true;
      state.pincodeListwithoutpaginationInfo = {};
      state.pincodeListwithoutpagination = [];
    });
    builder.addMatcher(isAnyOf(getAllPincodewithoutpaginationAsync.fulfilled), (state, action) => {
      state.pincodeListwithoutpaginationLoader = false;
      state.pincodeListwithoutpaginationInfo = {};
      state.pincodeListwithoutpagination = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllPincodewithoutpaginationAsync.rejected), (state, action) => {
      state.pincodeListwithoutpaginationLoader = false;
      state.pincodeListwithoutpaginationInfo = {};
      state.pincodeListwithoutpagination = [];
    });

    //Pincode List 
    builder.addMatcher(isAnyOf(getAllPincodeAsync.pending), (state) => {
      state.pincodeListLoader = true;
      state.pincodeListInfo = {};
      state.pincodeList = [];
    });
    builder.addMatcher(isAnyOf(getAllPincodeAsync.fulfilled), (state, action) => {
      state.pincodeListLoader = false;
      state.pincodeListInfo = {};
      state.pincodeList = action.payload?.data || [];
    });
    builder.addMatcher(isAnyOf(getAllPincodeAsync.rejected), (state, action) => {
      state.pincodeListLoader = false;
      state.pincodeListInfo = {};
      state.pincodeList = [];
    });

    //Pincode Delete 
    builder.addMatcher(isAnyOf(deletePincodeAsync.pending), (state) => {
      state.pincodeDeleteLoading = true;
    });
    builder.addMatcher(isAnyOf(deletePincodeAsync.fulfilled), (state, action) => {
      state.pincodeDeleteLoading = false;
      state.pincodeDeleteInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(deletePincodeAsync.rejected), (state, action) => {
      state.pincodeDeleteLoading = false;
    });
    
    
    //Pincode By Id 
    builder.addMatcher(isAnyOf(getPincodeByIdAsync.pending), (state) => {
      state.pincodeByIdLoading = true;
      state.pincodeByIdInfo = {};
      state.pincodeById = {};
    });
    builder.addMatcher(isAnyOf(getPincodeByIdAsync.fulfilled), (state, action) => {
      state.pincodeByIdLoading = false;
      state.pincodeByIdInfo = {};
      state.pincodeById = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getPincodeByIdAsync.rejected), (state, action) => {
      state.pincodeByIdLoading = false;
      state.pincodeByIdInfo = {};
      state.pincodeById = {};
    });

    //Pincode By city 
    builder.addMatcher(isAnyOf(getPincodeByCity.pending), (state) => {
      state.pincodeByCityLoading = true;
      state.pincodeByCityInfo = {};
      state.pincodeByCity = {};
    });
    builder.addMatcher(isAnyOf(getPincodeByCity.fulfilled), (state, action) => {
      state.pincodeByCityLoading = false;
      state.pincodeByCityInfo = {};
      state.pincodeByCity = action.payload?.data;
    });
    builder.addMatcher(isAnyOf(getPincodeByCity.rejected), (state, action) => {
      state.pincodeByCityLoading = false;
      state.pincodeByCityInfo = {};
      state.pincodeByCity = {};
    });

    //Pincode Update 
    builder.addMatcher(isAnyOf(updatePincodeAsync.pending), (state) => {
      state.pincodeUpdateLoader = true;
    });
    builder.addMatcher(isAnyOf(updatePincodeAsync.fulfilled), (state, action) => {
      state.pincodeUpdateLoader = false;
      state.pincodeUpdateInfo = action.payload;
    });
    builder.addMatcher(isAnyOf(updatePincodeAsync.rejected), (state, action) => {
      state.pincodeUpdateLoader = false;
    });
  },
  reducers: {
    emptyPincode: () => initialState,
  },
});
export const { emptyPincode } = pincodeSlice.actions;

export default pincodeSlice.reducer;
