import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "redux/AxiosClient";

export const getAllDocumentAsync = createAsyncThunk(
  "master/getAllDocument",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/list-documents?page=${payload?.page || 1}&limit=${
        payload?.limit || 10
      }&search=${payload?.search || ""}`,
      [],
      toolkit
    );
  }
);

export const createDocumentAsync = createAsyncThunk(
  "master/createDocument",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/master/create-document`,
      payload,
      toolkit
    );
  }
);

export const getDocumentByIdAsync = createAsyncThunk(
  "master/getDocumentById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/master/get-document/${payload}`,
      [],
      toolkit
    );
  }
);

export const updateDocumentAsync = createAsyncThunk(
  "master/updateDocument",
  async (payload, toolkit) => {
    return await AxiosClient(
      "PUT",
      `/master/update-document/${payload.id}`,
      payload,
      toolkit
    );
  }
);

export const deleteDocumentAsync = createAsyncThunk(
  "master/deleteDocument",
  async (payload, toolkit) => {
    return await AxiosClient(
      "DELETE",
      `/master/delete-document/${payload}`,
      [],
      toolkit
    );
  }
);
